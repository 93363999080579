import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { styled } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import { useNavigate, useParams } from "react-router-dom";
import { setUserField } from "../userProfile/userProfileSlice";
import { setPackageField } from "../package/packageSlice";
import PackageProvider from "../package/packageProvider";
import { setModalField } from "../modal/modalSlice";
import host from "../../config/host";
import RadioButtonGroup from "../../common/components/RadioGroup";
import { Subcaption, Body2 } from "../../common/components/Font";
import Button from "../../common/components/Button";
import SelectWithImage from "../../common/components/SelectWithImage";
import DropdownSelection from "../../common/components/DropdownSelection";
import InputText from "../../common/components/InputText";
import FormProfileProvider from "./formProfileProvider";
import { setFormProfileField } from "./formProfileSlice";
import ModalConfirm from "../../common/components/ModalConfirm";
// import * as animationIcon from "../../asset/animations/confirm.json";
import * as animationIconError from "../../asset/animations/women.json";
import ConfirmImage from "../../asset/images/confirm-profile.gif";

const utilDateOption = () => {
  const createYearOption = (startYear, endYear) => {
    return Array.from(
      {
        length: startYear + 1 - endYear,
      },
      (_, index) => startYear - index,
    ).map(item => ({
      label: item,
      value: item,
    }));
  };

  const createMonthOption = () => {
    return "มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม"
      .split("_")
      .map((item, index) => {
        return {
          value: index + 1,
          label: item,
        };
      });
  };

  const createDateOption = length =>
    Array.from({
      length,
    }).map((_, index) => ({
      label: index + 1,
      value: index + 1,
    }));

  return {
    createDateOption,
    createMonthOption,
    createYearOption,
  };
};

const StyleCoverInput = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const StyleContainerRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
  padding: 8px 0px;

  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 8px;
    flex-direction: column;
  }
`;

const StyleButton = styled(Button)`
  height: 44px;
`;

const StyleTitleText = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: ${props => props?.theme?.primaryLight300};

  .icon-star {
    font-size: 12px;
  }
`;

const StyleGender = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding-top: 8px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const StyleContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
  padding: 0px 40px 40px 40px;
`;

const StyleMessage = styled.div`
  font-size: ${props => props?.theme?.body1Size};
  font-family: ${props => props?.theme?.primaryFont};
  line-height: ${props => props?.theme?.body1LineHeight};
`;

const FormProfile = () => {
  const dispatch = useDispatch();
  const { site: mediaKey } = useParams();
  const { zodiacList } = useSelector(state => state.formProfile);
  const { packageCurrent } = useSelector(state => state.package);
  const { createDateOption, createMonthOption, createYearOption } =
    utilDateOption() || {};
  const { getZodiacList, getUserZodiac, createProfile } =
    FormProfileProvider() || {};
  const { getPackages } = PackageProvider();

  const { isOpenModalConfirmProfile = false, isOpenModalError = false } =
    useSelector(state => state.modal);

  const [optionDate, setOptionDate] = useState(createDateOption(31));
  const yearBC = new Date().getFullYear() + 542;
  const optionYear = createYearOption(yearBC, yearBC - 100);
  const optionMonth = createMonthOption();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      date: "",
      month: "",
      year: "",
      gender: "female",
      zodiac: "",
    },
  });

  const {
    clearErrors,
    resetField,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState,
  } = form;
  const { errors } = formState || {};

  const convertToDropDownList = zodiacListParam => {
    const shortMonthThai = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];

    const convertToThaiShortDate = stringDate => {
      /* input: stringDate (string): 18-02  */
      /* output: (string) */
      try {
        const dateArr = stringDate.split("-");
        return `${dateArr[0]} ${shortMonthThai[Number(dateArr[1] - 1)]}`;
      } catch (error) {
        return "";
      }
    };

    if (zodiacListParam && zodiacListParam.length > 0) {
      return zodiacListParam.map(item => {
        const { imagePath, name, nameEn, startDate, endDate, id } = item || {};
        return {
          label: `${name} (${convertToThaiShortDate(
            startDate,
          )} - ${convertToThaiShortDate(endDate)})`,
          value: id,
          img: `${host.img}${imagePath}`,
          id,
          startDate,
          endDate,
          name,
          nameEn,
          imagePath,
        };
      });
    }
    return [];
  };

  /* ----------------------------------- API ---------------------------------- */
  const fetchGetZodiacList = async () => {
    try {
      const result = await getZodiacList();
      dispatch(
        setFormProfileField({
          key: "zodiacList",
          value: convertToDropDownList(result),
        }),
      );
      dispatch(
        setUserField({
          key: "zodiacList",
          value: convertToDropDownList(result),
        }),
      );
    } catch (error) {
      // console.error("getZodiacList", error);
    }
  };

  const fetchGetUserZodiac = async _birthDate => {
    try {
      const response = await getUserZodiac({
        birthDate: _birthDate,
      });
      return response;
    } catch (error) {
      // console.error("getUserZodiac", error);
      return "";
    }
  };

  // eslint-disable-next-line no-unused-vars
  const fetchCreateProfile = async () => {
    try {
      const values = getValues();
      const provideDate = date =>
        new Date(date.replace(/-/g, "/")).toISOString();

      await createProfile({
        packageId: packageCurrent?.id || null,
        zodiacId: values?.zodiac,
        firstName: values?.firstname,
        lastName: values?.lastname,
        gender: values?.gender,
        birthdate: provideDate(
          `${Number(values?.year) - 543}-${values?.month}-${values?.date}`,
        ),
      });
      navigate(`/package/${packageCurrent?.mediaKey}`);
    } catch (error) {
      const { code = null, message = "" } = error.data || {};
      if (code === 403 && message === "Duplicate data") {
        dispatch(
          setModalField({
            key: "isOpenModalError",
            value: true,
          }),
        );
      } else {
        navigate("/error");
      }
    }
  };

  const fetchGetPackages = async () => {
    try {
      const packages = await getPackages();
      let packageSelected = null;
      if (packages && packages?.length > 0) {
        dispatch(
          setPackageField({
            key: "packageCurrentList",
            value: packages,
          }),
        );

        /* get package selected from slug URL */
        packageSelected = packages?.find(
          element => element?.mediaKey === mediaKey,
        );

        if (packageSelected) {
          dispatch(
            setPackageField({
              key: "packageCurrent",
              value: packageSelected,
            }),
          );
        }
      }

      return {
        packages,
        packageSelected,
      };
    } catch (error) {
      console.error("fetchGetPackages", error);
      return null;
    }
  };

  const checkProfileIsExist = async (isRedirect = true) => {
    const { packageSelected } = (await fetchGetPackages()) || {};
    if (packageSelected?.profileId) {
      if (isRedirect) {
        // navigate(`/package/${packageCurrent?.mediaKey}`);
        navigate(`/package`);
      } else {
        dispatch(
          setModalField({
            key: "isOpenModalError",
            value: true,
          }),
        );
      }
      return true;
    }

    return false;
  };

  useEffect(() => {
    checkProfileIsExist(true);
    clearErrors();
    resetField();
    fetchGetZodiacList();
  }, []);

  useEffect(() => {
    const subscription = watch(async (value, { name }) => {
      if (["date", "month", "year"].includes(name)) {
        /* get number of day in this month and this year */
        const year = value?.year || 2000;
        const daysInMonth = new Date(year, value?.month, 0).getDate();
        setOptionDate(createDateOption(daysInMonth));

        /* set date to 1 if current month has number of day lass than current day */
        if (value?.date) {
          if (value?.date > daysInMonth) setValue("date", 1);
        }

        if (value?.date && value?.month) {
          /* convert number to string (3 -> '03') */
          const formattedNumber = _number => `0${_number}`.slice(-2);

          try {
            const userZodiac = await fetchGetUserZodiac(
              `${formattedNumber(value?.date)}-${formattedNumber(
                value?.month,
              )}`,
            );
            setValue("zodiac", userZodiac.id);
          } catch (error) {
            console.log(`error:`, error);
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        /* check profileId is exist (เคยสมัครไปแล้ว) */
        const profileIsExist = await checkProfileIsExist(false);

        if (!profileIsExist) await fetchCreateProfile();
      }
    } catch (error) {
      console.log(`error:`, error);
    }
  };

  const handleModalConfirm = () => {
    dispatch(
      setModalField({
        key: "isOpenModalConfirmProfile",
        value: !isOpenModalConfirmProfile,
      }),
    );
  };

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationIcon,
  // };

  const defaultOptionsError = {
    loop: true,
    autoplay: true,
    animationData: animationIconError,
  };

  return (
    <StyleCoverInput onSubmit={handleSubmit(handleModalConfirm)}>
      <StyleContainerRow>
        <InputText
          name="firstname"
          isRequire
          label="ชื่อ"
          placeholder="กรอกชื่อ"
          errMsg="กรุณากรอกชื่อ"
          form={form}
          limit={30}
        />
        <InputText
          name="lastname"
          isRequire
          label="นามสกุล"
          placeholder="กรอกนามสกุล"
          errMsg="กรุณากรอกนามสกุล"
          form={form}
          limit={30}
        />
      </StyleContainerRow>

      {/* grnder */}
      <StyleGender>
        <div>
          <Subcaption color="textInput">เพศโดยกำเนิด</Subcaption>
          <Body2 color="statusTagErrorMain">*</Body2>
        </div>
        <RadioButtonGroup form={form} name="gender" />
      </StyleGender>

      {/* birth date */}
      <StyleContainerRow>
        <DropdownSelection
          placeholder="เลือกวัน"
          label="วันเกิด"
          name="date"
          isRequire
          errorMsg="กรุณาเลือกวันเกิด"
          options={optionDate}
          form={form}
        />
        <DropdownSelection
          placeholder="เลือกเดือน"
          label="เดือนเกิด"
          name="month"
          isRequire
          form={form}
          errorMsg="กรุณาเลือกเดือนเกิด"
          options={optionMonth}
        />
        <DropdownSelection
          placeholder="เลือกปี"
          label="ปีเกิด"
          name="year"
          isRequire
          errorMsg="กรุณาเลือกปีเกิด"
          options={optionYear}
          form={form}
        />
      </StyleContainerRow>

      {/* zodizc */}
      <StyleContainerRow>
        <SelectWithImage
          name="zodiac"
          label="ราศี"
          isRequire
          form={form}
          errorMsg="กรุณาเลือกราศี"
          options={zodiacList}
        />
      </StyleContainerRow>

      <StyleContainerRow>
        <StyleTitleText className="my-3">
          <div className="icon-star" />
          <Subcaption color="primaryLight300">
            ข้อมูลไม่สามารถแก้ไขได้ กรุณาตรวจสอบข้อมูลก่อนบันทึก
          </Subcaption>
          <div className="icon-star" />
        </StyleTitleText>
      </StyleContainerRow>

      {/* submit button */}
      <StyleContainerRow>
        <StyleButton buttonType="submit">บันทึก</StyleButton>
      </StyleContainerRow>

      <ModalConfirm
        isShowModal={isOpenModalConfirmProfile}
        btnLeftText="ยกเลิก"
        btnRightText="ยืนยัน"
        callbackBtnLeft={() => handleModalConfirm()}
        callbackBtnRight={() => {
          onSubmit();
          handleModalConfirm();
        }}
        toggle={() => handleModalConfirm()}
        width="500px"
      >
        <StyleContentModal>
          {/* <Lottie options={defaultOptions} width={150} /> */}
          <img src={ConfirmImage} alt="ConfirmImage" />
          <StyleMessage
            dangerouslySetInnerHTML={{
              __html: `กรุณายืนยันความถูกต้องของข้อมูลผู้ใช้งาน <br/>
          ข้อมูลนี้ไม่สามารถแก้ไขได้ในภายหลัง`,
            }}
          />
        </StyleContentModal>
      </ModalConfirm>

      <ModalConfirm
        isShowModal={isOpenModalError}
        btnRightText="ตกลง"
        btnLeftText={null}
        callbackBtnRight={() => {
          dispatch(
            setModalField({
              key: "isOpenModalError",
              value: false,
            }),
            navigate(`/package/${packageCurrent?.mediaKey}`),
          );
        }}
        toggle={() =>
          dispatch(
            setModalField({
              key: "isOpenModalError",
              value: false,
            }),
          )
        }
        width="500px"
      >
        <StyleContentModal>
          <Lottie options={defaultOptionsError} height={150} width={150} />
          <StyleMessage
            dangerouslySetInnerHTML={{
              __html: `ข้อมูลนี้มีในระบบแล้ว กดปุ่มตกลง เพื่อทำรายการต่อ`,
            }}
          />
        </StyleContentModal>
      </ModalConfirm>
    </StyleCoverInput>
  );
};

export default FormProfile;
