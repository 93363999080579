/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import SeparateLine from "../../common/components/SeparateLine";
import TitleText from "../../common/components/TitleText";
import { SubH6 } from "../../common/components/Font";
import CardCreateWallpaper from "./CardCreateWallpaper";
import IphoneMockup from "./IphoneMockup";
import ImgSunStarLeft from "../../asset/images/img-sun-star-left.svg";
import ImgSunStarRight from "../../asset/images/img-sun-star-rigth.svg";
import BGManyStar from "../../asset/images/bg-many-star.png";
import WallpaperLoadedProvider from "./wallpaperLoadedProvider";
import { setLoadingField } from "../loading/loadingSlice";
import { setWallpaperLoadedField } from "./wallpaperLoadedSlice";
import useExpireDate from "../../common/hooks/useExpireDate";
import { setModalAndMsgField } from "../modal/modalSlice";

const WallpaperContainer = styled.div`
  background-color: white;
  background-image: url(${ImgSunStarLeft}), url(${BGManyStar}),
    url(${ImgSunStarRight});
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-size: 250px, 100%, 250px;
  background-repeat: no-repeat;
  background-position:
    left -120px top,
    center center,
    right -70px bottom 50px;
  padding: 60px;
  border: 0;
  position: relative;
  top: -2px;

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const WallpaperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: calc(
    100% / ${props => (props?.packageCurrent === "victory" ? "1" : "2")}
  );

  @media (max-width: 430px) {
    gap: 12px;
  }
`;

const RecieveUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const RecieveUserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // flex-wrap: wrap;
  margin-top: 24px;
  width: 100%;
  justify-content: center;

  div {
    width: 280px;
    display: flex;
    justify-content: center;

    @media (max-width: 1300px) {
      // width: 100%;
    }
  }

  @media (max-width: 768px) {
    gap: 24px;
    flex-direction: column;
    align-items: center;
  }
`;

const StyleSeparateLine = styled(SeparateLine)`
  width: 80vw;

  .icon-star-small {
    font-size: 14px;
  }
`;

const WallpaperDownloaded = ({ setIsOpenModalWallpaperDownload = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getWallpaperDownloaded = null } = WallpaperLoadedProvider();

  const { wallpaperLoadedList = [] } = useSelector(
    state => state.wallpaperLoaded,
  );

  const { packageCurrent = {} } = useSelector(state => state.package);

  const handleLoading = (isShow = false) => {
    dispatch(
      setLoadingField({
        key: "isLoading",
        value: isShow,
      }),
    );
  };

  const getWallpaper = async () => {
    try {
      handleLoading(true);

      const res = await getWallpaperDownloaded({
        profileId: packageCurrent?.profileId || null, // update
      });

      if (res) {
        dispatch(
          setWallpaperLoadedField({
            key: "wallpaperLoadedList",
            value: res,
          }),
        );
      }
    } catch (err) {
      handleLoading(false);
    } finally {
      handleLoading(false);
    }
  };

  const handleOpenModalDownload = async index => {
    dispatch(
      setWallpaperLoadedField({
        key: "indexDownloaded",
        value: index,
      }),
    );
    setIsOpenModalWallpaperDownload(true);
  };

  useEffect(() => {
    if (packageCurrent) {
      getWallpaper();
    }
  }, [packageCurrent]);

  const countWallpaper = wallpaperLoadedList?.length || 0;
  const wallpaperPerMonth =
    packageCurrent?.config?.wallpaperDownload?.quotaLimit?.month || 0;

  return (
    <WallpaperContainer>
      <TitleText label="วอลเปเปอร์มงคลหนุนดวง" size="large" theme="black" />
      <WallpaperWrapper packageCurrent={packageCurrent?.mediaKey}>
        {wallpaperLoadedList &&
          wallpaperLoadedList?.map((item, index) => {
            if (index + 1 > wallpaperPerMonth) {
              return null;
            }

            return (
              <div>
                <IphoneMockup
                  image={`https://canvas.noonduang.com/wallpaper/download/${item?.downloadCode}.jpg?w=300`}
                  callbackDownload={() => {
                    return useExpireDate(packageCurrent?.expireDate)
                      ? dispatch(
                          setModalAndMsgField({
                            key: "isOpenModalOverLimitPackage",
                            value: true,
                            message: "Your package has expired",
                          }),
                        )
                      : handleOpenModalDownload(index);
                  }}
                />
              </div>
            );
          })}
        {/* render card generate wallpaper */}
        {wallpaperPerMonth - countWallpaper <= 0
          ? null
          : [...Array(wallpaperPerMonth - countWallpaper)].map(() => (
              <CardCreateWallpaper
                onClick={() => {
                  return useExpireDate(packageCurrent?.expireDate)
                    ? dispatch(
                        setModalAndMsgField({
                          key: "isOpenModalOverLimitPackage",
                          value: true,
                          message: "Your package has expired",
                        }),
                      )
                    : navigate(
                        `/package/${packageCurrent?.mediaKey}/wallpaper`,
                      );
                }}
              />
            ))}
      </WallpaperWrapper>
      <RecieveUserContainer>
        <SubH6>สิ่งที่ท่านจะได้รับ</SubH6>
        <RecieveUserWrapper>
          <TitleText label="วอลเปเปอร์" theme="goldblack" size="subtitle1" />
          <TitleText
            label="ฤกษ์มงคลติดตั้งวอลเปเปอร์"
            theme="goldblack"
            size="subtitle1"
          />
          <TitleText
            label="คำอธิบายวอลเปเปอร์"
            theme="goldblack"
            size="subtitle1"
          />
        </RecieveUserWrapper>
      </RecieveUserContainer>
      <StyleSeparateLine marginTop="40" icon="icon-star-small" />
    </WallpaperContainer>
  );
};

export default WallpaperDownloaded;
