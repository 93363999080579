import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import ButtonBack from "../common/components/ButtonBack";
import ModalComponent from "../common/components/ModalComponent";
import PackageDetail from "../common/components/PackageDetail";
import usePackageConfig from "../common/hooks/usePackageConfig";
import Layout from "../common/layout/Layout";
import { setModalField } from "../features/modal/modalSlice";
import { PackageContent } from "../features/package";
import useDisplayDate from "../common/hooks/useDisplayDate";
import host from "../config/host";

const StyleButtonBack = styled(ButtonBack)`
  padding: 8px 16px;
`;

const Package = () => {
  const dispatch = useDispatch();
  const { isOpenModalPackage = false } = useSelector(state => state.modal);

  const { packageCurrentList = [], packageSelected = [] } = useSelector(
    state => state.package,
  );

  const itemSelected = {
    ...packageCurrentList[packageSelected],
  };
  const packageItem = {
    ...itemSelected,
    name: itemSelected?.name || itemSelected?.packageName,
    config: {
      ...itemSelected?.config,
      ...itemSelected?.packageConfig,
    },
  };

  const { day: dayStart, time: timeStart } = useDisplayDate(
    packageItem.subscribeDate,
  );
  const { day: dayEnd } = useDisplayDate(packageItem.expireDate);
  const { day: dayEndShort } = useDisplayDate(packageItem.expireDate, "DDMMYY");

  const handleModalPackage = () => {
    dispatch(
      setModalField({
        key: "isOpenModalPackage",
        value: !isOpenModalPackage,
      }),
    );
  };

  return (
    <Layout>
      <StyleButtonBack label="< ย้อนกลับ" />
      <PackageContent />

      <ModalComponent
        isOpenModal={isOpenModalPackage}
        handleCloseModal={() => handleModalPackage()}
        width="50%"
        height="100%"
        isHideScrollTrack
      >
        <PackageDetail
          type="recommendDetail"
          data={{
            title: "แพ็กเกจ",
            name: `วอลเปเปอร์${packageItem.title}`,
            imageUrl: packageItem?.imagePath
              ? `${host.imageCms}${packageItem?.imagePath}`
              : "https://p16-va.lemon8cdn.com/tos-alisg-v-a3e477-sg/0137ffa1ff9b4d3c93803d33f97df99b~tplv-tej9nj120t-origin.webp",
            number: packageItem.price,
            per: "เดือน",
            isExpired: false,
            moreDetail: "เพิ่มเติม",
            data: null,
            buttonLabel: "ทดลองใช้ฟรี",
            startDate: `${dayStart}`, // "1 พย 2566 11:30",
            startTime: `${timeStart}`, // "11:30",
            endDate: `${dayEnd}`, // "30 พ.ย. 2566",
            expireAt: `ใช้งานได้ถึงวันที่ ${dayEndShort}`, // "ใช้งานได้ถึงวันที่ 30 พ.ย. 66",
            unSubscribe: "วิธียกเลิกบริการ กด *137 แล้วโทรออก",
            headerDetail: "รายการที่ได้รับ",
            detail: [...usePackageConfig(packageItem.config)],
          }}
        />
      </ModalComponent>
    </Layout>
  );
};

export default Package;
