/* eslint-disable no-useless-catch */

import { useAxios } from "../../common/hooks/useProviderAxios";
import host from "../../config/host";

const PackageProvider = () => {
  const { axios } = useAxios();

  const getPackageAll = async () => {
    try {
      const url = `${host.api}/packages/all`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      const err = error?.response;
      return err;
    }
  };

  const getPackages = async () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${host.api}/member/packages`;
        const res = await axios.get(url);
        console.log("--res--", res);
        resolve(res?.data);
      } catch (error) {
        // const err = error?.response;
        console.log("--error--", error);
        reject(error);
      }
    });
  };

  const getPackagesRecommend = async () => {
    try {
      const url = `${host.api}/packages/recommend`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      const err = error?.response;
      return err;
    }
  };

  return {
    getPackageAll,
    getPackages,
    getPackagesRecommend,
  };
};

export default PackageProvider;
