import { useEffect } from "react";
import styled from "styled-components";
import IconToast from "../../asset/images/icon-toast.svg";
import { useToast } from "../hooks/useProviderToast";
import { Caption } from "./Font";

// ToastContainer styled component
const ToastContainer = styled.div`
  min-width: 180px;
  max-width: 343px;
  width: fit-content;
  height: 60px;
  margin: auto;
  padding: 12px 24px 12px 24px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;
  font-size: 17px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  visibility: ${props => (props.$show ? "visible" : "hidden")};
  animation: ${props =>
    props.$show ? "fadeIn 0.1s ease-in-out" : "fadeOut 0.1s ease-in-out"};
  transition: visibility 0.1s linear;
  z-index: 999;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

const SpanContainer = styled(Caption)`
  white-space: pre-wrap;
  font-size: 12px;
  text-align: left;
`;

// Toast component
const Toast = () => {
  const { hideToast, label, duration, show, icon } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      hideToast();
    }, duration);

    return () => clearTimeout(timer);
  }, [show]);

  const getIcon = () => {
    switch (icon) {
      case "info":
        return IconToast;
      default:
        return IconToast;
    }
  };

  return (
    <ToastContainer $show={show}>
      <img src={getIcon()} alt="toast" />
      <SpanContainer>{label}</SpanContainer>
    </ToastContainer>
  );
};

export default Toast;
