import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  articleDetail: [],
};

export const articleDetailSlice = createSlice({
  name: "articleDetail",
  initialState,
  reducers: {
    setArticleDetailField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
  },
});

// reducer
export const articleDetailState = state => state.articleDetail;

// this action for dispatch
export const { setArticleDetailField } = articleDetailSlice.actions;

// this is for configureStore
export default articleDetailSlice.reducer;
