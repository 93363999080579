import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ModalLogout from "../../features/logout";
import Loading from "../../features/loading";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import BgDefault from "../../asset/images/bg-default.jpg";

const StyleContainer = styled.div`
  background-image: url(${BgDefault});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  height: 100%;
`;

const Content = styled.div`
  height: ${props => `calc(${props.innerHeight}px - 60px)`};
  overflow-y: auto;
  overflow-x: hidden;
`;

const Layout = ({ children }) => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const isMobileZoomed = () => {
    const widthDiff = window.screen.width - window.innerWidth;

    if (widthDiff > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const handleResize = () => {
      const height = window.innerHeight;

      // eslint-disable-next-line no-unused-vars
      const zoom = isMobileZoomed();

      if (!zoom) {
        setInnerHeight(height);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // disabled zoom on focus input
  const viewport = document.querySelector('meta[name="viewport"]');
  if (viewport !== null) {
    viewport.content = `${viewport.content},user-scalable=no`;
  }

  return (
    <StyleContainer>
      <NavigationBar />
      <Content id="content" innerHeight={innerHeight}>
        <div
          style={{
            minHeight: `calc(100vh - (60px + 189px))`,
          }}
        >
          {children}
        </div>
        <Footer />
      </Content>

      {/* === loading === */}
      <Loading />

      {/* === modal logout === */}
      <ModalLogout />
    </StyleContainer>
  );
};

export default Layout;
