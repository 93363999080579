import React, { useEffect } from "react";
import {
  useDispatch,
  useSelector,
  // useSelector
} from "react-redux";
import styled from "styled-components";
import { SubH6 } from "../../common/components/Font";
import LottoProvider from "./lottoProvider";
import { setLottoField } from "./lottoSlice";
import host from "../../config/host";
import LockComponent from "../../common/components/LockComponent";
import ImgLottoPreview from "../../asset/images/img-lotto-preview.png";

const LottoContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LottoImageCustom = styled.img`
  color: transparent;
  width: 198px;
  height: 230px;
`;

const LottoImagePreview = styled.img`
  width: auto;
  height: 230px;
`;

const StyleImgLotto = styled.div`
  // background-color: #ebebed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 12px 100px;
  border-radius: 8px;

  @media (max-width: 430px) {
    width: 300px;
    padding: 12px 0px;

    img {
      width: 150px;
      height: 100%;
    }
  }
`;

const Lotto = () => {
  const dispatch = useDispatch();
  const { lottoList = [] } = useSelector(state => state.lotto);
  const { getLotto } = LottoProvider();

  const { packageCurrent } = useSelector(state => state.package);
  const handleGetLotto = async () => {
    try {
      const res = await getLotto();
      if (res) {
        dispatch(
          setLottoField({
            key: "lottoList",
            value: res,
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetLotto();
  }, []);

  return (
    <LottoContainer>
      <SubH6>เลขสวย เลขเด็ด</SubH6>
      <StyleImgLotto>
        <LockComponent feature="lotto">
          {packageCurrent?.config?.lotto?.access === "preview" ? (
            <LottoImagePreview src={ImgLottoPreview} alt="img-lotto" />
          ) : (
            <LottoImageCustom
              src={`${host?.img}${lottoList[0]?.lotto?.mediaPath[0]?.path}`}
              alt="img-lotto"
            />
          )}
        </LockComponent>
      </StyleImgLotto>
    </LottoContainer>
  );
};

export default Lotto;
