import { useAxios } from "../../common/hooks/useProviderAxios";
import host from "../../config/host";

const WallpaperLoadedProvider = () => {
  const { axios } = useAxios();

  const getWallpaperDownloaded = async ({ profileId = null }) => {
    try {
      const url = `${host?.api}/content/wallpaper-downloaded/profile/${profileId}`;
      const res = await axios.get(url);
      return res?.data;
    } catch (ex) {
      const err = ex.data;
      throw err;
    }
  };
  return {
    getWallpaperDownloaded,
  };
};

export default WallpaperLoadedProvider;
