import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import moment from "moment";
import { Body2, Subcaption, Subtitle2, TooltipFont } from "./Font";

const StyleDropdownToggle = styled(DropdownToggle)`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: ${props => props.theme.white} !important;
  box-shadow: 0px 2px 4px 0px rgba(216, 207, 186, 0.3) inset;
  border: ${props =>
    props.error
      ? `1px solid ${props.theme.statusTagErrorMain}`
      : "none"} !important;
`;

const StyleDropdownMenu = styled(DropdownMenu)`
  width: 100%;
  margin: 4px 0px !important;
  border: none;
  padding: 8px;
  border-radius: 10px !important;
  max-height: 290px;
  overflow-y: auto;
`;

const StyleDropdownItem = styled(DropdownItem)`
  background-color: ${props => props.theme.white} !important;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 5px;
  margin: 16px 0px;

  &:hover {
    background-color: ${props => props.theme.secondaryLight200} !important;
  }
`;

const StyleGroupDropdown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
  width: 143px;
  border-radius: 20px;
  background-color: ${props => props.theme.secondaryMain};
  border: 1px solid ${props => props.theme.secondaryMain};

  span {
    font-size: 12px;

    @media (max-width: 430px) {
      font-size: 11px !important;
    }
  }
`;

const StyleIconDropdown = styled.div`
  color: ${props => props.theme.white};
  font-size: 20px;
  transform: rotate(0deg);
  position: absolute;
  right: 20px;

  ${props => props.isOpenDropdown && ` transform: rotate(-180deg); `}
`;

const StyleImage = styled.img`
  color: ${props => props.theme.secondaryMain};
  font-size: 20px;
`;

const StyleDropdownSelected = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyleTextZodiac = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 430px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const SelectWithImage = props => {
  const {
    label = "",
    selectText = "เลือกราศีของตนเอง",
    options = [],
    errorMsg = null,
    isRequire = true,
    name = "",
    placeholder = "ยังไม่มีข้อมูล",
    form = {},
  } = props;

  const {
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = form;

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [valueSelected, setValueSelected] = useState({});
  const watchValue = watch(name);

  const toggle = () => setIsOpenDropdown(!isOpenDropdown);

  const handleClick = value => {
    setValue(name, value.id);
    clearErrors(name);
    const optionSelected = options.find(item => item?.value === value.id);
    setValueSelected(optionSelected);
  };

  useEffect(() => {
    if (watchValue) {
      const optionSelected = options.find(item => item?.value === watchValue);

      if (optionSelected) {
        setError(name, "");
      }

      setValueSelected(optionSelected);
    }
  }, [watchValue]);

  let startDate = null;
  let endDate = null;

  if (valueSelected?.startDate && valueSelected?.endDate) {
    const start = moment(valueSelected?.startDate, "DD-MM");
    const end = moment(valueSelected?.endDate, "DD-MM");

    startDate = start.format("DD MMM", {
      locale: "th",
    });

    endDate = end.format("DD MMM", {
      locale: "th",
    });
  }

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {/* ======== title ======== */}
      <Subcaption color="textInput">{label} </Subcaption>
      {isRequire && <Body2 color="statusTagErrorMain">*</Body2>}

      {/* ======== dropdown ======== */}
      <Dropdown
        name={name}
        disabled={!isOpenDropdown}
        toggle={() => toggle()}
        isOpen={isOpenDropdown}
        className="mt-2"
        innerRTef={{
          ...register(name, {
            required: errorMsg,
            isRequire: {
              value: isRequire,
              message: errorMsg,
            },
          }),
        }}
      >
        <StyleDropdownToggle error={errors && errors[name]?.message}>
          <StyleDropdownSelected>
            {valueSelected?.img && (
              <StyleImage
                width={26}
                height={35}
                src={valueSelected?.img}
                alt="img-dropdown-card"
              />
            )}
            <StyleTextZodiac>
              <Subtitle2 color="secondaryDark600">
                {/* {valueSelected?.label || placeholder} */}
                {valueSelected?.name || placeholder}
              </Subtitle2>
              {startDate && (
                <Subtitle2 color="secondaryDark600">{`(${startDate} - ${endDate})`}</Subtitle2>
              )}
            </StyleTextZodiac>
          </StyleDropdownSelected>

          <StyleGroupDropdown onClick={() => toggle()}>
            <TooltipFont>{selectText}</TooltipFont>
            <StyleIconDropdown
              className="icon-dropdown"
              isOpenDropdown={isOpenDropdown}
            />
          </StyleGroupDropdown>
        </StyleDropdownToggle>

        <StyleDropdownMenu>
          {options.map(item => (
            <StyleDropdownItem onClick={() => handleClick(item)}>
              <StyleImage
                width={26}
                height={35}
                src={item?.img}
                alt="img-dropdown-card"
              />
              <Subtitle2 color="secondaryDark600">{item?.label}</Subtitle2>
            </StyleDropdownItem>
          ))}
        </StyleDropdownMenu>
      </Dropdown>

      {/* ======== error ======== */}
      {errors && errors[name]?.message && (
        <TooltipFont color="statusTagErrorMain">
          {errors[name]?.message}
        </TooltipFont>
      )}
    </div>
  );
};

export default SelectWithImage;
