import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  articleMonthly: [],
};

export const articleMonthlySlice = createSlice({
  name: "articleMonthly",
  initialState,
  reducers: {
    setArticleMonthlyField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
  },
});

// reducer
export const articleMonthlyState = state => state.articleMonthly;

// this action for dispatch
export const { setArticleMonthlyField } = articleMonthlySlice.actions;

// this is for configureStore
export default articleMonthlySlice.reducer;
