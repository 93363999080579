import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setModalAndMsgField, setModalField } from "../modal/modalSlice";
import BgCardWork from "../../asset/images/bg-card-month-01.png";

import ImageHealth from "../../asset/images/img-card-health.svg";
import ImageWork from "../../asset/images/img-card-work.svg";
import ImageLove from "../../asset/images/img-card-love.svg";
import ImageMoney from "../../asset/images/img-card-money.svg";
import BgMonthly from "../../asset/images/bg-card-monthly.jpg";
import CardMonthlyItem from "../../common/components/CardMonthly";
import { setLoadingField } from "../loading/loadingSlice";
import ArticleMonthlyProvider from "./articleMonthlyProvider";
import { setArticleMonthlyField } from "./articleMonthlySlice";
import useExpireDate from "../../common/hooks/useExpireDate";

const HoroWrapper = styled.div`
  background-image: url(${BgMonthly});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 24px;
  position: relative;
  padding: 40px 24px;
  border-radius: 10px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 40px 16px;
    width: 95%;
  }
`;

export const replaceNewlineWithBr = string => {
  return string.replaceAll(/\r?\n/g, "<br />");
};

export const cutStringFirstParagraph = string => {
  const data = string.indexOf("<br />");

  if (data !== -1) {
    const result = string.substring(0, data);

    return result.trim();
  }
  return string;
};

const CardMonthly = () => {
  const options = [
    {
      label: "การงาน",
      value: "work",
      bg: BgCardWork,
      img: ImageWork,
    },
    {
      label: "การเงิน",
      value: "money",
      bg: BgCardWork,
      img: ImageMoney,
    },
    {
      label: "ความรัก",
      value: "love",
      bg: BgCardWork,
      img: ImageLove,
    },
    {
      label: "สุขภาพ",
      value: "health",
      bg: BgCardWork,
      img: ImageHealth,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { articleMonthly = [] } = useSelector(state => state.articleMonthly);
  const packageState = useSelector(state => state.package);
  const { packageCurrent } = Object(packageState);
  const { getDetailArticleMonthly } = ArticleMonthlyProvider();

  // const handleCloseModalOverLimitPackage = async () => {
  //   await Promise.all([
  //     dispatch(
  //       setModalField({
  //         key: "isOpenModalOverLimitPackage",
  //         value: false,
  //       }),
  //     ),
  //     navigate("/package"),
  //   ]);
  // };

  const handleSeeMore = async (e, label) => {
    try {
      if (useExpireDate(packageCurrent?.expireDate)) {
        dispatch(
          setModalAndMsgField({
            key: "isOpenModalOverLimitPackage",
            value: true,
            message: "Your package has expired",
          }),
        );
      } else {
        const url = `${location?.pathname}/post?${label}`;
        navigate(url);
      }
    } catch (error) {
      const err = error.response;
      console.log(err);
    }
  };

  const getArticleMonthly = async () => {
    dispatch(
      setLoadingField({
        key: "isLoading",
        value: true,
      }),
    );
    try {
      const res = await getDetailArticleMonthly({
        packageKey: packageCurrent?.mediaKey, // update depend on mediaKey
      });

      if (res) {
        Promise.all([
          dispatch(
            setArticleMonthlyField({
              key: "articleMonthly",
              value: res,
            }),
          ),
          dispatch(
            setLoadingField({
              key: "isLoading",
              value: false,
            }),
          ),
        ]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        setLoadingField({
          key: "isLoading",
          value: false,
        }),
      );
    }
    dispatch(
      setModalField({
        key: "isModalConfirm",
      }),
    );
  };

  useEffect(() => {
    if (packageCurrent?.mediaKey) {
      getArticleMonthly();
    }
  }, [packageCurrent]);

  return (
    <HoroWrapper>
      {options.map(item => {
        const content =
          (articleMonthly &&
            articleMonthly[0]?.article?.find(i => i?.tag === item?.value)
              .content[0]) ||
          "";
        const contentSlice = replaceNewlineWithBr(content);
        return (
          <CardMonthlyItem
            label={item?.label}
            background={item?.bg}
            image={item?.img}
            handleSeeMore={e => handleSeeMore(e, item?.value)}
            content={`${cutStringFirstParagraph(contentSlice)}`}
          />
        );
      })}
    </HoroWrapper>
  );
};

export default CardMonthly;
