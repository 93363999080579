/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import ModalTemplate from "../../common/components/ModalTemplate";
import * as animationIcon from "../../asset/animations/women.json";
import { Body1, Body2 } from "../../common/components/Font";
import Button from "../../common/components/Button";

const StyleContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-top: 40px;
  padding-bottom: 30px;
`;

const BtnModalMessage = styled(Button)`
  margin: 20px 0px;
`;

const ModalOverLimitPackage = props => {
  const { isShowModal = false, handleClick = null, label = "" } = props;

  const [timeCountdown, setTimeCountdown] = useState(3);
  const [isFinish, setIsFinish] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationIcon,
  };

  const countdown = () => {
    let time = 3; /// time for countdown as milliseconds

    const timer = setInterval(() => {
      if (time <= 0) {
        clearInterval(timer);
        setIsFinish(true);
      } else {
        time -= 1;
        setTimeCountdown(time);
      }
    }, 1000);
  };

  useEffect(() => {
    if (isShowModal) {
      countdown();
    }
  }, [isShowModal]);

  if (timeCountdown === 0 && isFinish) {
    handleClick();
  }

  return (
    <ModalTemplate
      height="375px"
      width="500px"
      isShowModal={isShowModal}
      isShowIconClose={false}
      isShowBg={false}
      zIndex="9999"
    >
      <StyleContentModal>
        <Lottie options={defaultOptions} height={150} width={150} />
        <Body1>{label}</Body1>
        <Body2 color="#7145A2">กำลังเปลี่ยนหน้า ({timeCountdown})</Body2>
        <BtnModalMessage width="60%" handleClick={() => handleClick()}>
          ตกลง
        </BtnModalMessage>
      </StyleContentModal>
    </ModalTemplate>
  );
};

export default ModalOverLimitPackage;
