/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-string-refs */
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import Bowser from "bowser";
import { Subcaption } from "../../common/components/Font";
import ModalTemplate from "../../common/components/ModalTemplate";
import useExportDownloadWallpaper from "../../common/hooks/useExportDownloadWallpaper";
import { useProviderAuth } from "../../common/hooks/useProviderAuth";
import host from "../../config/host";
import ModalWallpaper from "../modalWallpaper";
import { AxiosContext } from "../../common/hooks/useProviderAxios";
import useExpireDate from "../../common/hooks/useExpireDate";
import { setModalAndMsgField } from "../modal/modalSlice";

export const StyleTextWarning = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .icon-star {
    color: #7145a2;
    font-size: 12px;
  }

  span {
    color: #7145a2;
  }
`;
const StyleButtonDownloadWallpaper = styled.input`
  all: inherit;
  text-align: center;
`;
const ModalWallpaperDownload = props => {
  const {
    toggle = null,
    isShowModal = false,
    // handleClickBtnRight = null,
  } = props || {};

  const axiosContext = useContext(AxiosContext);
  const dispatch = useDispatch();

  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isDownloadMultiple, setIsDownloadMultiple] = useState(true);
  const { wallpaperDownloadDetail = {} } =
    useSelector(state => state.wallpaperDownload) || {};

  const { accessToken = null } = useProviderAuth();

  const { indexDownloaded = -1, wallpaperLoadedList = [] } = useSelector(
    state => state.wallpaperLoaded,
  );

  const { packageCurrent = {} } = useSelector(state => state.package);

  const codeWallpaper =
    indexDownloaded >= 0
      ? wallpaperLoadedList[indexDownloaded]?.downloadCode
      : wallpaperDownloadDetail?.download;

  // NOTE - ExportDownloadWallpaper
  useEffect(() => {
    setIsFirstRender(pre => isShowModal ?? !pre);
  }, [isShowModal]);

  const handleDownloadWallpaper = (e, type) => {
    try {
      if (!useExpireDate(packageCurrent?.expireDate)) {
        useExportDownloadWallpaper(e, accessToken, axiosContext, type);
      } else if (
        packageCurrent !== null &&
        useExpireDate(packageCurrent?.expireDate)
      ) {
        dispatch(
          setModalAndMsgField({
            key: "isOpenModalOverLimitPackage",
            value: true,
            message: "Your package has expired",
          }),
        );
      }
    } catch (error) {
      console.log(`error:`, error);
    }
  };

  const CheckBrowser = () => {
    const ua = navigator.userAgent;
    let isDownloadMulti = navigator.userAgent;
    if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
      const res = Bowser.getParser(window.navigator.userAgent).parsedResult;
      const browser = res.browser.name.match(/Chrome/i);
      if (browser) {
        // alert(`platform: iPad or iPhone\nbrowser: Chrome`);
        setIsDownloadMultiple(false);
        isDownloadMulti = false;
      }
    }
    return isDownloadMulti;
  };

  useEffect(() => {
    CheckBrowser();
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      document.getElementById("download_all").addEventListener("click", e => {
        handleDownloadWallpaper(e, "download_all");
      });
      document
        .getElementById("btn-download_wall")
        .addEventListener("click", e => {
          handleDownloadWallpaper(e, "download_wall");
        });
      document
        .getElementById("btn-download_install")
        .addEventListener("click", e => {
          handleDownloadWallpaper(e, "download_install");
        });
      document
        .getElementById("btn-download_manual")
        .addEventListener("click", e => {
          handleDownloadWallpaper(e, "download_manual");
        });
    }
  }, [isFirstRender]);
  // NOTE - ExportDownloadWallpaper

  const title =
    indexDownloaded >= 0
      ? `${packageCurrent?.title || ""} ${
          wallpaperLoadedList[indexDownloaded]?.categoryName || ""
        }`
      : `${packageCurrent?.title || ""} ${
          wallpaperDownloadDetail?.categoryName || ""
        }`;

  const desc =
    indexDownloaded >= 0
      ? wallpaperLoadedList[indexDownloaded]?.description
      : wallpaperDownloadDetail?.description;

  const expireDate =
    indexDownloaded >= 0
      ? wallpaperLoadedList[indexDownloaded]?.expireDate
      : wallpaperDownloadDetail?.expireDate;

  return (
    <ModalTemplate
      isShowModal={isShowModal}
      header="ดาวน์โหลดวอลเปเปอร์"
      toggle={toggle}
      btnRight="ดาวน์โหลดทั้งหมด"
      btnRightOption={
        <StyleButtonDownloadWallpaper
          type="button"
          nd-target={host?.imgDownloadWallpaper}
          nd-id={codeWallpaper}
          // nd-id="f1638d00932b33f31405f7ed15424042a0da9053"
          name="downloadAll"
          id="download_all"
          value="ดาวน์โหลดทั้งหมด"
        />
      }
      isDownloadMultiple={isDownloadMultiple}
      height="100%"
      btnWidth="248px"
      callbackBtnLeft={() => toggle()}
      callbackBtnRight={() => null}
      labelWarning={
        <StyleTextWarning>
          <div className="icon-star" />
          <Subcaption>
            ดาวน์โหลดได้ถึงวันที่ {moment(expireDate).format("DD/MM/YYYY")}
          </Subcaption>
          <div className="icon-star" />
        </StyleTextWarning>
      }
    >
      <ModalWallpaper
        packageName={title}
        imgWallpaper={`${host?.host}/wallpaper/${codeWallpaper}.jpg?access_token=${accessToken}&w=100`}
        imgManual={`${host?.host}/wallpaper/manual/${codeWallpaper}.jpg?access_token=${accessToken}`}
        imgInstallation={`${host?.host}/wallpaper/installation/${codeWallpaper}.jpg?access_token=${accessToken}`}
        crossorigin="anonymous"
        description={desc}
        isDownloadMultiple={isDownloadMultiple}
        codeWallpaper={codeWallpaper}
        isShowModal={isShowModal}
        expireDate={expireDate}
        isConfirmModal={false}
      />
    </ModalTemplate>
  );
};

ModalWallpaperDownload.defaultProps = {};

export default ModalWallpaperDownload;
