import React from "react";
import styled from "styled-components";
import { Subtitle1 } from "./Font";

const StyleImageCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyleImage = styled.img`
  margin-bottom: 4px;
`;

const ImageCard = props => {
  const {
    width = "initial",
    height = "initial",
    image = null,
    label = null,
    fontColor = null,
  } = props;

  return (
    <StyleImageCard>
      <StyleImage src={image} alt="img-card" width={width} height={height} />
      <Subtitle1 color={fontColor || "white"}>{label}</Subtitle1>
    </StyleImageCard>
  );
};

export default ImageCard;
