import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenModalPackage: false,
  isOpenModalWallpaperDetail: false,
  isOpenModalDownloadWallpaper: false,
  isOpenModalConfirmDownload: false,
  isOpenModalLogout: false,
  isOpenModalOverLimitPackage: false,
  isOpenModalConfirmProfile: false,
  isOpenModalError: false,
  msgErrModal: "",
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
    setModalAndMsgField: (state, params) => {
      const { payload } = params;
      const { key, value, message } = payload;

      state[key] = value;
      state.msgErrModal = message;
    },
  },
});

// reducer
export const modalState = state => state.modal;

// this action for dispatch
export const { setModalField, setModalAndMsgField } = modalSlice.actions;

// this is for configureStore
export default modalSlice.reducer;
