import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  zodiacList: [],
};

export const formProfileSlice = createSlice({
  name: "formProfile",
  initialState,
  reducers: {
    setFormProfileField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
  },
});

export const formProfileState = state => state.formProfile;

export const { setFormProfileField } = formProfileSlice.actions;

export default formProfileSlice.reducer;
