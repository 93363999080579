import React, { useEffect } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
// import { useSelector } from "react-redux";
import { useAxios } from "../../common/hooks/useProviderAxios";
import * as AnimationLoading from "../../asset/animations/loading.json";

const StyleLoading = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: default;
  z-index: 2999;
`;
const Loading = () => {
  const { isLoading } = useAxios();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationLoading,
  };

  useEffect(() => {
    if (document.getElementById("content")) {
      if (isLoading) {
        document.getElementById("content").style.overflowY = "hidden";
      } else {
        document.getElementById("content").style.overflowY = "auto";
      }
    }
  }, [isLoading]);

  if (!isLoading) {
    return null;
  }

  return (
    <StyleLoading>
      <Lottie
        options={defaultOptions}
        style={{
          cursor: "default",
          width: "100px",
        }}
      />
    </StyleLoading>
  );
};

export default Loading;
