import React from "react";
import styled from "styled-components";
import Button from "../../common/components/Button";
import { Subtitle1 } from "../../common/components/Font";
import ImageWallpaperCard from "../../asset/images/Wallpaper-inactive.png";

const CardCreateContainer = styled.div`
  width: auto;
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 0px 10px 0px rgba(240, 189, 90, 0.8) !important;
    button {
      filter: brightness(1.1);
    }
  }

  @media (max-width: 430px) {
    height: 350px !important;
  }
`;

const ImageWallpaperCreate = styled.img`
  // width: 100%;
  height: 433px;
  border-radius: 10px;
  // width: auto;

  @media (max-width: 430px) {
    height: 350px !important;
  }
`;

const StyleButton = styled.div`
  width: 80%;
  border-radius: 10px;
  position: absolute;
  bottom: 86px;
`;

const CardCreateWallpaper = ({ onClick }) => {
  return (
    <CardCreateContainer onClick={onClick}>
      <ImageWallpaperCreate src={ImageWallpaperCard} alt="create wallpaper" />
      <StyleButton>
        <Button isBrightness={false}>
          <Subtitle1>สร้างวอลเปเปอร์</Subtitle1>
        </Button>
      </StyleButton>
    </CardCreateContainer>
  );
};

CardCreateWallpaper.defaultProps = {
  onClick: () => {},
};

export default CardCreateWallpaper;
