import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { StarIcon } from "../../common/components/PackageDetail";
import { setPackageField } from "../package/packageSlice";
import host from "../../config/host";
import {
  differenceDate,
  displayDateTh,
  displayDateThShortYear,
} from "../../common/utils/timeUtils";
import { ReactComponent as IconStarGolden } from "../../asset/images/icon-star-golden.svg";
import { ReactComponent as IconStarCircle } from "../../asset/images/icon-star-circle.svg";
import BackgroundProfileImage from "../../asset/images/BG-Card-Profile.jpg";
import { Body2, SubH5 } from "../../common/components/Font";
import ImageCard from "../../common/components/ImageCard";
import { setUserField } from "./userProfileSlice";
import CalendarImg from "../../asset/images/calendar.svg";
import FormProfileProvider from "../formProfile/formProfileProvider";
import { useFetchMyPackage } from "../package/useFetchMyPackage";
import { setModalAndMsgField } from "../modal/modalSlice";
import ProfileMale from "../../asset/images/male-profile.png";
import ProfileFeMale from "../../asset/images/female-profile.png";

const CardWrap = styled.div`
  width: ${props => props.width || "600px"};
  height: ${props => props.height || "350px"};
  background: white;
  border: 1px solid #eedebc;
  border-radius: 36px;
  position: relative;
  background-image: url(${BackgroundProfileImage});
  background-size: cover;
  background-position: left;

  @media (max-width: 768px) {
    width: auto;
    height: auto;
  }
`;

const IconStar = styled(IconStarCircle)`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  transform: ${props => props.transform};
  @media (max-width: 768px) {
    right: ${props => props.left};
    left: ${props => props.right};
    &:nth-child(1) {
      left: auto;
    }
    &:nth-child(2) {
      left: auto;
    }
  }
`;

const ProfileCard = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  height: 100%;
  justify-content: center;

  @media (max-width: 768px) {
    /* width: 200px; */
    width: 317px;
    height: 500px;
    flex-direction: column;
  }
`;

const GenderImage = styled.div`
  & img {
    width: 172px;
  }
  @media (max-width: 768px) {
    & img {
      width: 220px;
    }
    width: 215px;
    height: 491px;
    flex-direction: column;
    margin-top: 10%;
  }
`;

const InfoProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

const VerticalSeparator = styled.div`
  height: 196px;
  width: 0.3px;
  background-color: ${props => props?.theme?.goldB};

  @media (max-width: 768px) {
    height: 1px;
    width: 192px;
  }
`;

const VerticalWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px;
  gap: 8px;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

const DetailDateWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 24px;
`;

const NoticeText = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
    margin-top: 24px;
  }
`;

const StyleName = styled(SubH5)`
  max-width: 130px;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;

  @media (max-width: 430px) {
    font-size: 20px !important;
    max-width: 220px;
    display: -webkit-box;
    overflow: hidden;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
`;

const StyleCoverName = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;

  @media (max-width: 430px) {
    flex-wrap: wrap;
  }
`;

const useHookCardProfile = () => {
  const { fetchMyPackages } = useFetchMyPackage();
  const { getZodiacList, getProfile } = FormProfileProvider();
  const userProfileState = useSelector(state => state.userProfile);
  const packageState = useSelector(state => state.package);
  // const formProfileState = useSelector(state => state.formProfile);
  const [mergedDateTime, setMergedDateTime] = useState(null);
  const dispatch = useDispatch();
  // const { gender, name, surname, tel, horoScope, birthdate } = props;
  const { userProfile, zodiacInfo } = Object(userProfileState);
  const navigate = useNavigate();
  const {
    gender,
    firstName,
    lastName,
    displayName,
    birthDate,
    birthTime,
    zodiacId,
    zodiacName,
  } = Object(userProfile);

  useEffect(() => {
    /// merge moment date & time
    const birthdateMoment = moment(birthDate);
    const birthtimeMoment = moment(birthTime, "HH:mm:ss");
    const mergedDateTimeMoment = birthdateMoment.set({
      hour: birthtimeMoment.hour(),
      minute: birthtimeMoment.minute(),
      second: birthtimeMoment.second(),
    });
    setMergedDateTime(mergedDateTimeMoment.format("YYYY-MM-DD HH:mm:ss"));
  }, [birthDate, birthTime]);
  const { packageCurrent, packageCurrentList } = packageState;
  const { expireDate } = Object(packageCurrent);
  const diffDateExpire = differenceDate(moment(expireDate), new Date());

  const findPackage = () => {
    const url = window.location.pathname;
    const lastSegment = url.substring(url.lastIndexOf("/") + 1);
    const decodedStringPackage = decodeURIComponent(lastSegment);
    const packageSelect = packageCurrentList.find(
      e => e.mediaKey === decodedStringPackage,
    );
    if (packageSelect === undefined) {
      dispatch(
        setModalAndMsgField({
          key: "isOpenModalOverLimitPackage",
          value: true,
          message: "Member not subscribe package",
        }),
      );
    } else if (
      packageSelect !== undefined &&
      packageSelect?.profileId === null
    ) {
      navigate("/package");
    } else {
      dispatch(
        setPackageField({
          key: "packageCurrent",
          value: packageSelect,
        }),
      );
    }
  };

  const getZodiac = async () => {
    try {
      const res = await getZodiacList();
      dispatch(
        setUserField({
          key: "zodiacInfo",
          value: res.find(zo => zo.id === zodiacId),
        }),
      );
    } catch (error) {
      console.log(`error:`, error);
    }
  };

  const handleGetProfile = async () => {
    try {
      const profile = await getProfile(packageCurrent?.profileId);
      dispatch(
        setUserField({
          key: "userProfile",
          value: profile,
        }),
      );
    } catch (error) {
      console.log(`error:`, error);
    }
  };

  useEffect(() => {
    if (packageCurrentList?.length > 0 && packageCurrent?.profileId !== null) {
      handleGetProfile();
      findPackage();
    }
  }, [packageCurrentList, packageCurrent?.profileId]);

  useEffect(() => {
    getZodiac();
  }, [userProfileState.userProfile]);

  useEffect(() => {
    fetchMyPackages(true);
    return () => {};
  }, []);

  return {
    zodiacInfo,
    gender,
    firstName,
    lastName,
    displayName,
    birthDate,
    birthTime,
    zodiacId,
    zodiacName,
    mergedDateTime,
    diffDateExpire,
    expireDate,
  };
};

const CardProfile = () => {
  const {
    zodiacInfo,
    gender,
    firstName,
    lastName,
    displayName,
    // zodiacId,
    zodiacName,
    mergedDateTime,
    diffDateExpire,
    expireDate,
  } = useHookCardProfile();

  return (
    <CardWrap>
      <IconStar top="35px" left="-10px" />
      <IconStar top="-30px" left="55px" transform="rotate(270deg)" />
      <IconStar right="-10px" bottom="29px" />
      <IconStar right="55px" bottom="-30px" transform="rotate(90deg)" />
      <div>
        <ProfileCard>
          <GenderImage>
            {gender === "female" ? (
              <img src={ProfileFeMale} alt="ProfileMale" />
            ) : (
              <img src={ProfileMale} alt="ProfileMale" />
            )}
          </GenderImage>
          <VerticalWrap>
            <IconStarGolden />
            <VerticalSeparator />
            <IconStarGolden />
          </VerticalWrap>
          <InfoProfile>
            <StyleCoverName>
              <StyleName color="white">{firstName}</StyleName>
              <StyleName color="white">{lastName}</StyleName>
            </StyleCoverName>

            <Body2 color="white">{displayName}</Body2>
            <DetailDateWrap>
              <ImageCard
                image={`${host.img}${zodiacInfo?.imagePath}`}
                width="56px"
                height="64px"
                label={zodiacName && `ราศี${zodiacName}`}
              />
              <ImageCard
                image={CalendarImg}
                width="56px"
                height="64px"
                label={displayDateTh(mergedDateTime, "DD MMMM YYYY")}
              />
            </DetailDateWrap>
          </InfoProfile>
        </ProfileCard>
      </div>

      <NoticeText>
        <Body2 color="primaryMain">
          คุณได้รับการหนุนดวงเสริมชะตาเรียบร้อยแล้ว
        </Body2>
      </NoticeText>

      {diffDateExpire <= 3 ? (
        <NoticeText>
          <StarIcon className="icon-star" color="#fe4949" font="14px" />
          <Body2 color="statusTagErrorMain">
            {differenceDate(moment(expireDate), new Date()) < 1 &&
            moment(expireDate).startOf("day").isSame(moment().startOf("day"))
              ? `หนุนดวงเสริมชะตาใกล้หมดอายุเวลา ${displayDateThShortYear(
                  expireDate,
                  "HH:mm น.",
                )}`
              : `หนุนดวงเสริมชะตาใกล้หมดอายุอีก ${diffDateExpire} วัน`}
          </Body2>
          <StarIcon className="icon-star" color="#fe4949" font="14px" />
        </NoticeText>
      ) : null}
    </CardWrap>
  );
};

CardProfile.defaultProps = {
  gender: "male",
  name: "janny",
  lastname: "eieiza",
  tel: "029993449xxx",
  horoScope: {
    name: "เมถุน",
    img: "https://f.ptcdn.info/251/014/000/1389203627-31488955-o.jpg",
  },
  birthdate: {
    date: "29/03/2530",
    img: "https://f.ptcdn.info/536/015/000/1392110148-99-o.jpg",
  },
};

export default CardProfile;
