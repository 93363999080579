import React from "react";
import styled from "styled-components";
import IconStarBlack from "../../asset/images/icon-star-black.svg";
import IconStarGoldren from "../../asset/images/icon-star-golden.svg";
import IconStarWhite from "../../asset/images/icon-star-white.svg";
import { SubH6, Subcaption, Subtitle1 } from "./Font";

export const TitleTextStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const IconStarStyle = styled.img`
  width: ${props => props.$size};
  hight: ${props => props.$size};
`;

const TitleText = props => {
  const { label, theme, size, onClick, className = "" } = props || {};

  const getElementColor = () => {
    switch (theme) {
      case "white":
        return {
          colorIcon: IconStarWhite,
          colorText: "#FFF",
        };
      case "black":
        return {
          colorIcon: IconStarBlack,
          colorText: "#000",
        };
      case "gold":
        return {
          colorIcon: IconStarGoldren,
          colorText: "#FFF",
        };
      case "goldblack":
        return {
          colorIcon: IconStarGoldren,
          colorText: "#000",
        };
      default:
        return {
          colorIcon: IconStarWhite,
          colorText: "#FFF",
        };
    }
  };

  const { colorIcon, colorText } = getElementColor();

  const getElementSize = () => {
    switch (size) {
      case "small":
        return {
          sizeIcon: "6px",
          TextElement: <Subcaption color={colorText}> {label}</Subcaption>,
        };
      case "medium":
        return {
          sizeIcon: "12px",
          TextElement: <SubH6 color={colorText}> {label}</SubH6>,
        };
      case "large":
        return {
          sizeIcon: "12px",
          TextElement: <SubH6 color={colorText}> {label} </SubH6>,
        };
      case "subtitle1":
        return {
          sizeIcon: "12px",
          TextElement: <Subtitle1 color={colorText}> {label} </Subtitle1>,
        };
      default:
        return {
          sizeIcon: "6px",
          TextElement: <Subcaption color={colorText}> {label}</Subcaption>,
        };
    }
  };
  const { sizeIcon, TextElement } = getElementSize();

  return (
    <TitleTextStyle onClick={onClick} className={className}>
      <IconStarStyle src={colorIcon} $size={sizeIcon} />
      {TextElement}
      <IconStarStyle src={colorIcon} $size={sizeIcon} />
    </TitleTextStyle>
  );
};

TitleText.defaultProps = {
  label: "" /* label text (string) : "หัวข้อการแสดงผล" */,
  theme: "balck" /* theme icon color (string) : "balck", "white", "gold"  */,
  size: "large" /* label size (string) : "small", "medium", "large" */,
  onClick: () => {},
};

export default TitleText;
