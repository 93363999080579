const useRandomLuckyNumber = (digits = 3) => {
  return Array.from(
    String(
      Math.floor(Math.random() * 10 ** digits + 10 ** digits)
        .toString()
        .slice(-digits),
    ),
    Number,
  );
};

export default useRandomLuckyNumber;
