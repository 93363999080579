import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallpaperDownloadList: [],
  indexWallpaperDetail: -1,
  modalDownloadDetail: {},
  indexWallpaperPreview: -1,
  wallpaperDownloadDetail: {},
};

export const wallpaperDownloadSlice = createSlice({
  name: "wallpaperDownload",
  initialState,
  reducers: {
    setWallpaperDownloadField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
  },
});

// reducer
export const wallpaperDownloadState = state => state.wallpaperDownload;

// this action for dispatch
export const { setWallpaperDownloadField } = wallpaperDownloadSlice.actions;

// this is for configureStore
export default wallpaperDownloadSlice.reducer;
