import React from "react";
import styled from "styled-components";

/** @type {*} 
 *  <Image
        src={''}
        alt={''}
        width={'auto'} //string
        height={'auto'} //string
        marginTop={0}
        marginBottom={0}
        marginLeft={0}
        marginRight={0}
        color={'transparent'}
        backgroundColor={'transparent'}
    />
 * 
*/

const ImageStyle = styled.img.attrs(props => props)`
  width: ${props => props.width || "0px"};
  height: ${props => props.height || "0px"};
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  margin-right: ${props => props.marginRight || 0}px;
  color: ${props => props.theme[props.color] || props.color};
  background-color: ${props =>
    props.theme[props.backgroundColor] || props.backgroundColor};
`;

const Image = props => {
  const {
    className = "",
    src = "",
    alt = "",
    width = "auto",
    height = "auto",
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    color = "transparent",
    backgroundColor = "transparent",
  } = props;

  return (
    <ImageStyle
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={height}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      color={color}
      backgroundColor={backgroundColor}
    />
  );
};

export default Image;
