import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPackageField } from "../features/package/packageSlice";
import host from "../config/host";
import PackageProvider from "../features/package/packageProvider";
import Layout from "../common/layout/Layout";
import { H6 } from "../common/components/Font";
import TitleText from "../common/components/TitleText";
import Banner from "../asset/images/banner-mainpage.jpeg";
import Background from "../asset/images/bg-sky-bottom.png";
import PackageImage from "../asset/images/package-table.png";
// import PackageImage1 from "../asset/images/img-package1.png";
// import PackageImage2 from "../asset/images/img-package2.png";
// import PackageImage3 from "../asset/images/img-package3.png";
// import PackageImage4 from "../asset/images/img-package4.png";
// import PackageImage5 from "../asset/images/img-package5.png";

const MainPageContainer = styled.div`
  // background-image: url(${Background});
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 32px 0;
`;

const BannerTop = styled.img`
  width: 100%;
  max-width: 1500px;
`;

const Content = styled.div`
  margin-top: 60px;
`;

const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: ${props => props.marginTop};
  padding: 16px;

  @media (max-width: 430px) {
    width: ${props => (props?.width ? props?.width : "100%")};
    /* word-spacing: 9999px; */

    span {
      text-align: center;
      font-size: 20px !important;
    }
  }
`;

const ImagePackageWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    padding: 0px 16px;
  }
`;

const CardPackageImage = styled.img`
  cursor: pointer;
  width: 382px;
  height: 382px;
  border-radius: 5px;

  @media (max-width: 430px) {
    width: 100%;
    height: 100%;
  }
`;

const PackageImageCard = styled.img`
  width: 100%;
  max-width: 887px;
`;

const useHookMainPage = () => {
  const [data, setData] = useState([]);
  const { getPackageAll } = PackageProvider();
  const dispatch = useDispatch();

  const handleGetPackageAll = async () => {
    try {
      const res = await getPackageAll();
      dispatch(
        setPackageField({
          key: "packageAll",
          value: res,
        }),
      );
      setData(res);
      // setData([
      //   {
      //     imagePath: PackageImage1,
      //     mediaKey: "lucky",
      //   },
      //   {
      //     imagePath: PackageImage2,
      //     mediaKey: "love",
      //   },
      //   {
      //     imagePath: PackageImage3,
      //     mediaKey: "money",
      //   },
      //   {
      //     imagePath: PackageImage4,
      //     mediaKey: "work",
      //   },
      //   {
      //     imagePath: PackageImage5,
      //     mediaKey: "health",
      //   },
      // ]);
    } catch (error) {
      console.log(`error:`, error);
    }
  };

  useEffect(() => {
    handleGetPackageAll();
  }, []);

  return {
    data,
  };
};

const MainPage = () => {
  const navigate = useNavigate();
  const { data } = useHookMainPage();
  return (
    <Layout>
      <MainPageContainer>
        <ContentText>
          <BannerTop src={Banner} />
        </ContentText>
        <Content>
          <ContentText>
            <TitleText label="บริการหนุนดวง" theme="white" />
            <H6 color="white">วอลเปเปอร์ เสริมดวงสุดพลัง</H6>
          </ContentText>

          <ImagePackageWrap>
            {data.map(packageItem => (
              <CardPackageImage
                src={`${host?.img}${packageItem.howtoImagePath}`}
                // src={`${packageItem.imagePath}`}
                onClick={() =>
                  navigate(`/package/${packageItem.mediaKey}/howto`)
                }
              />
            ))}
          </ImagePackageWrap>
        </Content>

        <ContentText marginTop="60px" width="100%">
          <TitleText label="เปรียบเทียบรายละเอียด แต่ละแพ็กเกจ" theme="gold" />
        </ContentText>

        <ImagePackageWrap>
          <PackageImageCard src={PackageImage} />
        </ImagePackageWrap>
      </MainPageContainer>
    </Layout>
  );
};

export default MainPage;
