const theme = {
  /* color */
  black: "#000000",
  white: "#ffffff",

  primaryMain: "#eac16f",
  primaryLight300: "#f9ecd4",
  primaryBorder300: "#fdf9f1",
  primaryBG200: "#f2f3f4",
  primaryDark600: "#090522",

  secondaryMain: "#06192f",
  secondaryLight300: "#123a51",
  secondaryLight200: "#dde6ea",
  secondaryDark600: "#040f20",

  textPrimaryB: "#000000",
  textPrimaryW: "#ffffff",
  textSecondary: "#252527",
  textTertiary: "#6b6b6b",
  textInput: "#aeafaf",
  textDisable25: "rgba (42, 44, 45, 0.25)",

  actionMain: "#afaeae",
  actionDividerLine: "#eeeae2",
  actionBorderStroke: "#c9a251",
  actionBorderStroke300: "#efe9e0",
  actionDisable: "#cbcbcb",
  actionIcon: "#eac16f",

  statusTagSuccess: "#0b5c52",
  statusTagSuccessBG: "#e9eeec",
  statusTagWarning: "#b55232",
  statusTagWarningBG: "#fae9e3",
  statusTagErrorMain: "#fe4949",
  statusTagErrorMainBG: "#f2e3e3",
  statusTagErrorDark: "#782626",

  goldB: "#EEDEBC",

  otherGoldA:
    "linear-gradient(165deg, #9d8490 -18.09%, #ffd98b 26.79%, #ac873a 58.66%, #ffecc5 100.05%)",
  otherGoldB:
    "linear-gradient(215deg, #EEDEBC 11.15%, #EEDEBC 22.81%, #DDC5A3 38.35%, #C9A885 58.55%, #9A7A5F 67.09%, #C9A885 76.42%, #EEDEBC 88.85%)",

  otherGoldC:
    "linear-gradient(89deg, #E3BB55 -30.52%, #C08229 5.82%, #EED577 43.56%, #F7EA82 77.81%, #C4942C 109.25%)",
  /* color */

  /* fonts */
  primaryFont: '"Noto Sans Thai", sans-serif',

  h4Weight: 500,
  h4Size: "34px",
  h4LineHeight: "32px",
  h4Spacing: "0px",

  h5Weight: 500,
  h5Size: "24px",
  h5LineHeight: "32px",
  h5Spacing: "0px",

  subH5Weight: 700,
  subH5Size: "24px",
  subH5LineHeight: "30px",
  subH5Spacing: "0px",

  h6Weight: 400,
  h6Size: "18px",
  h6LineHeight: "24px",
  h6Spacing: "0px",

  subH6Weight: 700,
  subH6Size: "18px",
  subH6LineHeight: "26px",
  subH6Spacing: "0.4px",

  body1Weight: 400,
  body1Size: "16px",
  body1LineHeight: "24px",
  body1Spacing: "0.15px",

  subTitle1Weight: 600,
  subTitle1Size: "16px",
  subTitle1LineHeight: "20px",
  subTitle1Spacing: "0px",

  subTitle1UnderlineWeight: 600,
  subTitle1UnderlineSize: "16px",
  subTitle1UnderlineLineHeight: "20px",
  subTitle1UnderlineSpacing: "0px",

  body2Weight: 400,
  body2Size: "14px",
  body2LineHeight: "20px",
  body2Spacing: "0.15px",

  subTitle2Weight: 700,
  subTitle2Size: "14px",
  subTitle2LineHeight: "22px",
  subTitle2Spacing: "0px",

  subTitle2UnderlineWeight: 600,
  subTitle2UnderlineSize: "14px",
  subTitle2UnderlineLineHeight: "20px",
  subTitle2UnderlineSpacing: "0px",

  captionWeight: 400,
  captionSize: "12px",
  captionLineHeight: "16px",
  captionSpacing: "0px",

  captionLineWeight: 400,
  captionLineSize: "12px",
  captionLineLineHeight: "16px",
  captionLineSpacing: "0px",

  subCaptionWeight: 600,
  subCaptionSize: "12px",
  subCaptionLineHeight: "16px",
  subCaptionSpacing: "0px",

  subCaptionUnderlineWeight: 600,
  subCaptionUnderlineSize: "12px",
  subCaptionUnderlineLineHeight: "16px",
  subCaptionUnderlineSpacing: "0px",

  tooltipWeight: 400,
  tooltipSize: "10px",
  tooltipLineHeight: "14px",
  tooltipSpacing: "0px",

  subTooltipWeight: 600,
  subTooltipSize: "10px",
  subTooltipLineHeight: "14px",
  subTooltipSpacing: "0px",
  /* fonts */
};

export default theme;
