const useExportDownloadWallpaper = (e, accessToken, axiosContext, type) => {
  try {
    axiosContext.changeLoading(true);
    const target = e.target.getAttribute("nd-target").trim();
    const id = e.target.getAttribute("nd-id").trim();
    // const id = "f1638d00932b33f31405f7ed15424042a0da9053";
    e.preventDefault();

    // https://canvas.noonduang.com/wallpaper/download/1821c3f1037db84659378600019d3cdd91fa522a.jpg?download=true
    // const fileNameImage = `.jpg`;
    // const path = `${id + fileNameImage}`;
    // const canvasWallDownload = `${target}/wallpaper/download/${path}`;
    // const canvasWallManual = `${target}/wallpaper/manual/${path}`;
    // const canvasWallInstallation = `${target}/wallpaper/installation/${path}`;

    // https://noonduang-api.saranros.com/latest/wallpaper/installation/01dce8f1094bbe202d7a052e1f371dedbb73a03d.jpg?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjYxLCJpYXQiOjE3MDYwNjMzNzEsImV4cCI6MTcwNjE0OTc3MSwidHlwZSI6Im1lbWJlciIsInRva2VuVHlwZSI6ImFjY2VzcyJ9.Lhsv3DUUIUEAER7zCwSPf2QxEAjAEC_OVfGDCI
    const fileNameImage = `?access_token=${accessToken}`;
    const path = `${id + fileNameImage}`;
    const canvasWallDownload = `${target}/wallpaper/download/${path}`;
    const canvasWallManual = `${target}/wallpaper/download-manual/${path}`;
    const canvasWallInstallation = `${target}/wallpaper/download-installation/${path}`;

    if (type === "download_all") {
      document.getElementById("download_wall").src = canvasWallDownload;
      document.getElementById("download_manual").src = canvasWallManual;
      document.getElementById("download_install").src = canvasWallInstallation;
    } else if (type === "download_wall") {
      document.getElementById("download_wall").src = canvasWallDownload;
    } else if (type === "download_manual") {
      document.getElementById("download_manual").src = canvasWallManual;
    } else if (type === "download_install") {
      document.getElementById("download_install").src = canvasWallInstallation;
    }
  } catch (error) {
    console.log(`error:`, error);
  } finally {
    setTimeout(
      () => {
        axiosContext.changeLoading(false);
      },
      type === "download_all" ? 7000 : 3500,
    );
  }
};

export default useExportDownloadWallpaper;
