import React from "react";
import styled from "styled-components";
import { ReactComponent as SunStarIcon } from "../../asset/images/img-sun.svg";
import { ReactComponent as SunStarIconRight } from "../../asset/images/img-moon.svg";

const CardPackageWrap = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 20px;
  position: relative;
  box-shadow: 4px 4px 10px 0px rgba(146, 143, 171, 0.2);
  background: ${props => props?.background};
`;

const SunStar = styled(SunStarIcon)`
  position: absolute;
`;
const SunStarRight = styled(SunStarIconRight)`
  position: absolute;
  bottom: 20px;
  right: 0;
`;

const CardPackage = props => {
  const { width, height, children = null, background = "white" } = props;
  return (
    <CardPackageWrap width={width} height={height} background={background}>
      <SunStar />
      <SunStarRight />
      {children}
    </CardPackageWrap>
  );
};

CardPackageWrap.defaultProps = {
  width: "398px",
  // height: "549px",
  // height: "auto",
  height: "100%",
};

export default CardPackage;
