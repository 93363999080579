import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallpaperLoadedList: [],
  indexDownloaded: -1,
};

export const wallpaperLoadedSlice = createSlice({
  name: "wallpaperLoaded",
  initialState,
  reducers: {
    setWallpaperLoadedField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },

    resetWallpaperLoaded: state => {
      state.wallpaperLoadedList = [];
      state.indexDownloaded = -1;
    },
  },
});

// reducer
export const wallpaperLoadedState = state => state.wallpaperLoaded;

// this action for dispatch
export const { setWallpaperLoadedField, resetWallpaperLoaded } =
  wallpaperLoadedSlice.actions;

// this is for configureStore
export default wallpaperLoadedSlice.reducer;
