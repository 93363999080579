import React from "react";
import styled from "styled-components";

const StyleContent = styled.div``;

const DangerousHTMLComponent = ({ htmlContent = null }) => {
  if (htmlContent === null) {
    return null;
  }

  return (
    <StyleContent
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: htmlContent,
      }}
    />
  );
};

export default DangerousHTMLComponent;
