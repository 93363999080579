import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packageCurrentList: [],
  packageRecommendList: [],
  packageSelected: 0,
  packageCurrent: {},
  accessPackage: [],
};

export const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    setPackageField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
  },
});

export const packageState = state => state.package;

export const { setPackageField } = packageSlice.actions;

export default packageSlice.reducer;
