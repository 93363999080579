import React, { useState } from "react";
import { FormGroup, Label, CardBody } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import FemaleImage from "../../asset/images/female.svg";
import MaleImage from "../../asset/images/male.svg";
import { ReactComponent as IconStar } from "../../asset/images/icon-3-star.svg";
import { Body2 } from "./Font";
import Checkbox from "./Checkbox";

const CardWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 16px;
  flex-wrap: wrap;
`;

const CardImg = styled.img`
  width: 79px;
  height: 61px;
  object-fit: contain;
  transition: transform 0.3s ease;
`;

const Card = styled.div`
  /* text-align: center; */
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 10px;
  background: ${props =>
    props.isSelected ? "#F9EDD1" : props.theme.primaryBG200};
  padding: 8px;
  width: 96px !important;
  height: 124px;
  justify-content: center;
  align-items: center;
  border: ${props =>
    `1px solid ${
      props.isSelected ? props.theme.actionIcon : props.theme.white
    }`};
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Star = styled.div`
  position: absolute;
  top: ${props => `${props.top}px`};
`;

const CardBodyCustom = styled(CardBody)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const RadioButtonGroup = props => {
  const { options, form = {}, name = "" } = props;
  const { setValue, getValues } = form;

  const [radioSelected, setRadioSelected] = useState("female");

  const handleRadioChange = event => {
    setValue(name, event);
    const values = getValues(name);
    setRadioSelected(values);
  };

  const renderCard = (val, imageUrl, label) => (
    <Card
      key={val}
      isSelected={radioSelected === val}
      onClick={() => handleRadioChange(val)}
    >
      <Star top={-14}>
        <IconStar />
      </Star>
      <CardImg top width="100%" src={imageUrl} alt={`Option-${val}`} />
      <CardBodyCustom>
        <Body2>{label}</Body2>
        {/* <br /> */}
        <Checkbox
          name="radioGroup"
          value={val}
          checked={radioSelected === val}
          onChange={handleRadioChange}
        />
        <Star top={109}>
          <IconStar />
        </Star>
      </CardBodyCustom>
    </Card>
  );
  return (
    <FormGroup>
      {/* <legend>Choose an option:</legend> */}
      <CardWrapper>
        {options.map(option => (
          <Label key={option.value} check>
            {renderCard(option.value, option.imageUrl, option.label)}
          </Label>
        ))}
      </CardWrapper>
    </FormGroup>
  );
};

RadioButtonGroup.defaultProps = {
  options: [
    {
      value: "male",
      label: "ชาย",
      imageUrl: MaleImage,
    },
    {
      value: "female",
      label: "หญิง",
      imageUrl: FemaleImage,
    },
  ],
  onChange: () => console.log(),
  value: "female",
};

export default RadioButtonGroup;
