import host from "../../config/host";
import { useAxios } from "../../common/hooks/useProviderAxios";

const ArticleMonthlyProvider = () => {
  const { axios } = useAxios();
  const getDetailArticleMonthly = async ({ packageKey = null }) => {
    try {
      const url = `${host?.api}/content/private?categoryKey=article&packageKey=${packageKey}&type=article&tag=work&tag=love&tag=health&tag=money`;
      const res = await axios.get(url);
      return res.data;
    } catch (ex) {
      const err = ex.response.data;
      throw err;
    }
  };
  return {
    getDetailArticleMonthly,
  };
};

export default ArticleMonthlyProvider;
