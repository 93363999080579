import { useAxios } from "../../common/hooks/useProviderAxios";
import host from "../../config/host";

const LottoProvider = () => {
  const { axios } = useAxios();
  const getLotto = async () => {
    try {
      const url = `${host?.api}/content/public?type=lotto_number`;
      const res = await axios.get(url);
      return res.data;
    } catch (ex) {
      const err = ex?.response?.data;
      throw err;
    }
  };
  return {
    getLotto,
  };
};

export default LottoProvider;
