/* eslint-disable no-undef */
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import ModalOverLimitPackage from "../features/wallpaperDownload/ModalOverLimitPackage";
import {
  setModalAndMsgField,
  setModalField,
} from "../features/modal/modalSlice";
import FeatureLuckyNumber from "../features/luckyNumber";
import { setPackageField } from "../features/package/packageSlice";
import LockComponent from "../common/components/LockComponent";
import enhanceProvider from "../features/enhance/enhanceProvider";
import PackageProvider from "../features/package/packageProvider";
import { displayDateThShortYear } from "../common/utils/timeUtils";
import host from "../config/host";
import WallpaperDownloaded from "../features/wallpaperLoaded";
import Lotto from "../features/lotto";
import CardProfile from "../features/userProfile";
import SeparateLine from "../common/components/SeparateLine";
import TitleText from "../common/components/TitleText";
import ImageCard from "../common/components/ImageCard";
import VideoWidget from "../features/video";
import ImageDetail from "../features/enhance";
// eslint-disable-next-line import/no-named-as-default
import BGManyStar from "../asset/images/bg-many-star.png";
import ButtonBack from "../common/components/ButtonBack";
import { Caption, H5 } from "../common/components/Font";
import Layout from "../common/layout/Layout";
import CardMonthly, {
  cutStringFirstParagraph,
  replaceNewlineWithBr,
} from "../features/articleMonthly/index";
import BarCloud from "../asset/images/bar-cloud.png";
import BGSkyAll from "../asset/images/bg-sky.png";
import { ReactComponent as IconStarMoon } from "../asset/images/img-star-moon.svg";
import ModalWallpaperDownload from "../features/modalWallpaperDetail/ModalWallpaperDownload";
import VideoProvider from "../features/video/videoProvider";
import errMsg from "../config/errMsg";
import useExpireDate from "../common/hooks/useExpireDate";

const PackageUserContainer = styled.div``;

const UserProfileWrap = styled.div`
  background-image: url(${BarCloud});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom !important;
  padding: 60px;
  padding-bottom: 140px;
  padding-top: 20px;

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const H4 = styled.span`
  font-size: 34px;
  font-family: ${props => props.theme.primaryFont};
  font-weight: 700;
  color: ${props => props.theme.primaryMain};
`;

const CardProfileCustom = styled(CardProfile)`
  margin-top: 24px !important;
  margin-bottom: 50px;
`;

const StyledProfileContainer = styled.div`
  margin-bottom: 50px;
  margin-top: 20px;
`;

const NumberDialy = styled.div`
  background-color: white;
  background-image: url(${BGManyStar});
  background-size: cover;
  background: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 60px 16px;
  border: 0;
  position: relative;
  top: -3px;
`;

const HoroMonthly = styled.div`
  background-color: white;
  background-image: url(${BGManyStar});
  background-size: cover;
  background: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 60px;
  border: 0;
  position: relative;
  top: -5px;
  @media (max-width: 430px) {
    padding: 8px;
  }
`;

const BlessingMonthly = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 60px;
  border: 0;
  position: relative;
  top: -2px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const TrickBlessing = styled.div`
  background-image: url(${BGSkyAll});
  background-size: 100% 100%;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border: 0;
  position: relative;
  top: 0px;
  padding-top: 100px;
  padding-bottom: 50px;
  height: 100%;
  background-size: cover;
`;

const TrickBlessingWrap = styled.div`
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  @media (max-width: 430px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const BlessingMonthlyContainer = styled.div`
  background-image: linear-gradient(
    to bottom,
    transparent,
    rgba(18, 58, 88, 0.18)
  );
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  top: -6px;
`;

const StyleHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const H4Custom = styled(H4)`
  text-align: center;
`;

const H5Custom = styled(H5)`
  text-align: center;
  color: ${props => props?.theme?.primaryLight300};
`;

const StyBtnBack = styled.div`
  padding: 8px 16px;
`;

const StyleSeparateLine = styled(SeparateLine)`
  width: 80vw;

  .icon-star-small {
    font-size: 14px;
  }
`;

const useHookPackageUser = () => {
  const [isOpenModalWallpaperDownload, setIsOpenModalWallpaperDownload] =
    useState(false);
  const [tabArticle, setTabArticle] = useState("work");
  const [enhanceContent, setEnhanceContent] = useState(null);
  const [imgEnhance, setImgEnhance] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [contentPreview, setContentPreview] = useState(null);
  const [fullContent, setfullContent] = useState(null);
  const [isLoadmoreEnhance, setIsLoadmoreEnhance] = useState(false);
  const [accessPackage, setAccessPackage] = useState([]);
  const [videoContent, setVideoContent] = useState(null);
  const packageState = useSelector(state => state.package);
  const { packageCurrent } = Object(packageState);
  const { mediaKey } = Object(packageCurrent);
  const userProfileState = useSelector(state => state.userProfile);
  const { firstName, lastName } = Object(userProfileState?.userProfile);
  const { isOpenModalOverLimitPackage = false, msgErrModal = "" } = useSelector(
    state => state.modal,
  );
  const { zodiacInfo } = Object(userProfileState);
  const { getVideo } = VideoProvider();
  const { getEnhance } = enhanceProvider();
  const { getPackageAll } = PackageProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.state?.scrollTo) {
      navigate(location.pathname, {
        replace: true,
      });
      const element = document.getElementById(location?.state?.scrollTo);
      element?.scrollIntoView();
    }
    return () => {};
  }, [location?.state]);

  const handleCloseModalOverLimitPackage = async () => {
    await Promise.all([
      dispatch(
        setModalField({
          key: "isOpenModalOverLimitPackage",
          value: false,
        }),
      ),
      navigate(
        msgErrModal === "Member not subscribe package" ? "/main" : "/package",
      ),
    ]);
  };

  const handleError = error => {
    const { data } = error || {};
    if (
      data &&
      data?.code === 403 &&
      data?.message === "Member not subscribe package"
    ) {
      dispatch(
        setModalAndMsgField({
          key: "isOpenModalOverLimitPackage",
          value: true,
          message: data?.message,
        }),
      );
    }
  };

  const getEnhanceContent = async key => {
    try {
      const res = await getEnhance(key);
      setEnhanceContent(replaceNewlineWithBr(res[0]?.article[0]?.content[0]));
      setImgEnhance(res[0]?.coverPath);
    } catch (error) {
      console.log("error", error);
      handleError(error);
    }
  };

  const setLoadmore = key => {
    if (fullContent?.trim().length) {
      if (useExpireDate(packageCurrent?.expireDate)) {
        dispatch(
          setModalAndMsgField({
            key: "isOpenModalOverLimitPackage",
            value: true,
            message: "Your package has expired",
          }),
        );
      } else if (packageCurrent?.config?.tipsNoonduang?.access === "full") {
        setIsLoadmoreEnhance(key);
      } else {
        setModalConfirm(true);
      }
    }
  };

  const getAccessPackage = async () => {
    try {
      const res = await getPackageAll();
      const richPack = res.filter(pac => pac.mediaKey === "enhance");
      setAccessPackage(richPack);
      dispatch(
        setPackageField({
          key: "accessPackage",
          value: richPack,
        }),
      );
    } catch (error) {
      handleError(error);
    }
  };

  const handleGetVideo = async key => {
    try {
      const res = await getVideo(key);
      setVideoContent(res[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (mediaKey) {
      handleGetVideo(mediaKey);
    }
  }, [mediaKey]);

  useEffect(() => {
    getAccessPackage();
    return () => {};
  }, []);

  useEffect(() => {
    if (packageCurrent?.mediaKey) {
      getEnhanceContent(packageCurrent?.mediaKey);
    }
  }, [packageCurrent]);

  useEffect(() => {
    if (enhanceContent) {
      setContentPreview(`${cutStringFirstParagraph(enhanceContent)}`);
      setfullContent(enhanceContent);
    }
  }, [enhanceContent]);

  return {
    isOpenModalWallpaperDownload,
    setIsOpenModalWallpaperDownload,
    setTabArticle,
    tabArticle,
    zodiacInfo,
    navigate,
    isLoadmoreEnhance,
    fullContent,
    contentPreview,
    setLoadmore,
    modalConfirm,
    setModalConfirm,
    accessPackage,
    enhanceContent,
    imgEnhance,
    mediaKey,
    videoContent,
    firstName,
    lastName,
    handleError,
    isOpenModalOverLimitPackage,
    msgErrModal,
    handleCloseModalOverLimitPackage,
    packageCurrent,
  };
};

const PackageUser = () => {
  const {
    isOpenModalWallpaperDownload,
    setIsOpenModalWallpaperDownload,
    zodiacInfo,
    isLoadmoreEnhance,
    setIsLoadmoreEnhance,
    fullContent,
    contentPreview,
    setLoadmore,
    imgEnhance,
    mediaKey,
    videoContent,
    firstName,
    lastName,
    isOpenModalOverLimitPackage,
    msgErrModal,
    handleError,
    handleCloseModalOverLimitPackage,
    packageCurrent,
  } = useHookPackageUser();

  return (
    <Layout>
      <PackageUserContainer>
        <StyBtnBack>
          <ButtonBack label=" < ย้อนกลับ" color="white" to="/package" />
        </StyBtnBack>
        <UserProfileWrap>
          {/* <BarCloudCustom /> */}
          <StyleHeader>
            <H5Custom color="white">แพ็กเกจวอลเปเปอร์</H5Custom>
            <H4Custom>{packageCurrent?.title}</H4Custom>
          </StyleHeader>
          <IconStarMoon />
          <TitleText label="การ์ดเจ้าชะตา" theme="white" size="large" />
          <StyledProfileContainer>
            <CardProfileCustom />
          </StyledProfileContainer>
        </UserProfileWrap>

        {/* wallpaper downloaded */}
        <div id="wallpaper">
          <WallpaperDownloaded
            setIsOpenModalWallpaperDownload={value =>
              setIsOpenModalWallpaperDownload(value)
            }
          />
        </div>

        <NumberDialy>
          <TitleText
            label="เลข 3 หลักมงคลประจำวัน"
            theme="black"
            size="large"
          />

          <FeatureLuckyNumber handleError={handleError} />
          {/* lotto */}
          <Lotto />
          <StyleSeparateLine marginTop="40" icon="icon-star-small" />
        </NumberDialy>
        <HoroMonthly>
          <TitleText label="ดวงรายเดือน" theme="black" size="large" />
          <div id="post">
            <ImageCard
              image={`${host?.img}${zodiacInfo?.imagePath}`}
              width="64px"
              height="86px"
              label={`ราศี${zodiacInfo?.name}`}
              fontColor="black"
            />
            <Caption>
              (ข้อมูลอัปเดทวันที่{" "}
              {displayDateThShortYear(moment().startOf("month"), "DD MMM YYYY")}
              )
            </Caption>
          </div>
          <CardMonthly />
          <StyleSeparateLine marginTop="40" icon="icon-star-small" />
        </HoroMonthly>
        <BlessingMonthlyContainer>
          <BlessingMonthly>
            <TitleText
              label="ฝากดวง หนุนชะตา ประจำเดือน"
              theme="black"
              size="large"
            />
            <VideoWidget
              videoContent={videoContent}
              mediaKey={mediaKey}
              firstName={firstName}
              lastName={lastName}
            />
          </BlessingMonthly>

          <TrickBlessing>
            <TrickBlessingWrap>
              <TitleText label="เคล็ดลับเสริมดวง" theme="black" size="large" />
              <LockComponent feature="tipsNoonduang" top="30%">
                <ImageDetail
                  img={imgEnhance}
                  // content={isLoadmoreEnhance ? fullContent ? contentPreview}
                  isMoreDetail={isLoadmoreEnhance}
                  handleMoreDetail={() => setLoadmore(!isLoadmoreEnhance)}
                  isLoadmore={isLoadmoreEnhance}
                  setIsLoadmore={setIsLoadmoreEnhance}
                  fullContent={fullContent}
                  contentPreview={contentPreview}
                />
              </LockComponent>
            </TrickBlessingWrap>
          </TrickBlessing>
        </BlessingMonthlyContainer>
      </PackageUserContainer>
      <ModalWallpaperDownload
        isShowModal={isOpenModalWallpaperDownload}
        toggle={() => setIsOpenModalWallpaperDownload(false)}
      />

      <ModalOverLimitPackage
        label={errMsg[msgErrModal]}
        isShowModal={isOpenModalOverLimitPackage}
        handleClick={() => handleCloseModalOverLimitPackage()}
      />
    </Layout>
  );
};

export default PackageUser;
