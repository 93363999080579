import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { useProviderAuth } from "../../common/hooks/useProviderAuth";
import { setModalField } from "../modal/modalSlice";
import { Body1 } from "../../common/components/Font";
import ModalTemplate from "../../common/components/ModalTemplate";
import * as animationIcon from "../../asset/animations/women.json";
import { logout } from "../formLogin/formLoginSlice";

const StyleContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const ModalLogout = () => {
  const dispatch = useDispatch();
  const { isOpenModalLogout } = useSelector(state => state.modal);

  const { signout = null } = useProviderAuth();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationIcon,
  };

  const handleCloseModal = () => {
    dispatch(
      setModalField({
        key: "isOpenModalLogout",
        value: false,
      }),
    );
  };

  const handleLogout = () => {
    signout();
    handleCloseModal();
  };

  return (
    <ModalTemplate
      height="375px"
      width="500px"
      isShowModal={isOpenModalLogout}
      isShowIconClose
      isShowBg={false}
      btnLeft="ยกเลิก"
      btnRight="ยืนยัน"
      toggle={() => handleCloseModal()}
      callbackBtnLeft={() => {
        handleCloseModal();
      }}
      callbackBtnRight={() => {
        handleLogout();
        dispatch(logout());
      }}
    >
      <StyleContentModal>
        <Lottie options={defaultOptions} height={150} width={150} />
        <Body1>คุณแน่ใจหรือไม่ที่จะออกจากระบบ</Body1>
      </StyleContentModal>
    </ModalTemplate>
  );
};

export default ModalLogout;
