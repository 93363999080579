import { createContext, useContext, useState } from "react";

const ToastContext = createContext();

const useProviderToast = () => {
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState("");
  const [duration, setDuration] = useState(3000);
  const [icon, setIcon] = useState("info");

  const showToast = (_label, _icon) => {
    setLabel(_label);
    setIcon(_icon);
    setShow(true);
  };

  const hideToast = () => {
    setShow(false);
  };

  return {
    showToast,
    hideToast,
    show,
    label,
    icon,
    duration,
    setDuration,
  };
};

export const useToast = () => {
  return useContext(ToastContext);
};

// ToastProvider component to provide the context
const ToastProvider = ({ children }) => {
  const toast = useProviderToast();
  return (
    <ToastContext.Provider value={toast}>{children}</ToastContext.Provider>
  );
};

export default ToastProvider;
