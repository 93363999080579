import React from "react";
import { styled } from "styled-components";
import { useSelector } from "react-redux";
import TitleText from "../../common/components/TitleText";
import ModalTemplate from "../../common/components/ModalTemplate";
import { Body1, Subtitle1 } from "../../common/components/Font";
import ImageBorder from "../../common/components/ImageBorder";
import BgSelectWallpaper from "../../asset/images/bg-select-wallpaper.svg";

const StyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  img.card-image {
    width: 255px;
    height: 330px;
    margin-bottom: 20px;
  }
`;

const StyleContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 0px 40px 40px 40px;
`;

const StyleBgImage = styled.div`
  background-image: url(${BgSelectWallpaper});
  background-size: cover;
  padding: 13px 54px;
  border-radius: 10px;
`;

const StyleTextContent = styled.div`
  background: rgba(255, 255, 255, 0.9);
`;

const ModalWallpaperDetail = props => {
  const {
    toggle = null,
    isShowModal = false,
    width = "80%",
    height = "100%",
  } = props || {};

  const { indexWallpaperDetail = -1, wallpaperDownloadList = [] } = useSelector(
    state => state.wallpaperDownload,
  );

  const { packageCurrent = {} } = useSelector(state => state.package);

  if (!isShowModal) {
    return null;
  }

  const { content = {}, categoryName = "" } =
    (wallpaperDownloadList && wallpaperDownloadList[indexWallpaperDetail]) ||
    {};

  const { previewImage = "", title = "", description = "" } = content || {};

  return (
    <ModalTemplate
      width={width}
      height={height}
      isShowModal={isShowModal}
      header={title}
      toggle={() => toggle()}
    >
      <StyContainer>
        <TitleText
          label={`${packageCurrent?.title} ${categoryName}`}
          theme="black"
          size="medium"
        />
        <StyleBgImage>
          <ImageBorder image={`https://cms.noonduang.com${previewImage}`} />
        </StyleBgImage>
        <StyleContent>
          <Subtitle1>รายละเอียดคำอธิบาย</Subtitle1>
          <StyleTextContent>
            <Body1>{description}</Body1>
          </StyleTextContent>
        </StyleContent>
      </StyContainer>
    </ModalTemplate>
  );
};

ModalWallpaperDetail.defaultProps = {};

export default ModalWallpaperDetail;
