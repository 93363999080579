/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LockComponent from "../../common/components/LockComponent";
import Image from "../../common/components/Image";
import Button from "../../common/components/Button";
import useLuckyNumber from "./useLuckyNumber";
import LuckyNumberProvider from "./luckyNumberProvider";
import { setLuckyNumberField } from "./luckyNumberSlice";
import useTomorrowDate from "../../common/hooks/useTomorrowDate";
import useRandomLuckyNumber from "../../common/hooks/useRandomLuckyNumber";
import { useToast } from "../../common/hooks/useProviderToast";
import useExpireDate from "../../common/hooks/useExpireDate";
import { setModalAndMsgField } from "../modal/modalSlice";
// import ImageLock from "../../asset/images/img-lock.svg";

/** @type {*} 
 *  <LuckyNumber
        handleLuckyNumberRandom={()=>null}
        option={
          optionBgLuckyNumber:[
            {
              id: "IMG_CUP_RANDOM_NUMBER",
              image: IMG_CUP_RANDOM_NUMBER,
            },
            {
              id: "IMG_TABLE_RANDOM_NUMBER",
              image: IMG_TABLE_RANDOM_NUMBER,
            },
          ],
          optionLuckyNumber = [
            {
              id: "BUBBLE_0",
              value: 0,
              image: BUBBLE_0,
            }
        }
        isLuckyNumberActive="false"
        width="auto"
        height="auto"
        marginTop={0}
        marginBottom={0}
        marginLeft={0}
        marginRight={0}
    />
 * 
*/

const LuckyNumberStyle = styled.div`
  margin: ${props => props.marginTop || 0}px
    ${props => props.marginRight || 0}px ${props => props.marginBottom || 0}px
    ${props => props.marginLeft || 0}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  gap: 16px;

  .box-content-top {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    min-height: 260px;
    gap: 10%;

    @media screen and (max-width: 768px) {
      /* Screen Tablet */
      min-height: 250px;
      gap: 6%;
    }

    @media screen and (max-width: 430px) {
      /* Screen Mobile */
      min-height: 180px;
      gap: 6%;
    }

    img {
      @media screen and (max-width: 768px) {
        /* Screen Tablet */
        width: calc(100% / 3.5);
      }

      @media screen and (max-width: 430px) {
        /* Screen Mobile */
        width: calc(100% / 4);
      }

      width: calc(100% / 3);
      min-height: min-content;
      max-height: 250px;

      /* animation bubble */
      animation-name: bubble;
      animation-duration: 3.5s;
      /* animation-delay: 1s; */
      animation-timeline: auto;
      animation-composition: accumulate;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-play-state: ${props =>
        props.isLuckyNumberActive ? "running" : "paused"};
      visibility: hidden;
      transform: translateY(10px);

      &:nth-child(1) {
        /* animation-delay: ${props =>
          props.$showBubbleDefault ? "-1.5s" : "1.5s"}; */
        animation-delay: 1.5s;
      }
      &:nth-child(2) {
        /* animation-delay: ${props =>
          props.$showBubbleDefault ? "-2.5s" : "2.5s"}; */
        animation-delay: 2.5s;
      }
      &:nth-child(3) {
        /* animation-delay: ${props =>
          props.$showBubbleDefault ? "-3.5s" : "3.5s"}; */
        animation-delay: 3.5s;
      }

      @keyframes bubble {
        0% {
          visibility: hidden;
          transform: translateY(-5px);
        }
        1%,
        100% {
          visibility: visible;
          transform: translateY(-5px);
        }
        50% {
          visibility: visible;
          transform: translateY(-80px);
        }
      }
      /* animation bubble */
    }
  }

  .box-content-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-height: 450px;

    #IMG_TABLE_RANDOM_NUMBER {
      width: fit-content;
      height: fit-content;
    }

    #IMG_CUP_RANDOM_NUMBER {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: max-content;
      margin: -25px 0;
      bottom: 0%;
      top: 45%;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%) rotate(0deg);

      .icon-lock {
        position: absolute;
        top: 35%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        -webkit-animation-play-state: paused;
        -moz-animation-play-state: paused;
        -o-animation-play-state: paused;
        animation-play-state: paused;
      }

      .box-btn-gen-lucky-number {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;
        button {
          font-size: clamp(0.75rem, 0.4375rem + 1vw, 1rem);
        }
        @media screen and (max-width: 768px) {
          /* Screen Tablet */
          transform: translate(-50%, -50%) scale(0.8);
        }
        @media screen and (max-width: 430px) {
          /* Screen Mobile */
          transform: translate(-50%, -50%) scale(0.8);
        }
      }
      /* animation headShake */
      /* animation-name: headShake; */
      animation-name: ${props => {
        return props.isLuckyNumberActive || props.$accessLock
          ? "none"
          : "headShake";
      }};
      animation-duration: 1.8s;
      animation-delay: 0.4s;
      animation-timeline: auto;
      animation-composition: accumulate;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      transform-origin: 50% 50%;
      -webkit-animation-play-state: ${props =>
        props.isLuckyNumberActive ? "paused" : "running"};
      -moz-animation-play-state: ${props =>
        props.isLuckyNumberActive ? "paused" : "running"};
      -o-animation-play-state: ${props =>
        props.isLuckyNumberActive ? "paused" : "running"};
      animation-play-state: ${props =>
        props.isLuckyNumberActive ? "paused" : "running"};

      @keyframes headShake {
        0% {
          transform: translate(0%, 0%) rotate(0deg);
        }
        10% {
          transform: translate(0%, 0%) rotate(5deg);
        }
        20% {
          transform: translate(0%, 0%) rotate(-5deg);
        }
        30% {
          transform: translate(0%, 0%) rotate(5deg);
        }
        40% {
          transform: translate(0%, 0%) rotate(-5deg);
        }
        50% {
          transform: translate(0%, 0%) rotate(5deg);
        }
        60% {
          transform: translate(0%, 0%) rotate(-5deg);
        }
        100% {
          transform: translate(0%, 0%) rotate(0deg);
        }
      }
      /* animation headShake */
    }
  }
`;

const LuckyNumber = props => {
  const {
    option,
    luckyNumber: luckyNumberUse,
    setLuckyNumber,
    initialStateLuckyNumber,
    isLuckyNumberActive,
    setIsLuckyNumberActive,
    // handleLuckyNumberRandom,
  } = useLuckyNumber();
  const {
    arr: value = [],
    srt = "",
    isLuckyOld = false,
  } = luckyNumberUse || initialStateLuckyNumber;

  const {
    // handleLuckyNumberRandom = null,
    // isLuckyNumberActive = false,
    // value = [0, 0, 0],
    // option = {},
    width = "auto",
    height = "auto",
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    color = "textTertiary",
    colorActive = "primaryMain",
    backgroundColor = "white",
    backgroundColorActive = "secondaryMain",
    handleError,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { showToast } = useToast();
  const [isShowBubbleDefault, setIsShowBubbleDefault] = useState(false);
  const { luckyNumber = {} } = useSelector(state => state.luckyNumber);
  const { packageCurrentList = [], packageCurrent = null } = useSelector(
    state => state.package,
  );
  const { config, expireDate } = packageCurrent || {};
  const { luckyNumber: luckyNumberConfig } = config || {};
  const { access = "preview" } = luckyNumberConfig || {};
  const { optionLuckyNumber, optionBgLuckyNumber } = option || {};
  const { getLuckyNumberById, updateLuckyNumberById } = LuckyNumberProvider();
  const accessLock = Boolean(access === "preview" || access === "denied");

  const packageName = pathname.substring(
    pathname.lastIndexOf("/") + 1,
    pathname.length,
  );

  const profileIdRes =
    packageCurrentList.filter(e => {
      return e.mediaKey === packageName;
    })[0]?.profileId || null;

  const profileId = profileIdRes || null;
  const showBubbleDefault =
    Boolean(
      isLuckyOld &&
        luckyNumber &&
        luckyNumber?.luckyNumber?.length > 0 &&
        (isShowBubbleDefault || accessLock),
    ) || false;

  const generateLuckyNumberRandom = () => {
    const lucky = useRandomLuckyNumber();
    setLuckyNumber({
      srt: lucky.join(""),
      arr: lucky,
      isLuckyOld: false,
    });
  };

  const setStateLuckyNumber = (exp, num) => {
    if (useTomorrowDate(exp)) {
      setIsLuckyNumberActive(false);
      setLuckyNumber(initialStateLuckyNumber);
      generateLuckyNumberRandom();
    } else if (num) {
      setIsLuckyNumberActive(true);
      setLuckyNumber({
        srt: String(num),
        arr: Array.from(String(num), Number),
        isLuckyOld: true,
      });
      setIsShowBubbleDefault(true);
    } else {
      generateLuckyNumberRandom();
    }
  };

  const setReduxLuckyNumber = res => {
    dispatch(
      setLuckyNumberField({
        key: "luckyNumber",
        value: res,
      }),
    );
  };

  const fetchLuckyNumber = async () => {
    // if (true) {
    //   setIsLuckyNumberActive(false);
    //   setLuckyNumber(initialStateLuckyNumber);
    //   return null;
    // }

    try {
      const res = (await getLuckyNumberById(profileId)) || null;

      // NOTE - If: API return data
      setReduxLuckyNumber(res);
      setStateLuckyNumber(res.createdDate, res.luckyNumber);
    } catch (error) {
      console.log(`error:`, error);
      handleError(error);
    }
  };

  const updateLuckyNumber = async () => {
    // if (true) {
    //   generateLuckyNumberRandom();
    //   setIsLuckyNumberActive(true);
    //   return null;
    // }

    try {
      if (!!expireDate && useExpireDate(expireDate)) {
        dispatch(
          setModalAndMsgField({
            key: "isOpenModalOverLimitPackage",
            value: true,
            message: "Your package has expired",
          }),
        );
      } else if (
        !isLuckyNumberActive &&
        !accessLock &&
        profileId?.toString().length >= 0 &&
        srt?.toString().length >= 0
      ) {
        const res =
          (await updateLuckyNumberById({
            profileId,
            luckyNumber: srt,
          })) || {};

        setStateLuckyNumber(res.createdDate, res.luckyNumber);
        setReduxLuckyNumber(res);
      } else if (!accessLock) {
        if (profileId?.toString().length >= 0 && srt?.toString().length >= 0) {
          // NOTE - Show toast
          showToast("ท่านมีฤกษ์ขอเลขใหม่อีกครั้งในวันพรุ่งนี้", "info");
        }
      }
    } catch (error) {
      console.log(`error:`, error);
      const { data = {} } = error;
      if (
        data?.code === 422 &&
        data?.message === "Lucky Number over limit package"
      ) {
        // NOTE - Show toast
        showToast("ท่านมีฤกษ์ขอเลขใหม่อีกครั้งในวันพรุ่งนี้", "info");
      }
      if (
        data?.code === 403 &&
        data?.message === "Member not subscribe package"
      ) {
        handleError(error);
      }
    }
  };

  useEffect(() => {
    fetchLuckyNumber();
  }, [profileId]);

  return (
    <LockComponent feature="luckyNumber" top="60%">
      <LuckyNumberStyle
        width={width}
        height={height}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        color={color}
        colorActive={colorActive}
        backgroundColor={backgroundColor}
        backgroundColorActive={backgroundColorActive}
        isLuckyNumberActive={isLuckyNumberActive}
        $showBubbleDefault={showBubbleDefault}
        $accessLock={accessLock}
      >
        <div className="box-content-top">
          {option &&
            value.length > 0 &&
            [...value].map((item, index) => {
              return (
                <Image
                  src={optionLuckyNumber[value[index]].image || ""}
                  // src={optionLuckyNumber[value[index]].image}
                  width="100%" // string
                  height="100%" // string
                />
              );
            })}
        </div>
        <div className="box-content-bottom">
          <div id={optionBgLuckyNumber[0].id}>
            {/* {accessLock && (
            <Image
              className="icon-lock"
              src={ImageLock}
              width="clamp(3.375rem, 1.8125rem + 5vw, 4.625rem)" // string
              height="clamp(3.375rem, 1.8125rem + 5vw, 4.625rem)" // string
            />
          )} */}
            <Image
              src={optionBgLuckyNumber[0].image}
              // width="100%" // string
              height="100%" // string
            />
            <div className="box-btn-gen-lucky-number">
              <Button
                type="confirm"
                isBrightness={!accessLock}
                disabled={accessLock}
                borderRadius="999px"
                handleClick={() => {
                  updateLuckyNumber();
                }}
              >
                ขอเลขมงคล
              </Button>
            </div>
          </div>
          <div id={optionBgLuckyNumber[1].id}>
            <Image
              src={optionBgLuckyNumber[1].image}
              width="100%" // string
              height="100%" // string
            />
          </div>
        </div>
      </LuckyNumberStyle>
    </LockComponent>
  );
};

export default LuckyNumber;
