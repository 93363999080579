import { useAxios } from "../../common/hooks/useProviderAxios";
import host from "../../config/host";

const VideoProvider = () => {
  const { axios } = useAxios();
  const getVideo = async packageKey => {
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host.api}/content/private?packageKey=${packageKey}&type=video`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  };
  return {
    getVideo,
  };
};

export default VideoProvider;
