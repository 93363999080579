import React from "react";
import styled from "styled-components";
import FeatureArticle from "../features/article";
import Layout from "../common/layout/Layout";
import Banner from "../asset/images/banner-mainpage.jpeg";

const BannerTop = styled.img`
  width: 100%;
  /* max-width: 1500px; */
  max-width: 970px;
  // height: 172px;
`;

const StyleBanner = styled.div`
  display: flex;
  justify-content: center;
`;

const Article = () => {
  return (
    <Layout>
      <StyleBanner>
        <BannerTop src={Banner} />
      </StyleBanner>

      {/* content */}
      <FeatureArticle />
    </Layout>
  );
};

Article.defaultProps = {
  content: "",
};

export default Article;
