/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const ExpandContainer = styled.div`
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  background-color: ${props => props.theme.white};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  /* span {
    text-align: center;
    flex: 1;
  } */
`;

const TextHeader = styled.span`
  text-align: center;
  flex: 1;
`;

const ContentContainer = styled.div`
  margin-top: 10px;
  padding: 0 32px;
`;

const ExpandableBox = ({
  header = "",
  content = "",
  isOpenDefault = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isOpenDefault);
    return () => {};
  }, [isOpenDefault]);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <ExpandContainer>
      <HeaderContainer onClick={toggle}>
        <TextHeader>{header}</TextHeader>
        <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
      </HeaderContainer>
      <Collapse isOpen={isOpen}>
        <ContentContainer>{content}</ContentContainer>
      </Collapse>
    </ExpandContainer>
  );
};

export default ExpandableBox;
