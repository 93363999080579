/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEffect } from "react";
import BgStar from "../asset/images/bg-star.svg";
import BgMoonStar from "../asset/images/img-sun-star-left.svg";
import BgSunStar from "../asset/images/img-sun-star-rigth.svg";
import ButtonBack from "../common/components/ButtonBack";
import { Body1, SubH6 } from "../common/components/Font";
import TitleText from "../common/components/TitleText";
import Layout from "../common/layout/Layout";
import {
  setModalAndMsgField,
  setModalField,
} from "../features/modal/modalSlice";
import ModalWallpaperDownload from "../features/modalWallpaperDetail/ModalWallpaperDownload";
import ModalConfirmDownload from "../features/wallpaperDownload/ModalConfirmDownload";
import ModalOverLimitPackage from "../features/wallpaperDownload/ModalOverLimitPackage";
import ModalWallpaperDetail from "../features/wallpaperDownload/ModalWallpaperDetail";
import WallpaperDownload from "../features/wallpaperDownload/index";
import { setWallpaperDownloadField } from "../features/wallpaperDownload/wallpaperDownloadSlice";
import useExpireDate from "../common/hooks/useExpireDate";
import errMsg from "../config/errMsg";
import { resetWallpaperLoaded } from "../features/wallpaperLoaded/wallpaperLoadedSlice";

const StyleBg = styled.div`
  background-image: url(${BgMoonStar}), url(${BgSunStar}), url(${BgStar});
  background-repeat: no-repeat;
  background-size: 250px, 250px, cover;
  background-position:
    left -120px top -38px,
    right -70px bottom,
    0 0;
  overflow-y: auto;
  height: 100%;
`;

const StyleContent = styled.div`
  margin: 0 auto;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  max-width: 1046px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const StyleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyleStar = styled.div`
  margin-right: 10px;
  display: flex;
  color: #39437e;
  padding: 0px 16px;
`;

const StyleBenefit = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const BtnBack = styled.div`
  padding: 8px 16px;
`;

const Wallpaper = () => {
  const dispatch = useDispatch();
  const {
    isOpenModalWallpaperDetail,
    isOpenModalConfirmDownload = false,
    isOpenModalDownloadWallpaper = false,
    isOpenModalOverLimitPackage = false,
    msgErrModal = "",
  } = useSelector(state => state.modal);

  const { packageCurrent = {} } = useSelector(state => state.package);
  const { pathname } = useLocation();

  const content = [
    {
      label: "วอลเปเปอร์",
    },
    {
      label: "ฤกษ์มงคลติดตั้งวอลเปเปอร์",
    },
    {
      label: "คำอธิบายวอลเปเปอร์",
    },
  ];

  const navigate = useNavigate();

  const handleModalDetail = index => {
    dispatch(
      setModalField({
        key: "isOpenModalWallpaperDetail",
        value: !isOpenModalWallpaperDetail,
      }),
    );

    // save index selected
    dispatch(
      setWallpaperDownloadField({
        key: "indexWallpaperDetail",
        value: index,
      }),
    );
  };

  const handleModalDownloadWallpaper = () => {
    dispatch(
      setModalField({
        key: "isOpenModalDownloadWallpaper",
        value: !isOpenModalDownloadWallpaper,
      }),
    );
  };

  const handleModalConfirm = index => {
    if (!useExpireDate(packageCurrent?.expireDate)) {
      dispatch(
        setWallpaperDownloadField({
          key: "indexWallpaperPreview",
          value: index,
        }),
      );

      dispatch(
        setModalField({
          key: "isOpenModalConfirmDownload",
          value: !isOpenModalConfirmDownload,
        }),
      );
    } else {
      dispatch(
        setModalAndMsgField({
          key: "isOpenModalOverLimitPackage",
          value: true,
          message: "Your package has expired",
        }),
      );
    }
  };

  const handleCloseModalOverLimitPackage = async () => {
    const url = useExpireDate(packageCurrent?.expireDate)
      ? "/package"
      : `/package/${packageCurrent?.mediaKey}`;

    await Promise.all([
      dispatch(
        setModalField({
          key: "isOpenModalOverLimitPackage",
          value: false,
        }),
      ),
      navigate(url),
    ]);
  };

  useEffect(() => {
    if (useExpireDate(packageCurrent?.expireDate)) {
      dispatch(
        setModalAndMsgField({
          key: "isOpenModalOverLimitPackage",
          value: true,
          message: "Your package has expired",
        }),
      );
    }
  }, [packageCurrent]);

  // reset index of wallpaper download from prfile page
  useEffect(() => {
    dispatch(resetWallpaperLoaded());
  }, []);

  const url = pathname?.split("/")[2];
  if (url !== packageCurrent?.mediaKey) {
    navigate("/error");
  }

  return (
    <Layout>
      <StyleBg>
        <BtnBack>
          <ButtonBack
            label="< ย้อนกลับ"
            color="dark"
            scrollTo="wallpaper"
            to={`/package/${packageCurrent?.mediaKey}`}
          />
        </BtnBack>

        <StyleContent>
          <TitleText label="กรุณาเลือกวอลเปเปอร์" theme="black" size="large" />
          <WallpaperDownload
            handleModal={index => handleModalDetail(index)}
            handleModalDownloadWallpaper={(index, isDownloaded) =>
              handleModalConfirm(index, isDownloaded)
            } // download
          />
          <SubH6>สิ่งที่ท่านจะได้รับ</SubH6>

          <StyleBenefit>
            {[...content].map(item => {
              return (
                <StyleText>
                  <StyleStar className="icon-star" />
                  <Body1>{item.label}</Body1>
                </StyleText>
              );
            })}
          </StyleBenefit>
        </StyleContent>
      </StyleBg>

      <ModalWallpaperDetail
        isShowModal={isOpenModalWallpaperDetail}
        width="60%"
        toggle={() => handleModalDetail()}
      />

      <ModalConfirmDownload
        isShowModal={isOpenModalConfirmDownload}
        handleClickBtnRight={() => {
          handleModalConfirm();
          // handleModalDownloadWallpaper(); // close modal confirm
        }}
        toggle={() => handleModalConfirm()}
      />

      <ModalWallpaperDownload
        isShowModal={isOpenModalDownloadWallpaper}
        toggle={() => handleModalDownloadWallpaper()}
        // handleClickBtnRight={() => handleModalDownloadWallpaper()} // open modal confirm
      />

      {/* modal alert คุณใช้สิทธิ์เลือกวอลเปเปอร์ครบแล้ว */}
      <ModalOverLimitPackage
        label={errMsg[msgErrModal]}
        // label="คุณใช้สิทธิ์เลือกวอลเปเปอร์ครบแล้ว"
        isShowModal={isOpenModalOverLimitPackage}
        handleClick={() => handleCloseModalOverLimitPackage()}
      />
    </Layout>
  );
};

export default Wallpaper;
