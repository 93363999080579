/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { ReactComponent as IconFiveStar } from "../../asset/images/icon-5-star.svg";
import { ReactComponent as ImageDiamond1 } from "../../asset/images/icon-diamond.svg";
import Button from "./Button";
import SeparateLine from "./SeparateLine";
import { Body2, SubH5, SubH6, Subcaption, Subtitle1, Subtitle2 } from "./Font";

const PackageDetailWrap = styled.div`
  position: relative;
  opacity: 1;
  display: flex;
  /* justify-content: start; */
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: ${props =>
    props?.type === "default"
      ? "40px 20px"
      : props?.type === "recommendDetail"
        ? "0px"
        : "40px 20px 20px 20px"};
`;

const DetailWrap = styled.div`
  display: flex;
  /* justify-content: start; */
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const NumberWrap = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 16px 0;
  gap: 8px;
`;

const ImageCard = styled.img`
  // width: 100%;
  // height: 233px;
  margin-top: 24px;
  border-radius: 5px;
  max-width: 100%;
  filter: ${props => (props.isExpired ? "grayscale(1)" : "none")};
  object-fit: cover;
  @media (max-width: 375px) {
    width: auto;
    height: auto;
    max-width: 200px;
  }
  aspect-ratio: 3 / 2;
`;

const NumberText = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

const CardDetailWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-self: flex-start;
  // padding: 16px 0;
  width: 100%;

  ul {
    margin: 0px;
  }

  @media (min-width: 430px) {
    align-self: center;
  }
`;

const ListCard = styled.ul`
  padding: 8px 8px;
`;

const ListCardItem = styled.li`
  margin: 8px 0;
  list-style-type: none;
`;

const StyledIconFiveStar = styled(IconFiveStar)`
  margin-top: 12px;
`;

export const StarIcon = styled.i`
  color: ${props =>
    props.color ? props.color : props.theme.actionBorderStroke};
  font-size: ${props => props.font};
`;

const ButtonComp = styled(Button)`
  height: 44px;
  border: 0;
  background: ${props =>
    props.isExpired ? props.theme.secondaryLight300 : props.theme.primaryMain};
  border-radius: 10px;
  color: ${props => (props.isExpired ? props.theme.white : props.textPrimaryB)};
  margin-top: ${props => (props.type === "default" ? "24px" : "0px")};
`;

const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: ${props => props.minHight || 0};
`;

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  gap: 8px;
  cursor: pointer;
`;

const StyleSeparateLine = styled(SeparateLine)`
  i {
    font-size: 12px !important;
  }

  gap: 22px !important;
`;

const PackageDetail = props => {
  const { data, type, callbackBtn, callbackOpenModal } = props;
  const {
    title,
    name,
    imageUrl,
    number,
    per,
    detail,
    headerDetail,
    isExpired,
    buttonLabel,
    startDate,
    startTime,
    endDate,
    unSubscribe,
    expireAt,
    moreDetail,
    onClickMoreDetail,
  } = data;

  const cardType = ["default", "recommend", "recommendDetail"];
  return (
    <PackageDetailWrap type={type}>
      <DetailWrap>
        <div>
          <SubH5>{title}</SubH5>
          <SubH6 textAlign="center" color="secondaryLight300">
            {name}
          </SubH6>
        </div>
        <div>
          <StyledIconFiveStar />
          <ImageCard src={imageUrl} alt="image-card" isExpired={isExpired} />
        </div>
      </DetailWrap>

      {/* /////price  */}
      {(type === cardType[1] || type === cardType[2]) && (
        <NumberWrap>
          <StarIcon className="icon-star" font="4px" />
          <StarIcon className="icon-star" font="8px" />
          <NumberText>
            <SubH5 color="primaryMain">{number} บาท</SubH5>
            <Subtitle2 color="textInput"> / {per}</Subtitle2>
          </NumberText>
          <StarIcon className="icon-star" font="8px" />
          <StarIcon className="icon-star" font="4px" />
        </NumberWrap>
      )}
      {/* /////price  */}
      {(type === cardType[1] || type === cardType[2]) && (
        <CardDetailWrap>
          <Subtitle1>{headerDetail}</Subtitle1>
          <ListCard>
            {detail.map(list => (
              <Body2 key={list}>
                <ListCardItem>
                  <ImageDiamond1 /> {list}
                </ListCardItem>
              </Body2>
            ))}
          </ListCard>
        </CardDetailWrap>
      )}
      <div className="w-100">
        {(type === cardType[0] || type === cardType[1]) && (
          <ButtonComp
            width="100%"
            isExpired={isExpired}
            handleClick={() => callbackBtn(name)}
            type={type}
          >
            {buttonLabel}
          </ButtonComp>
        )}

        {type === cardType[2] && (
          <ContainerColumn>
            <Subcaption color="primaryMain">
              เริ่มใช้งาน วันที่ {startDate} {!!startTime && `${startTime} น.`}
            </Subcaption>
            <Subcaption color="primaryMain">ต่ออายุวันที่ {endDate}</Subcaption>
          </ContainerColumn>
        )}

        {type === cardType[2] && (
          <ContainerColumn>
            <div
              style={{
                width: "100%",
              }}
            >
              <StyleSeparateLine
                width="80%"
                colorIcon="linear-gradient(to right, #EEDEBC,#DDC5A3, #C9A885, #9A7A5F, #C9A885);"
                colorLine="linear-gradient(to right, #EEDEBC,#DDC5A3, #C9A885, #9A7A5F, #C9A885);"
              />
            </div>
          </ContainerColumn>
        )}

        {type === cardType[2] && (
          <ContainerColumn>
            <Subcaption color="secondaryLight300">{unSubscribe}</Subcaption>
          </ContainerColumn>
        )}

        {(type === cardType[0] || type === cardType[1]) && (
          <ContainerColumn minHight="20px">
            <Subcaption
              textAlign="center"
              color={isExpired && "statusTagErrorDark"}
            >
              {expireAt}
            </Subcaption>
          </ContainerColumn>
        )}

        {type === cardType[0] && (
          <ContainerRow onClick={callbackOpenModal}>
            <StarIcon className="icon-star" font="8px" color="secondaryMain" />
            <Body2 onClick={onClickMoreDetail}>{moreDetail}</Body2>
            <StarIcon className="icon-star" font="8px" color="secondaryMain" />
          </ContainerRow>
        )}
      </div>
    </PackageDetailWrap>
  );
};

PackageDetail.defaultProps = {
  type: "default",
  data: {
    title: "แพ็กเกจ",
    name: "วอลเปเปอร์หนุนชัยชนะ",
    imageUrl:
      "https://www.educatepark.com/wp-content/uploads/2015/09/ganesha-2.jpg",
    number: null,
    per: null,
    isExpired: false,
    moreDetail: "เพิ่มเติม",
    onClickMoreDetail: () => {},
    data: null,
    expireAt: "ใช้งานได้ถึงวันที่ 30 พ.ย. 66",
    buttonLabel: "ทดลองใช้ฟรี",
    startDate: "1 พย 2566",
    startTime: null, // "00:00"
    endDate: "30 พ.ย. 2566",
    unSubscribe: "วิธียกเลิกบริการ กด *137 แล้วโทรออก",
    headerDetail: "รายการที่ได้รับ",
    detail: [
      "Wallpaper (ดาวน์โหลดได้ 2 ครั้ง)",
      "ฤกษ์มงคลปรับ Wallpaper",
      "สวดหนุนดวงประจำวันเกิด",
    ],
  },
  callbackBtn: () => {},
  callbackOpenModal: () => {},
};

export default PackageDetail;
