/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */

const useDisplayDate = (dataDate, fDay, fTime) => {
  const date = new Date(dataDate) || new Date();
  const formatDate = fDay || "DDMMYYYY"; // "DDMMYY" | "DDMMYYYY" | "DDMMMMYY" | "DDMMMMYYYY"
  const formatTime = fTime || "short"; // "full" | "long" | "medium" | "short" | undefined;

  const time = date.toLocaleTimeString("th-TH", {
    timeStyle: formatTime, // "full" | "long" | "medium" | "short" | undefined;
  });

  const checkFormat = format => {
    switch (format) {
      case "DDMMYY":
        return {
          year: "2-digit", // "numeric" | "2-digit" | undefined
          month: "short", // "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
          day: "numeric", // "numeric" | "2-digit" | undefined
          // weekday: // "long" | "short" | "narrow" | undefined
        };
        break;
      case "DDMMYYYY":
        return {
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        break;
      case "DDMMMMYY":
        return {
          year: "2-digit",
          month: "long",
          day: "numeric",
        };
        break;
      case "DDMMMMYYYY":
        return {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        break;
      default:
        break;
    }
  };
  const format = checkFormat(formatDate);
  const day = date.toLocaleDateString("th-TH", format);

  return {
    day,
    time,
  };
};

export default useDisplayDate;
