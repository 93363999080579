import React from "react";
import styled from "styled-components";
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from "react-share";
import X from "../../asset/images/x-icon.svg";
import Facebook from "../../asset/images/facebook-icon.svg";
import Line from "../../asset/images/line-icon.svg";
import { ReactComponent as CopyToClipboard } from "../../asset/images/copy-to-clipboard.svg";
import { Body2 } from "./Font";
import Button from "./Button";
// import useCopyTextToClipboard from "../hooks/useCopyTextToClipboard";

const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  & button {
    max-width: 180px;
    border-radius: 20px;
  }

  & svg {
    cursor: pointer;

    &:hover {
      filter: brightness(1.1);
    }
  }
`;

const StyleCopyToClipboard = styled(CopyToClipboard)`
  width: clamp(1.125rem, 0.8125rem + 1vw, 1.375rem);
  height: auto;
  path {
    fill: black;
  }
`;

const StyleButton = styled(Button)`
  padding: 0px 20px !important;
`;

const Image = styled.img`
  cursor: pointer;
  width: clamp(1.375rem, 0.75rem + 2vw, 1.875rem);
  height: auto;
`;

const Share = props => {
  const { title = "" } = props;
  const currentUrl = window?.location?.href;
  const segments = currentUrl?.split("/");
  segments?.pop();
  const modifiedUrl = segments?.join("/");

  const url = `${modifiedUrl}/${encodeURIComponent(title)}`;

  function copyText() {
    const tempInput = document.createElement("input");
    tempInput.value = url;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }

  return (
    <ShareContainer>
      <LineShareButton url={url} title={title}>
        <Image src={Line} alt="line" />
      </LineShareButton>

      <FacebookShareButton url={url}>
        <Image src={Facebook} alt="fb" />
      </FacebookShareButton>

      <TwitterShareButton url={url}>
        <Image src={X} alt="x" />
      </TwitterShareButton>

      <StyleButton
        handleClick={() => copyText(url)}
        title={title}
        id="btnCopyText"
      >
        <Body2 color="black">คัดลอกลิงก์</Body2> <StyleCopyToClipboard />
      </StyleButton>
    </ShareContainer>
  );
};

export default Share;
