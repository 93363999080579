/* eslint-disable no-param-reassign */
import { combineReducers } from "redux";
import articleReducer from "../features/article/articleSlice";
import articleDetailReducer from "../features/articleDetail/articleDetailSlice";
import articleMonthlyReducer from "../features/articleMonthly/articleMonthlySlice";
import formLoginReducer from "../features/formLogin/formLoginSlice";
import formProfileReducer from "../features/formProfile/formProfileSlice";
import loadingReducer from "../features/loading/loadingSlice";
import lottoReducer from "../features/lotto/lottoSlice";
import luckyNumberReducer from "../features/luckyNumber/luckyNumberSlice";
import modalReducer from "../features/modal/modalSlice";
import packageReducer from "../features/package/packageSlice";
import userReducer from "../features/userProfile/userProfileSlice";
import wallpaperDownloadReducer from "../features/wallpaperDownload/wallpaperDownloadSlice";
import wallpaperLoadedReducer from "../features/wallpaperLoaded/wallpaperLoadedSlice";

const combinedReducer = combineReducers({
  modal: modalReducer,
  loading: loadingReducer,
  package: packageReducer,
  userProfile: userReducer,
  wallpaperLoaded: wallpaperLoadedReducer,
  lotto: lottoReducer,
  articleDetail: articleDetailReducer,
  article: articleReducer,
  articleMonthly: articleMonthlyReducer,
  wallpaperDownload: wallpaperDownloadReducer,
  formProfile: formProfileReducer,
  formLogin: formLoginReducer,
  luckyNumber: luckyNumberReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "formLogin/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const reducer = rootReducer;

export default reducer;
