/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as Logo } from "../../asset/images/logo-login.svg";
import CardPackage from "../../common/components/CardPackage";
import { Body2 } from "../../common/components/Font";
import PackageDetail from "../../common/components/PackageDetail";
import TabBarSlide from "../../common/components/TabBarSlide";
import useTabBarSlide from "../../common/components/TabBarSlide/useTabBarSlide";
import TitleText from "../../common/components/TitleText";
import usePackageConfig from "../../common/hooks/usePackageConfig";
import { setModalField } from "../modal/modalSlice";
import { setPackageField } from "./packageSlice";
import useDisplayDate from "../../common/hooks/useDisplayDate";
import host from "../../config/host";
import useExpireDate from "../../common/hooks/useExpireDate";
import { useFetchMyPackage } from "./useFetchMyPackage";

const StyleHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

const StyleContent = styled.div`
  display: flex;
  gap: 40px;
  padding: 32px;
  width: 100%;
  max-width: 1200px;
  justify-content: ${props => (props?.list > 3 ? "flex-start" : "center")};
  flex-wrap: wrap;
`;

const StyleEmptyPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  height: 50vh;

  svg {
    width: 160px;
  }
`;

const Content = styled.div`
  cursor: pointer;
  width: calc((100% - ${40 * (3 - 1)}px) / ${3});

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (min-width: 601px) and (max-width: 900px) {
    width: calc((100% - ${40 * (2 - 1)}px) / ${2});
  }
`;

const StyleLinkLabel = styled.span.attrs(props => props)`
  color: ${props => props.theme?.primaryMain};
  text-decoration: underline;
  font-weight: 700;
  margin-right: 5px;
  cursor: pointer;
`;

const StyleLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PackageContent = () => {
  const {
    option: optionTabBarSlide,
    isTabBarSlideActive,
    callbackTabBarSlideOnChange,
    setIsTabBarSlideActive,
  } = useTabBarSlide();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const dispatch = useDispatch();
  const {
    fetchMyPackages,
    fetchPackagesRecommend,
    fetchPackagesAll,
    // myPackageCurrentList,
  } = useFetchMyPackage();
  const { isOpenModalPackage = false } = useSelector(state => state.modal);

  let type = null;
  if (isTabBarSlideActive === "tab1") {
    type = "default";
  } else {
    type = "recommend";
  }

  const {
    packageCurrentList = [],
    packageRecommendList = [],
    // eslint-disable-next-line no-unused-vars
    packageSelected = -1,
  } = useSelector(state => state.package);

  const [isFirstRender, setIsFirstRender] = useState(false);

  const checkMyPackageEmpty = async () => {
    try {
      const myPackages = await fetchMyPackages();
      if (myPackages && myPackages.length > 0) {
        setIsTabBarSlideActive(`tab1`);
      } else {
        setIsTabBarSlideActive(`tab2`);
      }
      return true;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    if (tab) {
      setIsTabBarSlideActive(`tab${tab}`);
    } else {
      checkMyPackageEmpty();
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      fetchPackagesAll({
        isSetCurrent: false,
      });
      setIsFirstRender(true);
    } else {
      switch (type) {
        case "default":
          fetchMyPackages();
          break;
        case "recommend":
          fetchPackagesRecommend();
          break;
        default:
          break;
      }
    }
  }, [type]);

  const packageList =
    type === "default" ? packageCurrentList : packageRecommendList;
  const isEmptyPackage = packageList.length <= 0;
  const enhanceAlready = packageCurrentList.some(
    pac => pac.mediaKey === "enhance",
  );

  const handleModalPackage = (e, index) => {
    dispatch(
      setPackageField({
        key: "packageSelected",
        value: index,
      }),
    );
    dispatch(
      setModalField({
        key: "isOpenModalPackage",
        value: !isOpenModalPackage,
      }),
    );
  };

  const handleClickBtn = (e, item) => {
    dispatch(
      setPackageField({
        key: "packageCurrent",
        value: item,
      }),
    );

    if (isTabBarSlideActive === "tab2") {
      window.location = `/package/${item?.mediaKey}/howto`;
      return;
    }

    // ครั้งต่อไป ==> redirect ไปหน้า package/[name]
    if (useExpireDate(item?.expireDate) || type === "recommend") {
      window.location = `/package/${item?.mediaKey}/howto`;
    } else if (item?.profileId) {
      window.location = `/package/${item?.mediaKey}`;
    } else {
      window.location = `/package/${item?.mediaKey}/profile`;
    }
  };

  return (
    <>
      <div
        style={{
          padding: "40px 16px",
        }}
      >
        <StyleHeader>
          <TitleText label="แพ็กเกจของฉัน" />
          <TabBarSlide
            callbackTabBarSlideOnChange={e => {
              return callbackTabBarSlideOnChange(e);
            }}
            option={optionTabBarSlide}
            isTabBarSlideActive={isTabBarSlideActive}
          />
        </StyleHeader>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {isEmptyPackage ? (
          <StyleEmptyPage>
            <Logo />
            <Body2 color="white">
              {type === "default"
                ? !enhanceAlready && (
                    <Body2 color="white">
                      <StyleLabelContainer>
                        <div>คุณยังไม่ได้สมัครแพ็กเกจ</div>
                        <div>
                          <StyleLinkLabel onClick={() => navigate("/")}>
                            กดที่นี่
                          </StyleLinkLabel>
                          เพื่อดูแพ็กเกจ
                        </div>
                      </StyleLabelContainer>
                    </Body2>
                  )
                : `พบกับแพ็กเกจใหม่เร็วๆนี้`}
            </Body2>
          </StyleEmptyPage>
        ) : (
          <StyleContent list={packageList.length}>
            {packageList &&
              [...packageList].map((item, index) => {
                const isFirstRegister = item?.paymentType === "reg_free";
                const freeTrial = isFirstRegister || type === "recommend";
                const isExpDate = useExpireDate(item?.expireDate);
                const cardOpacity = isExpDate ? 0.9 : 1;
                const { day: dayStart, time: timeStart } = useDisplayDate(
                  item.subscribeDate,
                );
                const { day: dayEnd } = useDisplayDate(item.expireDate);
                const { day: dayEndShort } = useDisplayDate(
                  item.expireDate,
                  "DDMMYY",
                );
                return (
                  <Content onClick={e => handleClickBtn(e, item)}>
                    <CardPackage
                      width="100%"
                      background={`rgba(255, 255, 255, ${cardOpacity})`}
                    >
                      <PackageDetail
                        paymentType={item?.paymentType || null}
                        type={type}
                        data={{
                          title: "แพ็กเกจ",
                          name: `วอลเปเปอร์${item?.title}`,
                          imageUrl: item?.imagePath
                            ? `${host.imageCms}${item?.imagePath}`
                            : "https://p16-va.lemon8cdn.com/tos-alisg-v-a3e477-sg/0137ffa1ff9b4d3c93803d33f97df99b~tplv-tej9nj120t-origin.webp",
                          number: item.price,
                          per: "เดือน",
                          isExpired: isExpDate,
                          moreDetail: "เพิ่มเติม",
                          onClickMoreDetail: e => {
                            e.stopPropagation();
                            handleModalPackage(e, index);
                          },
                          data: null,
                          buttonLabel: isExpDate
                            ? "ต่ออายุ"
                            : freeTrial
                              ? "เข้าสู่แพ็กเกจ"
                              : useExpireDate(item?.expireDate)
                                ? "ต่ออายุ"
                                : freeTrial
                                  ? "เข้าสู่แพ็กเกจ"
                                  : "เข้าสู่แพ็กเกจ",
                          startDate: `${dayStart}`, // "1 พย 2566",
                          startTime: `${timeStart}`, // "11:30",
                          endDate: `${dayEnd}`, // "30 พ.ย. 2566",
                          expireAt:
                            type !== "recommend" && !isExpDate
                              ? ""
                              : type === "recommend"
                                ? "ทดลองฟรี 30 วันสำหรับสมัครบริการครั้งแรก"
                                : `แพ็กเกจหมดอายุวันที่ ${dayEndShort}`, // "ใช้งานได้ถึงวันที่ 30 พ.ย. 66",
                          unSubscribe: "วิธียกเลิกบริการ กด *137# แล้วโทรออก",
                          headerDetail: "รายการที่ได้รับ",
                          detail: [...usePackageConfig(item.config)],
                        }}
                        // callbackBtn={() => handleClickBtn(item)}
                      />
                    </CardPackage>
                  </Content>
                );
              })}
          </StyleContent>
        )}
      </div>
    </>
  );
};
export default PackageContent;
