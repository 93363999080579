import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FormProfileProvider from "../features/formProfile/formProfileProvider";
import { setUserField } from "../features/userProfile/userProfileSlice";
import host from "../config/host";
import { displayDateThShortYear } from "../common/utils/timeUtils";
import { setArticleDetailField } from "../features/articleDetail/articleDetailSlice";
import ArticleMonthlyProvider from "../features/articleMonthly/articleMonthlyProvider";
import ButtonBack from "../common/components/ButtonBack";
import { Caption } from "../common/components/Font";
import ImageCard from "../common/components/ImageCard";
import TitleText from "../common/components/TitleText";
import Layout from "../common/layout/Layout";
import CardPost from "../common/components/CardPost";
import ModalOverLimitPackage from "../features/wallpaperDownload/ModalOverLimitPackage";
import { setModalField } from "../features/modal/modalSlice";
import errMsg from "../config/errMsg";

const PostPageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const StyBtnBack = styled.div`
  padding: 8px 16px;
`;

const useHookPostPage = () => {
  const { search } = useLocation();
  const queryString = search?.split("?")[1];
  const [tabActive, setTabActive] = useState(queryString);

  const dispatch = useDispatch();
  const { isOpenModalOverLimitPackage = false, msgErrModal = "" } = useSelector(
    state => state.modal,
  );
  const { articleDetail = [] } = useSelector(state => state.articleDetail);
  const userProfileState = useSelector(state => state.userProfile);
  const packageState = useSelector(state => state.package);
  const { packageCurrent } = Object(packageState);
  const { zodiacId } = userProfileState.userProfile;
  const { zodiacInfo } = Object(userProfileState);
  const { getZodiacList } = FormProfileProvider();
  const { getDetailArticleMonthly } = ArticleMonthlyProvider();

  const navigate = useNavigate();

  const handleCloseModalOverLimitPackage = async () => {
    await Promise.all([
      dispatch(
        setModalField({
          key: "isOpenModalOverLimitPackage",
          value: false,
        }),
      ),
      navigate("/package"),
    ]);
  };
  const getZodiac = async () => {
    try {
      const res = await getZodiacList();
      dispatch(
        setUserField({
          key: "zodiacInfo",
          value: res.find(zo => zo.id === zodiacId),
        }),
      );
    } catch (error) {
      console.log(`error:`, error);
    }
  };

  useEffect(() => {
    if (zodiacInfo === undefined) {
      getZodiac();
    }
  }, []);

  const getDetailArticle = async () => {
    try {
      const res = await getDetailArticleMonthly({
        packageKey: packageCurrent?.mediaKey,
      });

      if (res) {
        Promise.all([
          dispatch(
            setArticleDetailField({
              key: "articleDetail",
              value: res,
            }),
          ),
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // TODO: call API fetch data
    if (packageCurrent?.mediaKey) {
      getDetailArticle();
    }
  }, [packageCurrent?.mediaKey]);

  useEffect(() => {
    const path = search.split("?")[1];
    setTabActive(path);
  }, [search]);

  return {
    articleDetail,
    tabActive,
    setTabActive,
    zodiacInfo,
    isOpenModalOverLimitPackage,
    msgErrModal,
    handleCloseModalOverLimitPackage,
    packageCurrent,
  };
};

const PostPage = () => {
  const {
    tabActive,
    setTabActive,
    articleDetail = [],
    zodiacInfo,
    isOpenModalOverLimitPackage,
    msgErrModal,
    handleCloseModalOverLimitPackage,
    packageCurrent,
  } = useHookPostPage();

  const {
    article = [],
    // zodiac = null,
    // updateAt = null,
  } = articleDetail[0] || {};
  return (
    <Layout>
      <StyBtnBack>
        <ButtonBack
          label=" < ย้อนกลับ"
          to={`/package/${packageCurrent?.mediaKey}`}
          scrollTo="post"
        />
      </StyBtnBack>

      <PostPageContainer>
        <TitleText label="ดวงรายเดือน" theme="white" size="large" />
        <div className="mt-4">
          <ImageCard
            image={`${host?.img}${zodiacInfo?.imagePath}`} /// image zodiac
            width="56px"
            height="56px"
            label={`ราศี${zodiacInfo?.name}`}
            fontColor="white"
          />
          <Caption color="white">
            (ข้อมูลอัปเดทวันที่{" "}
            {displayDateThShortYear(moment().startOf("month"), "DD MMM YYYY")})
          </Caption>
        </div>
        {article && (
          <CardPost
            tabActive={tabActive}
            callbackClickTab={tab => setTabActive(tab)}
            contentHoro={
              article.find(item => item?.tag === tabActive)?.content[0] || null
            }
          />
        )}
      </PostPageContainer>
      <ModalOverLimitPackage
        label={errMsg[msgErrModal]}
        isShowModal={isOpenModalOverLimitPackage}
        handleClick={() => handleCloseModalOverLimitPackage()}
      />
    </Layout>
  );
};

export default PostPage;
