import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { setModalField } from "../../features/modal/modalSlice";
import { ReactComponent as Logo } from "../../asset/images/logo-noonduang.svg";
import { Body1 } from "./Font";
import BgDefault from "../../asset/images/bg-default.jpg";
import { ReactComponent as IconMyPackage } from "../../asset/images/img-my-package.svg";
import { ReactComponent as IconLogin } from "../../asset/images/img-login.svg";

const StyleNavigation = styled.div`
  height: 60px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
`;

const StyleGroupMenu = styled.div`
  display: flex;
  gap: 16px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: none;
  }

  ${props =>
    props.isOpenNavBar &&
    `
    @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        padding: 16px 0px;
  `}
`;
const StyleMenuItem = styled.div`
  display: flex;
  gap: 8px;
  text-decoration: none;
`;

const StyleMenuFont = styled(Body1)`
  ${props => props?.isActive && ` color: ${props?.theme?.primaryMain};`}

  &:hover {
    color: ${props => props?.theme?.primaryMain};
  }
`;

const BoxHamburger = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
    cursor: pointer;
  }
`;

const StyleHamburger = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`;

const StyleHamburgerItem = styled.div`
  display: block;
  width: ${props => (props.width ? `${props?.width}px` : "25px")};
  height: 1.5px;
  background: ${props => props?.theme?.white};
  border-radius: 10px;
  margin: 5px 0px;
  transition: all 0.3s ease-in-out;
  transform: ${props => props.isOpenNavBar && props.animation};
  opacity: ${props => (props.isOpenNavBar ? props.opacity : "1")};
`;

const StyleMenuList = styled.div`
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: center;

  @media (max-width: 768px) {
    position: absolute;
    flex-direction: column;
    height: 0px;
    width: 100%;
    margin: auto;
    margin-top: 0px;
    transition: all 0.3s ease-out;
    top: 60px;
    left: 0;
    justify-content: start;
    background-image: url(${BgDefault});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;
    background-attachment: fixed;
  }

  ${props =>
    props.isOpenNavBar &&
    `
    @media screen and (max-width: 768px) {
        height: calc(100vh - 60px);
        background-image: url(${BgDefault});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right top;
        margin-top: 0px;
        padding: 15px 0px;
        background-attachment: fixed;
        overflow: hidden !important;
      }
  `}
`;

const NavigationBar = () => {
  const [isOpenNavBar, setIsOpenNavBar] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLogin = Cookies.get("token");

  useEffect(() => {
    const handleScreen = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleScreen);
    handleScreen();

    if (screenWidth > 768) {
      setIsOpenNavBar(false);
    }

    return () => {
      window.removeEventListener("resize", handleScreen);
    };
  }, [screenWidth]);

  useEffect(() => {
    const root = document.getElementById("root");
    if (isOpenNavBar) {
      root.style.overflowY = "hidden";
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
      root.style.overflowY = "auto";
    }
  }, [isOpenNavBar]);

  const menuList = [
    {
      img: <IconMyPackage />,
      label: "แพ็กเกจของฉัน",
      url: "/package",
    },
    {
      img: <IconLogin />,
      label: isLogin ? "ออกจากระบบ" : "เข้าสู่ระบบ",
      url: "/login",
    },
  ];

  const handleClickNavItem = item => {
    const { url = null, label = "" } = item;
    if (isLogin && label === "ออกจากระบบ") {
      dispatch(
        setModalField({
          key: "isOpenModalLogout",
          value: true,
        }),
      );
    } else {
      setIsOpenNavBar(pre => !pre);
      navigate(url);
    }
  };

  return (
    <>
      {/* Iframe download wallpaper */}
      <div id="download-panel">
        {["download_wall", "download_manual", "download_install"].map(item => {
          return (
            <iframe
              title="about:blank"
              id={item}
              style={{
                display: "none",
              }}
            />
          );
        })}
      </div>
      {/* Iframe download wallpaper */}
      <StyleNavigation>
        <Link to={encodeURI("https://www.noonduang.com/หนุนดวง")}>
          <Logo />
        </Link>

        {/* ======= menu list ======= */}
        <StyleMenuList
          style={{
            display: "flex",
            justifyContent: isOpenNavBar ? "flex-start" : "flex-end",
          }}
          isOpenNavBar={isOpenNavBar}
        >
          <StyleGroupMenu isOpenNavBar={isOpenNavBar}>
            {menuList.map(item => {
              // hide menu my package
              if (isLogin === undefined && item?.label === "แพ็กเกจของฉัน") {
                return null;
              }

              return (
                <StyleMenuItem onClick={() => handleClickNavItem(item)}>
                  {item?.img}
                  <StyleMenuFont
                    label={item?.label}
                    color="white"
                    isActive={location?.pathname === item?.url}
                  >
                    {item?.label}
                  </StyleMenuFont>
                </StyleMenuItem>
              );
            })}
          </StyleGroupMenu>
        </StyleMenuList>

        {/* ======== hamburger ======== */}
        <BoxHamburger>
          <StyleHamburger onClick={() => setIsOpenNavBar(!isOpenNavBar)}>
            <StyleHamburgerItem
              isOpenNavBar={isOpenNavBar}
              animation="translateY(9px) rotate(45deg)"
              opacity={1}
            />
            <StyleHamburgerItem
              width={18}
              isOpenNavBar={isOpenNavBar}
              opacity={0}
            />
            <StyleHamburgerItem
              isOpenNavBar={isOpenNavBar}
              animation="translateY(-4px) rotate(-45deg)"
              opacity={1}
            />
          </StyleHamburger>
        </BoxHamburger>
      </StyleNavigation>
    </>
  );
};

export default NavigationBar;
