import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lottoList: [],
};

export const lottoSlice = createSlice({
  name: "lotto",
  initialState,
  reducers: {
    setLottoField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
  },
});

// reducer
export const lottoState = state => state.lotto;

// this action for dispatch
export const { setLottoField } = lottoSlice.actions;

// this is for configureStore
export default lottoSlice.reducer;
