import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ReactComponent as SunStarIcon } from "../../asset/images/img-sun.svg";
import { ReactComponent as SunStarIconRight } from "../../asset/images/img-moon.svg";

const CustomModal = styled(Modal)`
  &.modal-dialog {
    display: flex;
    align-items: center;
    height: calc(${props => props?.height} - 56px);
    width: ${props => props?.width};
  }

  @media (max-width: 600px) {
    &.modal-dialog {
      margin: 0px !important;
      height: 100%;
      width: 100% !important;
    }
  }

  .modal-content {
    border-radius: 20px;
    // height: 100%;
    max-height: 100%;
    border: none;

    @media (max-width: 600px) {
      border-radius: 0px;
      max-height: 100%;
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 20px 24px !important;
`;

const IconClose = styled.i`
  /* color: ${props => props.theme.actionBorderStroke}; */
  font-size: ${props => props.font};
  cursor: pointer;
`;

const SunStar = styled(SunStarIcon)`
  position: absolute;
`;
const SunStarRight = styled(SunStarIconRight)`
  position: absolute;
  bottom: 20px;
  right: 0;
`;

const StyleModalHeader = styled(ModalHeader)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border: 0px;
  padding: 0px;
`;

const StyleContent = styled.div`
  height: 100%;
  max-height: calc(90vh - 156px);
  overflow-y: auto;
  padding-bottom: 40px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 600px) {
    max-height: calc(${props => props?.heightScreen}px - 100px);
  }
`;

const ModalComponent = props => {
  const {
    className = "",
    width = "100%",
    height = "100%",
    margin = "0px",
    isOpenModal = false,
    handleCloseModal = null,
    children = null,
  } = props;

  const [heightScreen, setHeightScreen] = useState(window.innerHeight);

  useEffect(() => {
    if (window.innerHeight) {
      setHeightScreen(window.innerHeight || 0);
      document.getElementsByClassName("modal-content").height = heightScreen;
    }
  }, []);

  return (
    <div>
      <CustomModal
        isOpen={isOpenModal}
        className={className}
        width={width}
        height={height}
        margin={margin}
        centered
      >
        <>
          <SunStar />
          <SunStarRight />

          <StyleModalHeader>
            <IconClose
              className="icon-close"
              font="40px"
              onClick={() => handleCloseModal()}
            />
          </StyleModalHeader>

          <StyledModalBody>
            <StyleContent heightScreen={heightScreen}>{children}</StyleContent>
          </StyledModalBody>
        </>
      </CustomModal>
    </div>
  );
};

// fluid setup
// width={'100%'} margin={'10px'} height={'100%'}

ModalComponent.defaultProps = {
  header: "",
  width: null,
  height: "100%",
  margin: null,
};

export default ModalComponent;
