import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PackageProvider from "./packageProvider";
import { setPackageField } from "./packageSlice";

export const useFetchMyPackage = () => {
  const dispatch = useDispatch();
  const { getPackages, getPackagesRecommend } = PackageProvider();
  const { site } = useParams();

  const initialStatePackage = {
    myPackageCurrentList: [],
    myPackageCurrent: {},
  };
  const [packageOjb, setPackageOjb] = useState(initialStatePackage);

  const fetchMyPackages = async (isSetCurrent = false) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let findPackageCurrent = null;
        const res = await getPackages();

        if (res) {
          const sortDate = listDate => {
            return listDate.sort((a, b) => {
              return new Date(b?.expireDate) - new Date(a?.expireDate);
            });
          };

          dispatch(
            setPackageField({
              key: "packageCurrentList",
              value: sortDate(res),
            }),
          );
          if (isSetCurrent && site) {
            findPackageCurrent =
              [...res]?.find(e => e.mediaKey === site) || null;

            dispatch(
              setPackageField({
                key: "packageCurrent",
                value: findPackageCurrent,
              }),
            );
          }
          setPackageOjb({
            myPackageCurrentList: res || [],
            myPackageCurrent: findPackageCurrent || {},
          });
        }
        resolve(res);
      } catch (error) {
        console.log(`error:`, error);
        reject(error);
      }
    });
  };

  const fetchPackagesRecommend = async () => {
    try {
      const res = await getPackagesRecommend();
      dispatch(
        setPackageField({
          key: "packageRecommendList",
          value: res,
        }),
      );
    } catch (error) {
      console.log(`error:`, error);
    }
  };

  const fetchPackagesAll = async ({
    isFetchMyPackages = true,
    isSetCurrent = true,
    isFetchPackagesRecommend = true,
  }) => {
    if (isFetchMyPackages) {
      fetchMyPackages(isSetCurrent);
    }
    if (isFetchPackagesRecommend) {
      fetchPackagesRecommend();
    }
  };

  return {
    fetchMyPackages,
    fetchPackagesRecommend,
    fetchPackagesAll,
    myPackageCurrentList:
      packageOjb.myPackageCurrentList ||
      initialStatePackage.myPackageCurrentList,
    myPackageCurrent:
      packageOjb.myPackageCurrent || initialStatePackage.myPackageCurrent,
  };
};
export default useFetchMyPackage;
