import styled from "styled-components";

export const TruncateText = styled.span.attrs(props => props)`
  display: -webkit-box;
  overflow: hidden;
  line-clamp: ${props => props.lineClamp};
  -webkit-line-clamp: ${props => props.lineClamp};
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  color: ${props => props.theme[props.color] || props.color};
  text-align: ${props => props.textAlign || "left"};
`;

export const H4 = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.h4Weight};
  font-size: ${props => props.theme.h4Size};
  line-height: ${props => props.theme.h4LineHeight};
  letter-spacing: ${props => props.theme.h4Spacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const H5 = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.h5Weight};
  font-size: ${props => props.theme.h5Size};
  line-height: ${props => props.theme.h5LineHeight};
  letter-spacing: ${props => props.theme.h5Spacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const SubH5 = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.subH5Weight};
  font-size: ${props => props.theme.subH5Size};
  line-height: ${props => props.theme.subH5LineHeight};
  letter-spacing: ${props => props.theme.subH5Spacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const H6 = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.h6Weight};
  font-size: ${props => props.theme.h6Size};
  line-height: ${props => props.theme.h6LineHeight};
  letter-spacing: ${props => props.theme.h6Spacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const SubH6 = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.subH6Weight};
  font-size: ${props => props.theme.subH6Size};
  line-height: ${props => props.theme.subH6LineHeight};
  letter-spacing: ${props => props.theme.subH6Spacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const Body1 = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.body1Weight};
  font-size: ${props => props.theme.body1Size};
  line-height: ${props => props.theme.body1LineHeight};
  letter-spacing: ${props => props.theme.body1Spacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const Subtitle1 = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.subTitle1Weight};
  font-size: ${props => props.theme.subTitle1Size};
  line-height: ${props => props.theme.subTitle1LineHeight};
  letter-spacing: ${props => props.theme.subTitle1Spacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const Subtitle1underLine = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.subTitle1UnderlineWeight};
  font-size: ${props => props.theme.subTitle1UnderlineSize};
  line-height: ${props => props.theme.subTitle1UnderlineLineHeight};
  letter-spacing: ${props => props.theme.subTitle1UnderlineSpacing};
  text-decoration: underline;
  text-align: ${props => props.textAlign || "left"};
`;

export const Body2 = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.body2Weight};
  font-size: ${props => props.theme.body2Size};
  line-height: ${props => props.theme.body2LineHeight};
  letter-spacing: ${props => props.theme.body2Spacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const Subtitle2 = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.subTitle2Weight};
  font-size: ${props => props.theme.subTitle2Size};
  line-height: ${props => props.theme.subTitle2LineHeight};
  letter-spacing: ${props => props.theme.subTitle2Spacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const Subtitle2underLine = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.subTitle2UnderlineWeight};
  font-size: ${props => props.theme.subTitle2UnderlineSize};
  line-height: ${props => props.theme.subTitle2UnderlineLineHeight};
  letter-spacing: ${props => props.theme.subTitle2UnderlineSpacing};
  text-decoration: underline;
  text-align: ${props => props.textAlign || "left"};
`;

export const Caption = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.captionWeight};
  font-size: ${props => props.theme.captionSize};
  line-height: ${props => props.theme.captionLineHeight};
  letter-spacing: ${props => props.theme.captionSpacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const captionLine = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.captionLineWeight};
  font-size: ${props => props.theme.captionLineSize};
  line-height: ${props => props.theme.captionLineLineHeight};
  letter-spacing: ${props => props.theme.captionLineSpacing};
  text-decoration: line-through;
  text-align: ${props => props.textAlign || "left"};
`;

export const Subcaption = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.subCaptionWeight};
  font-size: ${props => props.theme.subCaptionSize};
  line-height: ${props => props.theme.subCaptionLineHeight};
  letter-spacing: ${props => props.theme.subCaptionSpacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const SubcaptionUnderLine = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.subCaptionUnderlineWeight};
  font-size: ${props => props.theme.subCaptionUnderlineSize};
  line-height: ${props => props.theme.subCaptionUnderlineLineHeight};
  letter-spacing: ${props => props.theme.subCaptionUnderlineSpacing};
  text-decoration: underline;
  text-align: ${props => props.textAlign || "left"};
`;

export const TooltipFont = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.tooltipWeight};
  font-size: ${props => props.theme.tooltipSize};
  line-height: ${props => props.theme.tooltipLineHeight};
  letter-spacing: ${props => props.theme.tooltipSpacing};
  text-align: ${props => props.textAlign || "left"};
`;

export const SubTooltipFont = styled.span.attrs(props => props)`
  color: ${props => props.theme[props.color] || props.color};
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.subTooltipWeight};
  font-size: ${props => props.theme.subTooltipSize};
  line-height: ${props => props.theme.subTooltipLineHeight};
  letter-spacing: ${props => props.theme.subTooltipSpacing};
  text-align: ${props => props.textAlign || "left"};
`;
