import { useAxios } from "../../common/hooks/useProviderAxios";
import host from "../../config/host";

const enhanceProvider = () => {
  const { axios } = useAxios();
  const getEnhance = async packageKey => {
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${
        host.api
      }/content/private?packageKey=${packageKey}&categoryKey=${`article`}&type=article_enhance`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      const err = error?.response;
      throw err;
    }
  };

  return {
    getEnhance,
  };
};

export default enhanceProvider;
