/* eslint-disable react/no-string-refs */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Cookies from "js-cookie";
import { useProviderAuth } from "../../common/hooks/useProviderAuth";
import ModalTemplate from "../../common/components/ModalTemplate";
import ModalWallpaper from "../modalWallpaper";
import useWallpaperDownload from "./useWallpaperDownload";
import { setWallpaperDownloadField } from "./wallpaperDownloadSlice";
import host from "../../config/host";
import useExpireDate from "../../common/hooks/useExpireDate";
import { setModalAndMsgField, setModalField } from "../modal/modalSlice";

const StyleButtonDownloadWallpaper = styled.input`
  all: inherit;
  text-align: center;
`;

const ModalConfirmDownload = props => {
  const {
    toggle = null,
    isShowModal = false,
    handleClickBtnRight = null,
  } = props || {};

  const { packageCurrent = {} } = useSelector(state => state.package);
  const { accessToken = null } = useProviderAuth();

  const refBtn = useRef(null);

  const {
    indexWallpaperPreview = -1,
    wallpaperDownloadList = [],
    wallpaperDownloadDetail = {},
  } = useSelector(state => state.wallpaperDownload);

  const {
    modalDownloadDetail = {},
    postWallpaperPreview,
    postWallpaperDownload,
    getWallpaperDownloadList,
    isFinish,
  } = useWallpaperDownload();

  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState({
    wallpaper: "",
    manual: "",
    installation: "",
  });

  useEffect(() => {
    dispatch(
      setWallpaperDownloadField({
        key: "modalDownloadDetail",
        value: {},
      }),
    );
  }, []);

  useEffect(() => {
    if (modalDownloadDetail?.preview) {
      const token = Cookies.get("token");
      setImagePreview({
        wallpaper: `${host?.host}/wallpaper/preview/${modalDownloadDetail?.preview}.jpg?access_token=${token}`,
        manual: `${host?.host}/wallpaper/preview-manual/${modalDownloadDetail?.preview}.jpg?access_token=${token}`,
        installation: `${host?.host}/wallpaper/preview-installation/${modalDownloadDetail?.preview}.jpg?access_token=${token}`,
      });
    }
  }, [modalDownloadDetail?.preview]);

  useEffect(() => {
    if (isShowModal) {
      postWallpaperPreview();
    }
  }, [isShowModal]);

  const handleConfirm = async () => {
    try {
      await handleClickBtnRight();
      const res = await postWallpaperDownload();

      if (res?.download) {
        dispatch(
          setModalField({
            key: "isOpenModalDownloadWallpaper",
            value: true,
          }),
        );
      }

      await getWallpaperDownloadList();
    } catch (error) {
      console.log("error", error);
    }
  };

  // action confirm to download
  const handleConfirmDownload = async () => {
    if (!useExpireDate(packageCurrent?.expireDate)) {
      handleConfirm();
    } else {
      dispatch(
        setModalAndMsgField({
          key: "isOpenModalOverLimitPackage",
          value: true,
          message: "Your package has expired",
        }),
      );
    }
  };

  if (!isShowModal) {
    return null;
  }

  return (
    <ModalTemplate
      isShowModal={isShowModal}
      header="ตรวจสอบความถูกต้อง"
      toggle={toggle}
      btnLeft="ยกเลิก"
      btnRight="ยืนยัน"
      height="70vh"
      btnWidth="248px"
      callbackBtnLeft={() => toggle()}
      callbackBtnRight={e => handleConfirmDownload(e)}
    >
      <ModalWallpaper
        packageName={`${packageCurrent?.title} ${wallpaperDownloadList[indexWallpaperPreview]?.categoryName}`} // update packageName
        imgWallpaper={imagePreview?.wallpaper}
        imgManual={imagePreview?.manual}
        imgInstallation={imagePreview?.installation}
        description={modalDownloadDetail?.description}
        crossorigin="anonymous"
        isConfirmModal
      />
    </ModalTemplate>
  );
};

ModalConfirmDownload.defaultProps = {};

export default ModalConfirmDownload;
