import React from "react";
import styled from "styled-components";

/** @type {*} 
 *  <SeparateLine
        starCount = 1,
        width="auto"
        height="auto"
        marginTop={0}
        marginBottom={0}
        marginLeft={0}
        marginRight={0}
        colorIcon="secondaryMain",
        colorLine="secondaryMain",
    />
 * 
*/

const SeparateLineStyle = styled.div`
  --icon-size: 20px;
  --span-width: ${props => props.width || "100%"};

  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  margin-right: ${props => props.marginRight || 0}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;

    &.div-revert {
      flex-direction: row-reverse;
    }

    i {
      background: ${props => props.theme[props.colorIcon] || props.colorIcon};
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: var(--icon-size);

      &::before {
        margin: 0;
      }
    }
  }

  /* i:nth-child(n) {
    font-size: calc(var(--icon-size) - ($n + 10));
  } */

  span {
    width: var(--span-width);
    height: ${props => props.height || "1px"};
    font-size: var(--icon-size);
    background: ${props => props.theme[props.colorLine] || props.colorLine};

    @media screen and (max-width: 768px) {
      /* Screen Tablet */
      width: calc(var(--span-width) + 15%);
    }

    @media screen and (max-width: 430px) {
      /* Screen Mobile */
      width: calc(var(--span-width) + 10%);
    }
  }
`;

const SeparateLine = props => {
  const {
    starCount = 1,
    width = "40%",
    height = "1px",
    marginTop = 0,
    marginBottom = 0,
    marginLeft = "auto",
    marginRight = "auto",
    colorIcon = "secondaryMain",
    colorLine = "secondaryMain",
    className = "",
    icon = "icon-star",
  } = props;

  return (
    <SeparateLineStyle
      width={width}
      height={height}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      colorIcon={colorIcon}
      colorLine={colorLine}
      className={className}
    >
      <div>
        {[...Array(starCount)].map(() => (
          <i key={Math.random * 100} className={icon} />
        ))}
      </div>
      <span />
      <div className="div-revert">
        {[...Array(starCount)].map(() => (
          <i key={Math.random * 100} className={icon} />
        ))}
      </div>
    </SeparateLineStyle>
  );
};

export default SeparateLine;
