import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Body2 } from "./Font";

export const StyleButtonBack = styled(Body2)`
  cursor: pointer;
  color: ${props => props.theme[props.color] || props.$color};
`;

const ButtonBack = ({
  label,
  color,
  handleClick = null,
  className,
  to = "",
  scrollTo = "",
}) => {
  const getColor = () => {
    switch (color) {
      case "light":
        return "#fff";
      case "dark":
        return "#000";
      default:
        return "#fff";
    }
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(to || -1, {
      state: {
        scrollTo: scrollTo || "",
      },
    });
  };

  return (
    <StyleButtonBack
      color={getColor()}
      onClick={() => (handleClick ? handleClick() : goBack())}
      className={className}
    >
      {label}
    </StyleButtonBack>
  );
};

ButtonBack.defaultProps = {
  label: "< ย้อนกลับ" /* label text (string) : "< ย้อนกลับ" */,
  color: "white" /* text color (string option) : "white" "black" */,
  handleClick: null /* function callback on click (function) : () => {} */,
};

export default ButtonBack;
