import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  luckyNumber: {},
};

export const luckyNumberSlice = createSlice({
  name: "luckyNumber",
  initialState,
  reducers: {
    setLuckyNumberField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
    setLuckyNumberObjField: (state, params) => {
      const { payload } = params;
      const { key, keyObj, valueObj } = payload;

      state[key] = {
        ...state[key],
        [keyObj]: valueObj,
      };
    },
  },
});

export const luckyNumberState = state => state.luckyNumber;

export const { setLuckyNumberField, setLuckyNumberObjField } =
  luckyNumberSlice.actions;

export default luckyNumberSlice.reducer;
