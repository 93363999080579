import React from "react";
import styled from "styled-components";
import { Body2, Subcaption, TooltipFont } from "./Font";

const StyleWrapInputText = styled.div`
  width: 100%;
`;

const StyleInput = styled.input`
  width: 100%;
  margin-top: 0.5rem;
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.body2Weight};
  font-size: ${props => props.theme.body2Size};
  line-height: ${props => props.theme.body2LineHeight};
  letter-spacing: ${props => props.theme.body2Spacing};
  padding: 12px 16px;
  border-radius: 10px;
  border: none;
  outline: none;
  box-shadow: 0px 2px 4px 0px rgba(216, 207, 186, 0.3) inset;

  ${props =>
    props?.error && `border: 1px solid ${props?.theme?.statusTagErrorMain};`}

  &::placeholder {
    color: ${props => props?.theme?.textInput};
  }

  @media screen and (max-width: 768px) {
    padding: 12px 12px;
  }
`;

const StyleTextError = styled(TooltipFont)`
  display: flex;
  margin-top: 6px;
`;

const InputText = props => {
  const {
    name = "",
    label = "",
    placeholder = "",
    isRequire = true,
    limit = null,
    errMsg = null,
    type = "text",
    form = {},
  } = props;

  const {
    register,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = form;

  const handleChange = e => {
    if (type === "number" || type === "tel") {
      const formatNumber = e?.target?.value.replace(/\D/g, "");
      setValue(e?.target?.name, formatNumber);
    } else {
      const inputValue = e.target?.value?.trim();
      const validInput = inputValue.replace(
        // eslint-disable-next-line no-misleading-character-class
        /[^ก-ฮัเแโใไะาิีึืุูเแโใไำํๅ่้๊๋็ๆ์ฯ๚๛a-zA-Z]/g,
        "",
      );
      setValue(e?.target?.name, validInput);
    }
    clearErrors(e?.target?.name);
  };
  const values = getValues();

  return (
    <StyleWrapInputText>
      <Subcaption color="textInput">{label}</Subcaption>
      {isRequire && <Body2 color="statusTagErrorMain">*</Body2>}
      <StyleInput
        name={name}
        value={values && values[name]}
        placeholder={placeholder}
        errMsg={errors[name]?.message}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(name, {
          required: errMsg,
          onChange: e => handleChange(e),
          isRequire: {
            value: isRequire,
            message: errMsg,
          },
          maxLength: {
            value: limit,
            message: errMsg,
          },
          minLength: {
            value: 1,
            message: errMsg,
          },
        })}
        maxLength={limit}
        type={type}
      />
      {/* ======== error ======== */}
      {errors && errors[name]?.message && (
        <StyleTextError color="statusTagErrorMain">
          {errors[name]?.message}
        </StyleTextError>
      )}
    </StyleWrapInputText>
  );
};

export default InputText;
