// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import host from "../../config/host";
// import PackageProvider from "../../features/package/packageProvider";
import { ReactComponent as ImgLock } from "../../asset/images/img-lock.svg";
import ModalConfirm from "./ModalConfirm";
import IconHoroConfirm from "../../asset/images/icon-horo.svg";
import TitleText from "./TitleText";

const StyleLockComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: ${props => (props.isNoPermission ? "pointer" : "default")};
  ${props =>
    props.isNoPermission &&
    css`
      &.wrap-lock-access:hover {
        div {
          img,
          svg {
            transition: unset;
            /* transform: unset; */
            filter: unset;
          }
        }
      }
    `};
`;

const StyleImg = styled(ImgLock)`
  width: clamp(3.375rem, 1.8125rem + 5vw, 4.625rem);
  height: clamp(3.375rem, 1.8125rem + 5vw, 4.625rem);
  position: absolute;
  top: ${props => props.top || "50%"};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const TitleTextCustom = styled.div`
  display: flex;
  justify-content: center;

  & img {
    width: 12px;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
`;

const ImageAccess = styled.img`
  max-width: 200px;
  max-height: 200px;
  border-radius: 5px;
`;

const LockWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 99;
  position: absolute;
`;

const useHookLockComponant = () => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const navigate = useNavigate();
  const packageState = useSelector(state => state.package);
  const { accessPackage } = packageState;

  return {
    modalConfirm,
    setModalConfirm,
    navigate,
    accessPackage,
  };
};

const LockComponent = props => {
  const { children = null, feature = "", top } = props;
  const { modalConfirm, setModalConfirm, accessPackage } =
    useHookLockComponant(props);

  const { packageCurrent = {} } = useSelector(state => state.package);
  const { config = {} } = packageCurrent || {};
  const access = config[feature]?.access || "preview";
  const isNoPermission = Boolean(access === "preview");

  const openModalConfirm = () => {
    if (isNoPermission) {
      setModalConfirm(true);
    }
  };

  return (
    <div>
      <StyleLockComponent
        className="wrap-lock-access"
        isNoPermission={isNoPermission}
        onClick={() => {
          openModalConfirm();
        }}
      >
        {isNoPermission && (
          <LockWrapper>
            <StyleImg top={top} />
          </LockWrapper>
        )}
        {children}
      </StyleLockComponent>

      <ModalConfirm
        isShowModal={modalConfirm}
        toggle={() => setModalConfirm(!modalConfirm)}
        icon={IconHoroConfirm}
        message="แพ็กเกจของคุณไม่สามารถดูข้อมูลนี้ได้กรุณาสมัครแพ็กเกจที่สูงกว่าเพื่อปลดล็อกการใช้งาน"
        btnLeftText="ยกเลิก"
        btnRightText="สนใจสมัคร"
        callbackBtnLeft={() => setModalConfirm(false)}
        callbackBtnRight={() => {
          window.location = "/package?tab=2";
        }}
      >
        <TitleTextCustom>
          <TitleText label="เเพ็คเกจที่รองรับ" theme="black" size="medium" />
        </TitleTextCustom>
        <ImgWrap>
          {accessPackage?.map(pack => (
            <ImageAccess src={`${host.img}${pack.howtoImagePath}`} alt="" />
          ))}
        </ImgWrap>
      </ModalConfirm>
    </div>
  );
};

export default LockComponent;
