/* eslint-disable import/no-extraneous-dependencies */
import moment from "moment";

const useTomorrowDate = (expire = new Date()) => {
  const today = moment(expire).tz("Asia/Bangkok");
  const tomorrow = moment().tz("Asia/Bangkok").add(1, "days");

  const now = new Date(today);
  const exp = new Date(tomorrow);

  return Boolean(now > exp);
};

export default useTomorrowDate;
