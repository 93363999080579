/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { setWallpaperDownloadField } from "./wallpaperDownloadSlice";
import WallpaperDownloadProvider from "./wallpaperDownloadProvider";
import { setModalAndMsgField, setModalField } from "../modal/modalSlice";

const useWallpaperDownload = () => {
  const dispatch = useDispatch();
  const {
    wallpaperDownloadList = [],
    indexWallpaperDetail = -1,
    modalDownloadDetail = {},
    indexWallpaperPreview = -1,
    wallpaperDownloadDetail = {},
  } = useSelector(state => state.wallpaperDownload);
  const { packageCurrent = {} } = useSelector(state => state.package);

  const profileId = packageCurrent?.profileId || null; /// ============== update profileId

  const { content = {} } = wallpaperDownloadList[indexWallpaperPreview] || {};
  const { categoryId = null, selectedId = null } = content || {};

  const {
    getWallpaperDownload,
    apiPostWallpaperDownload,
    apiPostWallpaperPreview,
  } = WallpaperDownloadProvider();

  const navigate = useNavigate();
  const [isFinish, setIsFinish] = useState(false);

  const hanldeError = async error => {
    const { code, message } = error;
    if (
      (code === 422 && message === "Wallpaper over limit package") ||
      (code === 409 && message === "Duplicate data")
    ) {
      dispatch(
        setModalAndMsgField({
          key: "isOpenModalOverLimitPackage",
          value: true,
          message: "Wallpaper over limit package",
        }),
      );
    } else if (code === 400 && message === "Wallpaper does not exist") {
      navigate(`/package/${packageCurrent?.mediaKey}`);
    }
  };

  const getWallpaperDownloadList = async () => {
    try {
      const res = await getWallpaperDownload({
        profileId,
      });

      if (res) {
        // sort array by key
        const sortList = ["work", "money", "love", "enhance"];
        const wallpaperSorted = res.sort((a, b) => {
          return (
            sortList.indexOf(a.categoryKey) - sortList.indexOf(b.categoryKey)
          );
        });

        dispatch(
          setWallpaperDownloadField({
            key: "wallpaperDownloadList",
            value: wallpaperSorted,
          }),
        );
      }
    } catch (err) {
      hanldeError(err);
    }
  };

  const postWallpaperPreview = async () => {
    try {
      const params = {
        profileId,
        categoryId: categoryId || null,
        selectedId: selectedId || null,
      };

      const res = await apiPostWallpaperPreview(params);
      if (res) {
        dispatch(
          setWallpaperDownloadField({
            key: "modalDownloadDetail",
            value: res,
          }),
        );
      }
    } catch (err) {
      hanldeError(err);
      dispatch(
        setModalField({
          key: "isOpenModalConfirmDownload",
          value: false,
        }),
      );
    }
  };

  const postWallpaperDownload = async () => {
    let res = null;
    try {
      const params = {
        profileId,
        previewId: modalDownloadDetail?.previewId || null,
        selectedId: selectedId || null,
      };

      res = await apiPostWallpaperDownload(params);
      if (res) {
        setIsFinish(true);
        dispatch(
          setWallpaperDownloadField({
            key: "wallpaperDownloadDetail",
            value: res,
          }),
        );
      }
    } catch (err) {
      hanldeError(err);
      dispatch(
        setModalField({
          key: "isOpenModalDownloadWallpaper",
          value: false,
        }),
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return res;
    }
  };

  return {
    getWallpaperDownloadList,
    postWallpaperPreview,
    postWallpaperDownload,
    wallpaperDownloadList,
    indexWallpaperDetail,
    modalDownloadDetail,
    indexWallpaperPreview,
    wallpaperDownloadDetail,
    isFinish,
  };
};

export default useWallpaperDownload;
