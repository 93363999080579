/* eslint-disable import/no-extraneous-dependencies */
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// eslint-disable-next-line import/no-unresolved
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import PackageUser from "../pages/PackageUser";
import { ProvideAuth } from "../common/hooks/useProviderAuth";
import { AxiosProvider } from "../common/hooks/useProviderAxios";
import Article from "../pages/Article";
import MainPage from "../pages/MainPage";
// import PackageUser from "../pages/PackageUser";
import PostPage from "../pages/PostPage";
import Wallpaper from "../pages/wallpaper";
// import Main from "../pages/main";
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Toast from "../common/components/Toast";
import ToastProvider from "../common/hooks/useProviderToast";
import Login from "../pages/Login";
import Package from "../pages/package";
import Profile from "../pages/profile";
import theme from "../themes/theme";
// import dataStore from "./store";
import ErrorPage from "../pages/error";
import Howto from "../pages/howto";
import { persistor, store } from "./store";
import Loading from "../features/loading";

const App = () => {
  const PrivateRoute = ({ children }) => {
    // const auth = useAuth();
    // if (!auth.user) {
    //   return <Navigate to="/login" replace />;
    // }
    return children;
  };
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <ProvideAuth>
              <AxiosProvider>
                <Loading />
                <ToastProvider>
                  <Toast />

                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <MainPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/main"
                      element={
                        <PrivateRoute>
                          <MainPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/package"
                      element={
                        <PrivateRoute>
                          <Package />
                        </PrivateRoute>
                      }
                    />
                    <Route path="/package/:site" element={<PackageUser />} />
                    <Route path="/package/:site/howto" element={<Howto />} />
                    <Route
                      path="/package/:site/profile"
                      element={<Profile />}
                    />
                    <Route
                      path="/package/:site/wallpaper"
                      element={<Wallpaper />}
                    />
                    <Route path="/package/:site/post" element={<PostPage />} />
                    <Route path="/article/:id" element={<Article />} />
                    <Route path="*" element={<ErrorPage />} />
                    <Route path="/error" element={<ErrorPage />} />
                  </Routes>
                </ToastProvider>
              </AxiosProvider>
            </ProvideAuth>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
