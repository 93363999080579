import React from "react";
import styled from "styled-components";
import ImageCard from "../../asset/images/card-article.svg";
import host from "../../config/host";

const CardContainer = styled.a`
  border-radius: 7px;
  cursor: pointer;

  img {
    width: 100%;
    max-width: 400px;
    height: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 7px;
  }
`;

const CardArticle = props => {
  const { cover = null, href = null } = props;
  return (
    <CardContainer href={href}>
      <img src={`${host?.img}${cover}`} alt="" />
    </CardContainer>
  );
};

CardArticle.defaultProps = {
  image: ImageCard,
};

export default CardArticle;
