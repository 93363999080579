import { styled } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import ModalConfirm from "../common/components/ModalConfirm";
import host from "../config/host";
import { useAuth } from "../common/hooks/useProviderAuth";
import Layout from "../common/layout/Layout";
import TitleText from "../common/components/TitleText";

// import ImgPackage1 from "../asset/images/img-package1.png";
// import ImgPackage2 from "../asset/images/img-package2.png";
// import ImgPackage3 from "../asset/images/img-package3.png";
// import ImgPackage4 from "../asset/images/img-package4.png";
// import ImgPackage5 from "../asset/images/img-package5.png";

import ImgBorder1 from "../asset/images/img-border-decorate-1.svg";
import ImgBorder2 from "../asset/images/img-border-decorate-2.svg";

import ImgNumber1 from "../asset/images/icon-number-1.svg";
import ImgNumber2 from "../asset/images/icon-number-2.svg";
import ImgNumber3 from "../asset/images/icon-number-3.svg";
import IconStar from "../asset/images/icon-star-grey.svg";
import ImgLogoLogin from "../asset/images/logo-login.svg";
import Button from "../common/components/Button";
import {
  SubTooltipFont,
  Subcaption,
  SubcaptionUnderLine,
} from "../common/components/Font";
import useCopyTextToClipboard from "../common/hooks/useCopyTextToClipboard";
import { setPackageField } from "../features/package/packageSlice";
import PackageProvider from "../features/package/packageProvider";
import ButtonBack from "../common/components/ButtonBack";

const StyleButtonBack = styled(ButtonBack)`
  padding: 8px 16px;
`;

const StyleBodyContainer = styled.div`
  display: block;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 70px;
  padding-left: 16px;
  overflow-x: hidden;
`;

const StyleContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;

const StyleContainerSnapLeft = styled.div`
  white-space: pre;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

const StyleUl = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  padding: 0px;
  width: 90%;
`;

const StyleLi = styled.li`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

const StyleBorderCard = styled.div`
  position: relative;
  .image-package {
    width: 370px;
    height: 370px;
    position: relative;
    z-index: 3;
    border-radius: 5px;

    @media (max-width: 430px) {
      width: 240px;
      height: 240px;
      aspect-ratio: 1/1;
    }
  }
  &::before {
    content: "";
    position: absolute;
    background-image: url(${ImgBorder1});
    top: 55px;
    right: -50px;
    z-index: 1;
    width: 124px;
    height: 111px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 124px;
    height: 111px;
    top: 220px;
    left: -50px;
    z-index: 1;
    background-image: url(${ImgBorder2});

    @media (max-width: 430px) {
      top: 100px;
    }
  }
`;

const StyleTextWink = styled.a`
  span {
    animation: flicker 1.5s infinite alternate;
  }
  @keyframes flicker {
    0%,
    50%,
    80% {
      text-shadow:
        0 0 2px #000000,
        0 0 3px #ffffff,
        0 0 4px #eac16f;
      filter: brightness(1.1);
    }

    10%,
    60%,
    90%,
    100% {
      text-shadow: none;
      filter: brightness(1);
    }
  }
`;

const DetailWrap = styled.div`
  display: flex;
  white-space: pre;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
`;

const ButtonLogin = styled(Button)`
  display: inline-block;
`;

const StyleContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
  padding: 0px 40px 40px 40px;
`;

const StyleMessage = styled.div`
  font-size: ${props => props?.theme?.body1Size};
  font-family: ${props => props?.theme?.primaryFont};
  line-height: ${props => props?.theme?.body1LineHeight};
`;

const Howto = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { site } = useParams();
  const { isLogin } = useAuth();
  const { getPackageAll, getPackages } = PackageProvider();
  const { packageAll } = useSelector(state => state?.package) || {};
  const [detailPackageObj, setDetailPackageObj] = useState({
    image: null,
    tel: null,
  });
  const { image, tel } = detailPackageObj;
  const [isModalConfirmUsingPackage, setIsModalConfirmUsingPackage] =
    useState(false);

  const fetchGetPackageAll = async () => {
    try {
      const res = await getPackageAll();
      dispatch(
        setPackageField({
          key: "packageAll",
          value: res,
        }),
      );
    } catch (error) {
      console.log(`error:`, error);
    }
  };

  const fetchGetUserPackages = async () => {
    try {
      const response = await getPackages();
      dispatch(
        setPackageField({
          key: "packageCurrentList",
          value: response,
        }),
      );
      return response;
    } catch (error) {
      return false;
    }
  };

  const getPackage = () => {
    const detailPackage = packageAll?.find(pac => pac.mediaKey === site);
    return {
      image: `${host?.img}${detailPackage?.howtoImagePath}`,
      tel: detailPackage?.description,
    };
    // switch (site) {
    //   case "lucky":
    //     return {
    //       image: ImgPackage1,
    //       tel: "*539*59#",
    //     };
    //   case "love":
    //     return {
    //       image: ImgPackage2,
    //       tel: "*539*69#",
    //     };
    //   case "money":
    //     return {
    //       image: ImgPackage3,
    //       tel: "*539*99#",
    //     };
    //   case "work":
    //     return {
    //       image: ImgPackage4,
    //       tel: "*539*159#",
    //     };
    //   case "health":
    //     return {
    //       image: ImgPackage5,
    //       tel: "*539*199#",
    //     };
    //   default:
    //     return {
    //       image: ImgPackage1,
    //       tel: "*539*59#",
    //     };
    // }
  };

  useEffect(() => {
    fetchGetPackageAll();
  }, []);

  useEffect(() => {
    const { image: imageProps, tel: telProps } = getPackage();
    setDetailPackageObj({
      image: imageProps,
      tel: telProps,
    });
  }, [packageAll]);

  const checkUserPackageAvailable = async () => {
    const userPackage = await fetchGetUserPackages();
    if (userPackage && userPackage?.length > 0) {
      const packageIsExist = userPackage.find(
        element => element?.mediaKey === site,
      );
      return {
        isUserPackageAvailable: !!packageIsExist,
        hasProfileId: packageIsExist ? !!packageIsExist?.profileId : false,
        isExpire:
          packageIsExist && packageIsExist?.expireDate
            ? moment(packageIsExist?.expireDate) < moment(new Date())
            : false,
      };
    }
    return false;
  };

  const handleSubmit = async () => {
    const { isUserPackageAvailable, hasProfileId, isExpire } =
      await checkUserPackageAvailable();
    if (isUserPackageAvailable) {
      if (!isLogin) {
        navigate("/login");
        return;
      }
      if (isExpire) {
        /* case expire แต่ยังไม่ต่ออายุ */
        setIsModalConfirmUsingPackage(true);
        return;
      }
      if (!hasProfileId) {
        navigate(`/package/${site}/profile`);
        return;
      }
      navigate(`/package/${site}`);
    } else {
      setIsModalConfirmUsingPackage(true);
    }
  };

  return (
    <Layout>
      <StyleButtonBack label="< ย้อนกลับ" />
      <StyleBodyContainer>
        <StyleContentContainer>
          <TitleText label="สมัครแพ็กเกจ" theme="white" />
          <StyleBorderCard>
            <img src={image} alt="packages" className="image-package" />
          </StyleBorderCard>
          <TitleText label="ขั้นตอนการใช้งาน" theme="white" size="small" />
          <StyleUl>
            <StyleLi>
              <img src={ImgNumber1} alt="number-1" />
              <Subcaption color="white">
                กดหมายเลข&nbsp;
                <StyleTextWink href={`tel:${tel}`}>
                  <SubcaptionUnderLine
                    textAlign="center"
                    role="presentation"
                    onClick={() => useCopyTextToClipboard(`${tel}`)}
                    color="actionIcon"
                  >
                    {tel}
                  </SubcaptionUnderLine>
                </StyleTextWink>
                &nbsp;แล้วกดโทรออก
              </Subcaption>
            </StyleLi>
            <StyleLi>
              <img src={ImgNumber2} alt="number-2" />
              <Subcaption color="white">
                รอรับ SMS ยืนยันการสมัครบริการมงคลหนุนดวง
              </Subcaption>
            </StyleLi>
            <StyleLi
            // style={{
            //   alignItems: "start",
            // }}
            >
              <img
                src={ImgNumber3}
                alt="number-3"
                style={{
                  marginTop: "5px",
                }}
              />
              <DetailWrap>
                <Subcaption color="white">
                  {`เมื่อได้รับ SMS ยืนยันการสมัคร กดปุ่ม `}
                  <ButtonLogin handleClick={handleSubmit} width="80px">
                    เข้าสู่ระบบ
                  </ButtonLogin>
                  {` เพื่อใช้งาน`}
                </Subcaption>
              </DetailWrap>
            </StyleLi>
          </StyleUl>
          {/* <Button handleClick={handleSubmit}>เข้าสู่ระบบ</Button> */}
          <StyleContainerSnapLeft>
            <Subcaption color="#79748F">เงื่อนไขบริการ</Subcaption>
            <StyleUl>
              <StyleLi>
                <img src={IconStar} alt="number-3" />
                <SubTooltipFont color="#79748F">
                  สมัครครั้งแรก รับสิทธิ์ทดลองใช้ฟรี 30 วัน (สำหรับลูกค้า AIS)
                </SubTooltipFont>
              </StyleLi>
              <StyleLi>
                <img src={IconStar} alt="number-3" />
                <SubTooltipFont color="#79748F">
                  หลังจากนั้นคิดค่าบริการตามเเพ็กเกจที่ท่านสมัคร
                </SubTooltipFont>
              </StyleLi>
              <StyleLi>
                <img src={IconStar} alt="number-3" />
                <SubTooltipFont color="#79748F">ติดต่อเพิ่มเติม</SubTooltipFont>
              </StyleLi>
            </StyleUl>
          </StyleContainerSnapLeft>
        </StyleContentContainer>
        <ModalConfirm
          isShowModal={isModalConfirmUsingPackage}
          toggle={() => {
            setIsModalConfirmUsingPackage(!isModalConfirmUsingPackage);
          }}
          // icon={ImgLogoLogin}
          // message="ท่านยังไม่ได้สมัครบริการ (ขั้นตอนข้อที่ 1) กรุณากดตกลงและทำตามขั้นตอนการใช้งาน"
          btnLeftText={null}
          callbackBtnLeft={() => {}}
          callbackBtnRight={() => setIsModalConfirmUsingPackage(false)}
          callbackCloseModal={() => {}}
        >
          <StyleContentModal>
            {/* <Lottie options={defaultOptions} width={150} /> */}
            <img src={ImgLogoLogin} alt="ConfirmImage" />
            <StyleMessage
              dangerouslySetInnerHTML={{
                __html: `ท่านยังไม่ได้สมัครบริการ <br/>  (ขั้นตอนข้อที่ 1) <br/>
                กรุณากดตกลงและทำตามขั้นตอนการใช้งาน`,
              }}
            />
          </StyleContentModal>
        </ModalConfirm>
      </StyleBodyContainer>
    </Layout>
  );
};

Howto.defaultProps = {};

export default Howto;
