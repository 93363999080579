import React from "react";
import styled from "styled-components";
import ButtonBack from "../common/components/ButtonBack";
import Layout from "../common/layout/Layout";
import TitleText from "../common/components/TitleText";
import FormProfile from "../features/formProfile";

const StyleButtonBack = styled(ButtonBack)`
  padding: 8px 16px;
`;

const StyleContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 32px 0px;
  gap: 16px;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;

const StyleTitleText = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
`;

const Profile = () => {
  return (
    <Layout>
      <StyleButtonBack label="< ย้อนกลับ" />
      <StyleTitleText>
        <TitleText label="กรอกข้อมูลเพื่อใช้ในการวิเคราะห์" size="large" />
      </StyleTitleText>
      <StyleContainer>
        <FormProfile />
      </StyleContainer>
    </Layout>
  );
};

export default Profile;
