// Checkbox.js
import styled from "styled-components";

const CheckboxContainer = styled.label`
  position: relative;
  display: block;
  /* padding-left: 30px; */
  cursor: pointer;
  font-size: 16px;
`;

const HiddenCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const StyledCheckbox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid ${props => (props.isChecked ? "#FFFFFF" : "#06192F")};
  border-radius: 50px;
`;

const Checkmark = styled.div`
  position: absolute;
  display: none;
`;

const CheckboxLabel = styled.span`
  margin-left: 10px;
`;

const CheckboxWrap = styled.div`
  width: 20px;
`;

const CustomCheckbox = styled(CheckboxContainer)`
  ${HiddenCheckbox}:checked + ${StyledCheckbox} {
    background-color: #06192f;
  }

  ${HiddenCheckbox}:checked + ${StyledCheckbox} + ${Checkmark} {
    display: block;
  }

  ${Checkmark}::before {
    content: "";
    position: absolute;
    left: 7px;
    top: 3px;
    width: 6px;
    height: 12px;
    border-style: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    border-color: white;
    border-radius: 2px; /* ปรับความโค้งของมุมติ๊กถูก */
  }
`;

const Checkbox = ({ checked }) => {
  return (
    <CheckboxWrap>
      <CustomCheckbox>
        <HiddenCheckbox checked={checked} />
        <StyledCheckbox checked={checked} />
        <Checkmark />
        <CheckboxLabel />
      </CustomCheckbox>
    </CheckboxWrap>
  );
};

export default Checkbox;

// สามารถนำไปใช้ใน component ของคุณได้เลย
