import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import host from "../../config/host";
import Bg159 from "../../asset/images/bg-video-159.jpg";
import Bg199 from "../../asset/images/bg-video-199.jpg";
import Bg59 from "../../asset/images/bg-video-59.jpg";
import Bg69 from "../../asset/images/bg-video-69.jpg";
import Bg99 from "../../asset/images/bg-video-99.jpg";
import IconPlay from "../../asset/images/img-play.svg";

import { Body2, Subtitle1 } from "../../common/components/Font";
import useExpireDate from "../../common/hooks/useExpireDate";
import { setModalAndMsgField } from "../modal/modalSlice";

const getBgVideo = mediaKey => {
  switch (mediaKey) {
    case "lucky":
      return Bg59;
    case "victory":
      return Bg69;
    case "success":
      return Bg99;
    case "fully":
      return Bg159;
    case "enhance":
      return Bg199;
    default:
      return Bg59;
  }
};

const StyleWrapVideo = styled.div`
  height: 100%;
  width: 100%;
  // max-width: 380px;
  background-image: url(${props => getBgVideo(props?.type)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 32px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;

  @media (max-width: 430px) {
    width: 100%;
    max-width: 100%;
    background-size: 100% 100%;
  }
`;

const StyleCoverVideo = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  background: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  justify-items: center;
  align-items: flex-start;

  @media (max-width: 430px) {
    width: 90%;
  }

  ${props =>
    props?.isPremiumPackage &&
    `
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: linear-gradient(
      100deg,
      #e2b060,
      #eecb5d,
      #fbf183,
      #d29e3c,
      #c68c2f,
      #e0b54c
    );
    background-size: 400%;
    width: calc(300px + 8px);
    height: calc(100% + 8px);
    z-index: 2;
    top: -4px;
    animation: ${props?.isPremiumPackage && "animate 40s linear infinite"};

    @media (max-width: 430px) {
      width: calc(100% + 8px);
    }
  }`}

  &::after {
    filter: blur(8px);

    @media (max-width: 430px) {
      width: 100%;
    }
  }

  @keyframes animate {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

const StyleVideo = styled.video`
  z-index: 3;
  width: 100%;
  max-width: 300px;
  height: auto;
  position: relative;
  cursor: pointer;
  object-fit: cover;
  aspect-ratio: 4 / 5;

  @media (max-width: 430px) {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
`;

const StyleImgPlay = styled.img`
  width: 20%;
  height: 20%;
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const Subtitle1Custom = styled(Subtitle1)`
  padding: 0px 8px;
  text-align: center !important;

  @media (max-width: 430px) {
    font-size: 18px !important;
  }
`;

const Body2Custom = styled(Body2)`
  padding: 12px 8px;
  white-space: break-spaces;
  word-break: break-word;
  word-wrap: break-word;

  @media (max-width: 430px) {
    font-size: 16px !important;
  }
`;

const StyleTextDesc = styled.div`
  width: 100%;
  word-wrap: break-word;
  text-align: center;
  padding-top: 12px;

  @media (max-width: 430px) {
    width: 80%;
  }
`;

const StyleCoverText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  width: auto;
`;

const StyleName = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80%;

  @media (max-width: 430px) {
    gap: 0px;
  }
`;

const VideoWidget = props => {
  const { videoContent, mediaKey, firstName, lastName } = props;
  const [isShowIconPlay, setIsShowIconPlay] = useState(true);
  const [videoData, setVideoData] = useState(null);
  const isPremiumPackage = mediaKey === "enhance";
  const dispatch = useDispatch();
  const packageState = useSelector(state => state.package);
  const { packageCurrent } = Object(packageState);

  const refVideo = useRef(null);

  const handleClick = () => {
    const video = refVideo?.current;
    if (useExpireDate(packageCurrent?.expireDate)) {
      dispatch(
        setModalAndMsgField({
          key: "isOpenModalOverLimitPackage",
          value: true,
          message: "Your package has expired",
        }),
      );
    } else if (video.paused) {
      video.play();
      setIsShowIconPlay(false);
    } else {
      video.pause();
      setIsShowIconPlay(true);
    }
  };

  const handleVideoEnded = () => {
    setIsShowIconPlay(true);
  };

  useEffect(() => {
    if (videoContent?.video?.mediaPath) {
      setVideoData(`${host?.video}${videoContent?.video?.mediaPath[0].path}`);
    }
  }, [videoContent]);

  return (
    <StyleWrapVideo type={mediaKey}>
      {videoData && (
        <StyleCoverVideo isPremiumPackage={isPremiumPackage}>
          <StyleVideo
            ref={refVideo}
            loop={false}
            onClick={e => handleClick(e)}
            onEnded={() => handleVideoEnded()}
            poster={`${host?.img}${videoContent?.coverPath}` || ""}
          >
            <source
              // src={`${host?.video}${videoContent?.video?.mediaPath[0].path}`}
              src={videoData.trim()}
              srcLang="en"
              type="video/mp4"
            />
            <track
              src="video_en.vtt"
              kind="captions"
              srcLang="en"
              label="English"
            />
          </StyleVideo>
          {isShowIconPlay && (
            <StyleImgPlay
              src={IconPlay}
              alt="icon-play"
              onClick={e => handleClick(e)}
            />
          )}
        </StyleCoverVideo>
      )}
      <StyleCoverText>
        <StyleName>
          <Subtitle1Custom
            color={mediaKey === "lucky" ? "black" : "white"}
            className=""
          >
            {firstName}
          </Subtitle1Custom>

          <Subtitle1Custom
            color={mediaKey === "lucky" ? "black" : "white"}
            className=""
          >
            {lastName}
          </Subtitle1Custom>
        </StyleName>

        <StyleTextDesc>
          <Body2Custom color={mediaKey === "lucky" ? "black" : "white"}>
            {/* {videoContent?.description} */}
            ดวงของคุณได้รับการสวดหนุนดวงตามพระประจำวันเกิดแล้ว
          </Body2Custom>
        </StyleTextDesc>
      </StyleCoverText>
    </StyleWrapVideo>
  );
};

export default VideoWidget;
