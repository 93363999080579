import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {},
};

export const formLoginSlice = createSlice({
  name: "formLogin",
  initialState,
  reducers: {
    setFormLoginField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
    logout: () => {
      // CLEAR Redux Store's state
    },
  },
});

export const formLoginState = state => state.formLogin;

export const { setFormLoginField, logout } = formLoginSlice.actions;

export default formLoginSlice.reducer;
