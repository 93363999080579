/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DangerousHTMLComponent from "../../common/components/DangerousHTML";
import TitleText from "../../common/components/TitleText";
import host from "../../config/host";
import ModalConfirm from "../../common/components/ModalConfirm";
import { Body1, Subcaption } from "../../common/components/Font";
import BgContentStar from "../../asset/images/bg-content-star.svg";
import enhanceProvider from "./enhanceProvider";
import packageProvider from "../package/packageProvider";

const StyleImageDetail = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
`;

const StyleCoverImg = styled.div`
  width: 100%;
  position: relative;
  background-image: url(${BgContentStar});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
`;

const StyleImageContent = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  color: transparent;
`;

const StyleText = styled(Body1)`
  padding: 16px;
  width: 407px;
  text-align: center;

  &.is-load-more {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0px;
  }

  @media (max-width: 430px) {
    width: 100%;
  }
`;

const StyleCoverStar = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const StyleIconStar = styled.div`
  font-size: ${props => (props?.small ? "8px" : "10px")};
`;

const MoreDetailWrap = styled(Subcaption)`
  cursor: pointer;
  margin-bottom: 20px;
`;

const StyleContentDetail = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  background-color: ${props => props.theme.primaryBG200};
`;

const ImageDetail = props => {
  const {
    content = "",
    img,
    isMoreDetail,
    handleMoreDetail,
    isLoadmore,
    fullContent,
    contentPreview,
    setLoadmore,
  } = props;

  return (
    <StyleImageDetail>
      <StyleCoverImg>
        <StyleImageContent src={`${host.img}${img}`} alt="img-content" />
      </StyleCoverImg>
      <StyleContentDetail>
        <StyleText className={!isLoadmore ? "is-load-more" : ""}>
          <DangerousHTMLComponent
            htmlContent={isLoadmore ? fullContent : contentPreview}
          />{" "}
        </StyleText>
        {fullContent ? (
          <MoreDetailWrap onClick={() => handleMoreDetail()}>
            {isLoadmore ? "ดูน้อยลง" : "ดูเพิ่มเติม"}
          </MoreDetailWrap>
        ) : null}
      </StyleContentDetail>
      <StyleCoverStar>
        {[...Array(20)].map((item, index) => (
          <StyleIconStar className="icon-star-small" small={index % 2 !== 0} />
        ))}
      </StyleCoverStar>
    </StyleImageDetail>
  );
};

export default ImageDetail;
