import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoadingField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
  },
});

// reducer
export const loadingState = state => state.loading;

// this action for dispatch
export const { setLoadingField } = loadingSlice.actions;

// this is for configureStore
export default loadingSlice.reducer;
