/* eslint-disable import/no-extraneous-dependencies */
import moment from "moment";

const useExpireDate = (expire = new Date()) => {
  const todayDate = moment().tz("Asia/Bangkok");
  const expDate = moment(expire).tz("Asia/Bangkok");
  const now = new Date(todayDate);
  const exp = new Date(expDate);

  return Boolean(now > exp);
};

export default useExpireDate;
