import { useAxios } from "../../common/hooks/useProviderAxios";
import host from "../../config/host";

const FormProfileProvider = () => {
  const { axios } = useAxios();
  const getZodiacList = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host.api}/zodiac`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const getUserZodiac = async param => {
    /* (param :: {birthDate: "18/04/1999"}) */
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host.api}/zodiac/get?birthdate=${param?.birthDate}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const createProfile = async param => {
    /*  param
    {
        "packageId": 1,
        "zodiacId":8,
        "firstName": "Thoonly",
        "lastName": "Sone",
        "gender": "male",s
        "birthdate": "1984-08-18T03:02:20.000Z"
    } */

    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host.api}/member/profile`;
      const res = await axios.post(url, param);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const getProfile = async param => {
    /* param: {profileId: 122} */
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host.api}/member/profile/${param}`;
      const res = await axios.get(url, param);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getZodiacList,
    getUserZodiac,
    createProfile,
    getProfile,
  };
};

export default FormProfileProvider;
