import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Body2, Subcaption, TooltipFont } from "./Font";

const StyleDropdownToggle = styled(DropdownToggle)`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: ${props => props.theme.white} !important;
  box-shadow: 0px 2px 4px 0px rgba(216, 207, 186, 0.3) inset;
  border: ${props =>
    props.error
      ? `1px solid ${props.theme.statusTagErrorMain}`
      : "none"} !important;

  @media screen and (max-width: 768px) {
    padding: 8px 10px;
  }
`;

const StyleDropdownMenu = styled(DropdownMenu)`
  width: 100%;
  margin: 4px 0px;
  border: none;
  border-radius: 10px;
  padding: 8px;
  max-height: 290px;
  overflow-y: auto;
`;

const StyleDropdownItem = styled(DropdownItem)`
  background-color: ${props => props.theme.white} !important;
  width: 100%;
  border-radius: 5px;
  padding: 8px 16px;

  &:hover {
    background-color: ${props => props.theme.secondaryLight200} !important;
  }
`;

const StyleIconDropdown = styled.div`
  color: ${props => props.theme.secondaryMain};
  font-size: 20px;
  transform: rotate(0deg);

  ${props => props.isOpenDropdown && `  transform: rotate(-180deg);`}
`;

const DropdownSelection = props => {
  const {
    label = "",
    options = [],
    errorMsg = "",
    isRequire = false,
    placeholder = "",
    name = "",
    form = {},
  } = props;

  const {
    register,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = form;

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [valueSelected, setValueSelected] = useState(null);
  const watchValue = watch(name);

  const toggle = () => setIsOpenDropdown(!isOpenDropdown);

  useEffect(() => {
    if (watchValue) {
      const optionSelected = options.find(item => item?.value === watchValue);
      setValueSelected(optionSelected);
    }
  }, [watchValue]);

  const handleClick = data => {
    setValue(data?.name, data?.value);
    clearErrors(data?.name);
    const optionSelected = options.find(item => item?.value === data?.value);
    setValueSelected(optionSelected);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {/* ======== title ======== */}
      <Subcaption color="textInput">{label} </Subcaption>
      {isRequire && <Body2 color="statusTagErrorMain">*</Body2>}

      {/* ======== dropdown ======== */}
      <Dropdown
        isOpen={isOpenDropdown}
        toggle={() => toggle()}
        name={name}
        className="mt-2"
        innerRTef={{
          ...register(name, {
            required: errorMsg,
            isRequire: {
              value: isRequire,
              message: errorMsg,
            },
          }),
        }}
      >
        <StyleDropdownToggle error={errors && errors[name]?.message}>
          <Body2 color={valueSelected ? "black" : "textInput"}>
            {valueSelected ? valueSelected.label : placeholder}
          </Body2>
          <StyleIconDropdown
            className="icon-dropdown"
            isOpenDropdown={isOpenDropdown}
          />
        </StyleDropdownToggle>
        <StyleDropdownMenu>
          {options.map((item, index) => (
            <StyleDropdownItem
              // eslint-disable-next-line react/no-array-index-key
              key={`dropdown-item-${index}`}
              onClick={() =>
                handleClick({
                  name,
                  value: item?.value,
                })
              }
            >
              <Body2 color="black">{item?.label}</Body2>
            </StyleDropdownItem>
          ))}
        </StyleDropdownMenu>
      </Dropdown>

      {/* ======== error ======== */}
      {errors && errors[name]?.message && (
        <TooltipFont color="statusTagErrorMain">
          {errors[name]?.message}
        </TooltipFont>
      )}
    </div>
  );
};

export default DropdownSelection;
