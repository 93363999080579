import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LOGO from "../../asset/images/LOGO.svg";
import BG_FOOTER from "../../asset/images/BG-Footer.svg";
import BG_FOOTER_MOBILE from "../../asset/images/BG-Footer-mobile.svg";
import Image from "./Image";
import { Caption } from "./Font";
import TitleText from "./TitleText";

/** @type {*} 
 *  <Footer
width="auto"
marginTop={0}
        marginBottom={0}
        marginLeft={0}
        marginRight={0}
    />
 * 
*/

const FooterStyle = styled.div`
  --icon-size: 20px;

  width: ${props => props.width || "0px"};
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  margin-right: ${props => props.marginRight || 0}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-image: ${props => `url(${props.backgroundImage})`};
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 900px) {
    background-image: ${props => `url(${props.backgroundImageMobile})`};
  }

  :is(.footer-content, .footer-content-detail, .footer-copy-rights) {
    width: ${props => props.width || "0px"};
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .footer-content {
    flex-direction: column;
    gap: 16px;
  }

  .footer-content-detail {
    flex-direction: column;
    gap: 8px;

    a {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .footer-copy-rights {
    flex-direction: column;
    border-top: 0.3px solid ${props => props.theme.goldB};
  }
`;

const Footer = props => {
  const {
    width = "100%",
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    backgroundImg = BG_FOOTER,
    backgroundImgMobile = BG_FOOTER_MOBILE,
  } = props;
  const location = window.location.pathname;

  return (
    <FooterStyle
      width={width}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      backgroundImage={backgroundImg}
      backgroundImageMobile={backgroundImgMobile}
    >
      <div className="footer-content">
        <Image
          src={LOGO}
          width="auto" // string
          height="65px" // string
        />
        <div className="footer-content-detail">
          {(location === "/" || location === "/main") && (
            <TitleText
              label="วิธียกเลิกบริการ กด *137 แล้วโทรออก"
              theme="gold"
              size="small"
            />
          )}
          <Link to="/main">
            <TitleText label="บริการหนุนดวง" theme="gold" size="small" />
          </Link>
        </div>
      </div>
      <div className="footer-copy-rights">
        <Caption color="white">
          &copy; Copyright Noonduang. All rights reserved.
        </Caption>
      </div>
    </FooterStyle>
  );
};

export default Footer;
