/* eslint-disable no-useless-catch */

import { useAxios } from "../../common/hooks/useProviderAxios";
import host from "../../config/host";

const LuckyNumberProvider = () => {
  const { axios } = useAxios();

  const getLuckyNumberById = async profileId => {
    try {
      const url = `${host.api}/member-lucky-number/profile/${profileId}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const updateLuckyNumberById = async data => {
    /* 
    {
      "profileId": {{profileId}},
      "luckyNumber": "000"
    }
    */
    try {
      const url = `${host.api}/member-lucky-number`;
      const res = data?.luckyNumber ? await axios.post(url, data) : {};
      return res.data;
    } catch (error) {
      throw error;
    }
  };
  return {
    getLuckyNumberById,
    updateLuckyNumberById,
  };
};

export default LuckyNumberProvider;
