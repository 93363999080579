import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const authContext = createContext();

const fakeAuth = {
  isAuthenticated: false,
  signin(callback) {
    fakeAuth.isAuthenticated = true;
    callback();
    // setTimeout(callback, 100); // fake async
  },
  signout(callback) {
    fakeAuth.isAuthenticated = false;
    callback();
    // setTimeout(cb, 100);
  },
};

export const useProviderAuth = () => {
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  const navigate = useNavigate();

  // const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("token");
    const refresh = Cookies.get("refresh");

    setAccessToken(token);
    setRefreshToken(refresh);
  }, []);

  const signin = (tokens, callback = null) => {
    const { token, refresh } = tokens || {};
    Cookies.set("token", token);
    Cookies.set("refresh", refresh);

    return fakeAuth.signin(() => {
      if (callback) callback();
    });
  };

  const signout = () => {
    Cookies.remove("token");
    Cookies.remove("refresh");
    setAccessToken(null);
    setRefreshToken(null);
    navigate("/login");
  };

  return {
    user,
    signin,
    signout,
    accessToken,
    refreshToken,
    isLogin: !!accessToken,
  };
};

export const useAuth = () => {
  return useContext(authContext);
};

export const ProvideAuth = ({ children }) => {
  const auth = useProviderAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
