import React from "react";
import styled from "styled-components";
import { Subtitle2 } from "../Font";
import Image from "../Image";
import Theme from "../../../themes/theme";

/** @type {*} 
 *  <TabBarSlide
        callbackTabBarSlideOnChange={()=>null}
        option={[
            { id: 'tab1', value: 'current-package', label: 'แพ็กเกจปัจจุบัน' },
            { id: 'tab2', value: 'recommend-package', label: 'แนะนำสำหรับคุณ' },
        ]}
        isTabBarSlideActive="tab1"
        width="auto"
        height="auto"
        marginTop={0}
        marginBottom={0}
        marginLeft={0}
        marginRight={0}
        color="transparent"
        colorActive="transparent"
        backgroundColor="transparent"
        backgroundColorActive="transparent"
    />
 * 
*/

const TabBarSlideStyle = styled.div`
  width: ${props => props.width || "0px"};
  height: ${props => props.height || "0px"};
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  margin-right: ${props => props.marginRight || 0}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input[type="radio"] {
    display: none;
  }

  .tabs {
    display: flex;
    gap: 4px;
    position: relative;
    background-color: ${props =>
      props.theme[props.backgroundColor] || props.backgroundColor};
    box-shadow:
      0 10px 20px 0 rgba(7, 41, 19, 0.01),
      0 0 20px 0 rgba(7, 41, 19, 0.1);
    padding: 10px;
    border-radius: 100px;
  }

  .tabs > * {
    z-index: 2;
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 48px;
    width: 160px;
    border-radius: 99px;
    cursor: pointer;
    transition:
      color 0.5s,
      text-shadow 0.5s,
      font-size 0.5s;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  input[type="radio"]:checked + label {
    /* color: ${props => props.theme[props.colorActive] || props.colorActive};
    font-size: calc(${Theme.subTitle2Size} * 1.12); */
  }

  .selector {
    position: absolute;
    display: flex;
    height: 48px;
    width: 160px;
    background-color: ${props =>
      props.theme[props.backgroundColorActive] || props.backgroundColorActive};
    border: 1px solid
      ${props => props.theme[props.colorActive] || props.colorActive};
    z-index: 1;
    border-radius: 100px;
    transition: transform 0.5s ease-in-out;
  }

  input[id="tab1"]:checked ~ .selector {
    transform: translateX(0);
  }

  input[id="tab2"]:checked ~ .selector {
    transform: translateX(calc(100% + 4px));
  }

  input[id="tab3"]:checked ~ .selector {
    transform: translateX(calc(200% + 8px));
  }

  input[id="tab4"]:checked ~ .selector {
    transform: translateX(calc(300% + 12px));
  }

  input[id="tab5"]:checked ~ .selector {
    transform: translateX(calc(300% + 16px));
  }

  @keyframes squish1 {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 0.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

const TabBarSlide = props => {
  const {
    callbackTabBarSlideOnChange = null,
    option = [
      {
        id: "tab1",
        name: "current-package",
        label: "แพ็กเกจปัจจุบัน",
      },
      {
        id: "tab2",
        name: "recommend-package",
        label: "แนะนำสำหรับคุณ",
      },
    ],
    isTabBarSlideActive = "tab1",
    width = "auto",
    height = "auto",
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    color = "textTertiary",
    colorActive = "primaryMain",
    backgroundColor = "white",
    backgroundColorActive = "secondaryMain",
  } = props;

  const callbackTabBarSlide = e => {
    callbackTabBarSlideOnChange(e);
  };

  return (
    <TabBarSlideStyle
      width={width}
      height={height}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      color={color}
      colorActive={colorActive}
      backgroundColor={backgroundColor}
      backgroundColorActive={backgroundColorActive}
    >
      <div className="tabs">
        <input
          type="radio"
          name="tab-bar-slide"
          id={option[0].id}
          value={isTabBarSlideActive}
          checked={isTabBarSlideActive === option[0].id}
        />
        <label
          htmlFor={option[0].id}
          className="tab"
          onClick={() => callbackTabBarSlide(option[0].id)}
          aria-hidden="true"
        >
          <span>
            <Image
              src={option[0].image}
              alt={option[0].value}
              width="auto" // string
              height="28px" // string
            />
            <Subtitle2
              color={option[0].id === isTabBarSlideActive ? colorActive : color}
            >
              {option[0].label}
            </Subtitle2>
          </span>
        </label>
        <input
          type="radio"
          name="tab-bar-slide"
          id={option[1].id}
          value={isTabBarSlideActive}
          checked={isTabBarSlideActive === option[1].id}
        />
        <label
          htmlFor={option[1].id}
          className="tab"
          onClick={() => callbackTabBarSlide(option[1].id)}
          aria-hidden="true"
        >
          <span>
            <Image
              src={option[1].image}
              alt={option[1].value}
              width="auto" // string
              height="28px" // string
            />
            <Subtitle2
              color={option[1].id === isTabBarSlideActive ? colorActive : color}
            >
              {option[1].label}
            </Subtitle2>
          </span>
        </label>
        <span className="selector" />
      </div>
    </TabBarSlideStyle>
  );
};

export default TabBarSlide;
