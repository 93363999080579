import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  article: [],
  articleOtherList: [],
};

export const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    setArticleField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
  },
});

// reducer
export const articleState = state => state.article;

// this action for dispatch
export const { setArticleField } = articleSlice.actions;

// this is for configureStore
export default articleSlice.reducer;
