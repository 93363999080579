/* eslint-disable no-unused-vars */
import { createContext, useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setModalAndMsgField } from "../../features/modal/modalSlice";
import { useAuth } from "./useProviderAuth";
import host from "../../config/host";

export const AxiosContext = createContext();

const useProviderAxios = () => {
  const navigate = useNavigate();
  const { signout, signin } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  let isRefreshToken = false;

  const changeLoading = (value = false) => {
    setIsLoading(value);
  };

  const axiosInstance = axios.create();

  const dispatch = useDispatch();

  const getRefreshToken = async param => {
    /* param: {refreshToken: "adsooo000808dsfdf"} */
    // eslint-disable-next-line no-useless-catch, no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${host.api}/auth/refresh-tokens`;
        const res = await axios.post(url, param);
        resolve(res.data);
      } catch (error) {
        // signout();
        reject(error);
      }
    });
    // try {
    //   const url = `${host.api}/auth/refresh-tokens`;
    //   const res = await axiosInstance.post(url, param);
    //   return res.data;
    // } catch (error) {
    //   throw error;
    // }
  };

  axiosInstance.interceptors.request.use(
    config => {
      setIsLoading(true);
      const token = process.env.REACT_APP_TOKEN || Cookies.get("token") || null;
      if (token != null) config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    error => {
      return error;
    },
  );

  axiosInstance.interceptors.response.use(
    response => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);

      return response;
    },
    async error => {
      const err = error.response;
      setTimeout(() => {
        setIsLoading(false);
      }, 500);

      if (err?.status === 400) {
        const { response: ErrResponse } = error || {};
        const { data: ErrResponseData } = ErrResponse || {};
        const { message: ErrResponseDataMessage = "" } = ErrResponseData || {};
        if (
          ErrResponseDataMessage === "Profile does not exist" ||
          err?.data?.message === "Profile does not exist"
        ) {
          navigate("/package");
        }
      }

      if (err?.status === 401 && !isRefreshToken) {
        isRefreshToken = true;

        try {
          const refreshToken = Cookies.get("refresh");
          if (refreshToken) {
            const response = await getRefreshToken({
              refreshToken,
            });
            const { access, refresh } = response || {};
            signin({
              token: access.token,
              refresh: refresh.token,
            });
            isRefreshToken = false;

            setTimeout(() => {
              window.location.reload();
            }, 15000);
            return Promise.reject(err);
            // return error;
          }
          signout();
          return Promise.reject(err);
        } catch (errors) {
          signout();
          return Promise.reject(errors);
        }
      }

      if (err?.status === 403) {
        if (
          err?.data?.message === "Your package has expired" ||
          err?.data?.message === "Member not subscribe package"
        ) {
          dispatch(
            setModalAndMsgField({
              key: "isOpenModalOverLimitPackage",
              value: true,
              message: err?.data?.message,
            }),
          );
        }
        // signout(() => Navigate("/package"));
      }

      if (err?.status === 500) {
        const errorMessage = new Error("Server Error");
        return Promise.reject(errorMessage);
      }

      return Promise.reject(err);
    },
  );

  return {
    axiosInstance,
    isLoading,
    changeLoading,
  };
};

const useAxios = () => {
  const { axiosInstance, isLoading } = useContext(AxiosContext) || {};
  return {
    axios: axiosInstance,
    isLoading,
  };
};

const AxiosProvider = ({ children }) => {
  const instanceRef = useProviderAxios();

  return (
    <AxiosContext.Provider value={instanceRef}>
      {children}
    </AxiosContext.Provider>
  );
};

export { AxiosProvider, useAxios, useProviderAxios };
