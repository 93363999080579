/* eslint-disable import/no-unresolved */
import { useState } from "react";
import IMG_CUP_RANDOM_NUMBER from "../../asset/images/img-cup-random-number.png";
// import IMG_CUP_RANDOM_NUMBER from "../../asset/images/img-cup-random-number.svg";
import IMG_TABLE_RANDOM_NUMBER from "../../asset/images/img-table-random-number.svg";
import BUBBLE_0 from "../../asset/images/bubble-0.svg";
import BUBBLE_1 from "../../asset/images/bubble-1.svg";
import BUBBLE_2 from "../../asset/images/bubble-2.svg";
import BUBBLE_3 from "../../asset/images/bubble-3.svg";
import BUBBLE_4 from "../../asset/images/bubble-4.svg";
import BUBBLE_5 from "../../asset/images/bubble-5.svg";
import BUBBLE_6 from "../../asset/images/bubble-6.svg";
import BUBBLE_7 from "../../asset/images/bubble-7.svg";
import BUBBLE_8 from "../../asset/images/bubble-8.svg";
import BUBBLE_9 from "../../asset/images/bubble-9.svg";
import useRandomLuckyNumber from "../../common/hooks/useRandomLuckyNumber";

const useLuckyNumber = () => {
  const optionBgLuckyNumber = [
    {
      id: "IMG_CUP_RANDOM_NUMBER",
      image: IMG_CUP_RANDOM_NUMBER,
    },
    {
      id: "IMG_TABLE_RANDOM_NUMBER",
      image: IMG_TABLE_RANDOM_NUMBER,
    },
  ];
  const optionLuckyNumber = [
    {
      id: "BUBBLE_0",
      value: 0,
      image: BUBBLE_0,
    },
    {
      id: "BUBBLE_1",
      value: 1,
      image: BUBBLE_1,
    },
    {
      id: "BUBBLE_2",
      value: 2,
      image: BUBBLE_2,
    },
    {
      id: "BUBBLE_3",
      value: 3,
      image: BUBBLE_3,
    },
    {
      id: "BUBBLE_4",
      value: 4,
      image: BUBBLE_4,
    },
    {
      id: "BUBBLE_5",
      value: 5,
      image: BUBBLE_5,
    },
    {
      id: "BUBBLE_6",
      value: 6,
      image: BUBBLE_6,
    },
    {
      id: "BUBBLE_7",
      value: 7,
      image: BUBBLE_7,
    },
    {
      id: "BUBBLE_8",
      value: 8,
      image: BUBBLE_8,
    },
    {
      id: "BUBBLE_9",
      value: 9,
      image: BUBBLE_9,
    },
  ];
  const [isLuckyNumberActive, setIsLuckyNumberActive] = useState(false);
  const initialStateLuckyNumber = {
    srt: "",
    arr: [],
    isLuckyOld: false,
  };
  const [luckyNumber, setLuckyNumber] = useState(initialStateLuckyNumber);

  const callbackLuckyNumberOnChange = () => {
    if (luckyNumber.arr.length <= 0 && !isLuckyNumberActive) {
      const lucky = useRandomLuckyNumber();
      setIsLuckyNumberActive(true);
      setLuckyNumber({
        srt: lucky.join(""),
        arr: lucky,
        isLuckyOld: false,
      });
    }
  };

  return {
    option: {
      optionBgLuckyNumber,
      optionLuckyNumber,
    },
    luckyNumber: luckyNumber || initialStateLuckyNumber,
    setLuckyNumber,
    initialStateLuckyNumber,
    isLuckyNumberActive,
    setIsLuckyNumberActive,
    callbackLuckyNumberOnChange,
  };
};

export default useLuckyNumber;
