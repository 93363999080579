import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  // CarouselIndicators,
  // CarouselCaption,
} from "reactstrap";
import styled from "styled-components";
import ImgEmptyWallpaper from "../../asset/images/Loading_Wallpaper.png";

const StyledCarouselItem = styled(CarouselItem)`
  &.carousel-item {
    height: 70vh;
    /* transition: none; */
    transition: ${({ isTransition }) =>
      isTransition ? "transform 0.5s ease-in-out" : "none"};
  }
`;

const StyledImage = styled.img`
  max-height: 90%;
  max-width: 75%;
`;

const StyledImageWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const ImageCarousel = ({ images, data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isTranstion, setIsTranstion] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    setIsTranstion(true);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    setIsTranstion(true);
  };

  useEffect(() => {
    setActiveIndex(data);
    return () => {};
  }, [data]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const slides = images.map(image => (
    <StyledCarouselItem
      onExiting={() => setAnimating(false)}
      onExited={() => setAnimating(false)}
      isTransition={isTranstion}
    >
      <StyledImageWrap>
        <StyledImage
          src={loading ? ImgEmptyWallpaper : image.src}
          alt={image.alt}
          onLoad={handleImageLoad}
          crossOrigin="anonymous"
        />
      </StyledImageWrap>
    </StyledCarouselItem>
  ));

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={false}
    >
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default ImageCarousel;
