// eslint-disable-next-line import/no-cycle
import { useAxios } from "../../common/hooks/useProviderAxios";
import host from "../../config/host";

const FormLoginProvider = () => {
  const { axios } = useAxios();
  const requestOtp = async param => {
    /* param: { "channel":"sms", "mobileNumber": "0801862145" } */
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host.api}/auth/request-otp`;
      const res = await axios.post(url, param);
      return res.data;
    } catch (error) {
      throw error;
    }
  };
  const verifyOtp = async param => {
    /* param: { "mobileNumber": "0801862145", "otp":"9355"} */
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host.api}/auth/verify-otp`;
      const res = await axios.post(url, param);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const getUserProfile = async param => {
    /* param: {profileId: 122} */
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host.api}/member/profile/${param.profileId}`;
      const res = await axios.get(url, param);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    requestOtp,
    verifyOtp,
    getUserProfile,
  };
};

export default FormLoginProvider;
