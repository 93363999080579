import React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Button from "./Button";
import TitleText from "./TitleText";
import ImgStarPageWhite from "../../asset/images/img-star-page-white.svg";
import ImgSunStarPageRight from "../../asset/images/img-sun-star-rigth.svg";
import ImgSunStarPageLeft from "../../asset/images/img-sun-star-left.svg";

const StyleModal = styled(Modal)`
  max-width: initial;
  width: ${props => props?.width};
  height: calc(${props => props?.height} - 56px);

  ${props => props?.zIndex && ` z-index: ${props?.zIndex}; `};

  @media screen and (max-width: 768px) {
    height: 100% !important;
  }

  .modal-content {
    border: none;
    overflow-y: hidden;
    height: ${props => (props?.isShowBg ? "100%" : "auto")};
    background-attachment: fixed fixed fixed;
    background-image: ${props =>
      props?.isShowBg
        ? `url(${ImgSunStarPageLeft}), url(${ImgStarPageWhite}),
      url(${ImgSunStarPageRight})`
        : ""};
    background-position:
      left -140px top -135px,
      center center,
      right -90px bottom;
    background-repeat: no-repeat;
    background-size: 300px, cover, 300px;

    @media screen and (max-width: 768px) {
      border-radius: 0px;
      height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    margin: 0;
  }

  span:has(#download_all) {
    width: 100%;
    height: 100%;
  }
`;

const StyleModalBody = styled.div`
  position: relative;
  min-height: ${props =>
    props?.labelWarning ? "calc(100% - 184px)" : "calc(100% - 136px)"};
  overflow-y: auto;
`;

const StyleContainerBody = styled.div`
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
`;

const StyleModalHeader = styled(ModalHeader)`
  width: 100%;
  border: none;
  position: relative;
  padding: 20px 16px;

  h5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const StyleTitleModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: ${props => props.theme.primaryFont};
`;

const StyleModalFooter = styled(ModalFooter)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  flex-wrap: inherit;
  justify-content: center;
  border-top: none;
  background-color: ${props => props.theme.white};

  button {
    max-width: 320px;
  }

  ${props => props?.labelWarning && `flex-direction: column ;`}
`;

const StyleIconClose = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 40px;
  margin: 0px;
`;

const StyleButton = styled(Button)`
  width: ${props => props?.btnWidth || "100%"};
  padding: ${props => props?.$paddingWallpaperDownload && 0};
  display: ${props => props.display || "block"};
`;

const ModalTemplate = props => {
  const {
    width,
    height,
    btnLeft,
    btnRight,
    btnRightOption = {},
    callbackBtnLeft,
    callbackBtnRight,
    children,
    isShowModal,
    callbackCloseModal,
    header,
    isShowBg,
    toggle,
    btnWidth = "100%",
    labelWarning = null,
    isShowIconClose = true,
    zIndex = null,
    isDownloadMultiple = true,
  } = props || {};

  return (
    <StyleModal
      isShowBg={isShowBg}
      isOpen={isShowModal}
      centered
      onClosed={callbackCloseModal}
      height={height}
      width={width}
      zIndex={zIndex}
    >
      {(header || isShowIconClose) && (
        <StyleModalHeader>
          <StyleTitleModal>
            {header && <TitleText label={header} theme="black" size="medium" />}
            {isShowIconClose && (
              <StyleIconClose className="icon-close" onClick={() => toggle()} />
            )}
          </StyleTitleModal>
        </StyleModalHeader>
      )}

      <StyleModalBody labelWarning={labelWarning}>
        <StyleContainerBody $height={height}>{children}</StyleContainerBody>
      </StyleModalBody>
      {(btnLeft || btnRight) && (
        <StyleModalFooter labelWarning={labelWarning}>
          {/* {labelWarning && labelWarning} */}
          {btnLeft && (
            <StyleButton
              type="cancel"
              handleClick={callbackBtnLeft}
              btnWidth={btnWidth}
            >
              {btnLeft}
            </StyleButton>
          )}
          {btnRight && (
            <StyleButton
              display={!isDownloadMultiple ? "none" : "block"}
              type="confirm"
              handleClick={callbackBtnRight}
              btnWidth={btnWidth}
              $paddingWallpaperDownload={!!btnRightOption.props}
            >
              {!!btnRightOption && !!btnRightOption.props
                ? btnRightOption
                : btnRight}
            </StyleButton>
          )}
        </StyleModalFooter>
      )}
    </StyleModal>
  );
};

export default ModalTemplate;
ModalTemplate.defaultProps = {
  width: "60%",
  height: "60vh",
  btnLeft: null,
  btnRight: null,
  callbackBtnLeft: null,
  callbackBtnRight: null,
  children: null,
  isShowModal: false,
  callbackCloseModal: () => {},
  header: "",
  isShowBg: true,
  toggle: () => {},
};
