/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ImageCarousel from "./ImageCarousel";
import { Caption, Subcaption, Subtitle1 } from "./Font";

const ModalCustom = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
    .modal-body {
    }
    .modal-footer {
      border-top: none;
    }

    &.backdrop-custom {
      .modal-backdrop {
        background-color: rgba(0, 0, 0, 0.8) !important;
      }
    }
  }
`;

const ButtonCustom = styled(Button)`
  background-color: ${props => props.theme.primaryMain};
  border: 0;
  color: ${props => props.theme.primaryDark600};
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  width: fit-content;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalPreviewImageDownload = ({ isOpen, toggleModal, images, data }) => {
  return (
    <ModalCustom
      isOpen={isOpen}
      fade={false}
      toggle={toggleModal}
      zIndex={1055}
      size="lg"
      backdropClassName="backdrop-custom"
    >
      <ModalBody>
        <ImageCarousel images={images} data={data} />
        <ButtonContainer>
          <ButtonWrap>
            <Caption color="white">
              กดค้างที่รูปเพื่อดาวน์โหลดวอลเปเปอร์
            </Caption>
            <ButtonCustom onClick={toggleModal}>
              <Subtitle1>ปิดหน้าต่าง</Subtitle1>
            </ButtonCustom>
          </ButtonWrap>
        </ButtonContainer>
      </ModalBody>
      <ModalFooter />
    </ModalCustom>
  );
};

export default ModalPreviewImageDownload;
