import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userProfile: {},
  zodiacInfo: {},
};

export const userProfileSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserField: (state, params) => {
      const { payload } = params;
      const { key, value } = payload;

      state[key] = value;
    },
  },
});

// reducer
export const userState = state => state.user;

// this action for dispatch
export const { setUserField } = userProfileSlice.actions;

// this is for configureStore
export default userProfileSlice.reducer;
