/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import CardWallpaper from "../../common/components/CardWallpaper";
import BgSelectWallpaper from "../../asset/images/bg-select-wallpaper.svg";
import ImgStarGroup from "../../asset/images/img-star-group.svg";
import { Subtitle2 } from "../../common/components/Font";
import Button from "../../common/components/Button";
import useWallpaperDownload from "./useWallpaperDownload";
import { useFetchMyPackage } from "../package/useFetchMyPackage";

const StyleWrapCard = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  background-image: url(${BgSelectWallpaper});
  background-repeat: repeat;
  background-size: contain;
  background-position: center top;
  padding: 24px 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 1046px;

  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 24px 20px;
    gap: 12px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 16px;
    gap: 40px 10px;
  }
`;

const StyleCardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const StyleImgStar = styled.img`
  width: 50px;
  height: 100%;
`;
const StyleGroupTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  align-items: center;
`;

const WallpaperDownload = ({
  handleModal = null,
  handleModalDownloadWallpaper = null,
}) => {
  const { getWallpaperDownloadList = null, wallpaperDownloadList = [] } =
    useWallpaperDownload();

  const callbackOpenModal = index => {
    handleModal(index);
  };

  const handleDownload = (index, isDownloaded) => {
    handleModalDownloadWallpaper(index, isDownloaded);
  };
  const { fetchMyPackages } = useFetchMyPackage();
  const { packageCurrent = {} } = useSelector(state => state.package);

  useEffect(() => {
    fetchMyPackages(true);
  }, []);

  useEffect(() => {
    if (packageCurrent?.id) {
      getWallpaperDownloadList();
    }
  }, [packageCurrent]);

  if (wallpaperDownloadList?.length === 0) {
    return null;
  }

  return (
    <StyleWrapCard list={wallpaperDownloadList?.length}>
      {wallpaperDownloadList &&
        wallpaperDownloadList.map((item, index) => {
          const isDownloaded = moment(item?.content?.publishDate).isAfter(
            moment(),
          );

          return (
            <StyleCardItem>
              <StyleGroupTitle>
                <Subtitle2>{item?.categoryName}</Subtitle2>
                <StyleImgStar src={ImgStarGroup} alt="star" />
              </StyleGroupTitle>
              <CardWallpaper
                index={index}
                callbackOpenModal={e => callbackOpenModal(e)}
                content={item?.content}
                isDownloaded={isDownloaded}
              />
              {!isDownloaded && item?.content !== null && (
                <Button
                  width="60%"
                  handleClick={() => handleDownload(index, item?.usedDate)}
                >
                  ดาวน์โหลด
                </Button>
              )}
            </StyleCardItem>
          );
        })}
    </StyleWrapCard>
  );
};

export default WallpaperDownload;
