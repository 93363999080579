import React from "react";
import { styled } from "styled-components";
import ModalTemplate from "./ModalTemplate";
import { Body1 } from "./Font";

const ContainrtStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding: 16px;

  img {
    width: 195px;
    height: auto;
  }
`;

const StyleMessage = styled(Body1)`
  text-align: center;
  width: 70%;
`;

const StyleChildren = styled.div`
  padding-top: 24px;
`;
const ModalConfirm = props => {
  const {
    isShowModal,
    toggle,
    icon,
    message,
    btnLeftText,
    btnRightText,
    callbackBtnLeft,
    callbackBtnRight,
    callbackCloseModal,
    children,
    width = "40%",
  } = props || {};
  return (
    <ModalTemplate
      isShowModal={isShowModal}
      width={width}
      height="auto"
      toggle={toggle}
      btnLeft={btnLeftText}
      btnRight={btnRightText}
      callbackCloseModal={callbackCloseModal}
      callbackBtnLeft={callbackBtnLeft}
      callbackBtnRight={callbackBtnRight}
      isShowBg={false}
    >
      <ContainrtStyle>
        <img src={icon} alt={message} />
        <StyleMessage>{message}</StyleMessage>

        {children && <StyleChildren>{children}</StyleChildren>}
      </ContainrtStyle>
    </ModalTemplate>
  );
};

ModalConfirm.defaultProps = {
  isShowModal: false,
  icon: null,
  message: null,
  btnLeftText: "ยกเลิก",
  btnRightText: "ตกลง",
  toggle: () => {},
  callbackBtnLeft: () => {},
  callbackBtnRight: () => {},
  children: null,
};

export default ModalConfirm;
