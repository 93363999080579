import { useAxios } from "../../common/hooks/useProviderAxios";
import host from "../../config/host";

const wallpaperDownloadProvider = () => {
  const { axios } = useAxios();

  const getWallpaperDownload = async ({ profileId = null }) => {
    try {
      const url = `${host?.api}/content/wallpaper-raw/profile/${profileId}`;
      const res = await axios.get(url);
      return res.data;
    } catch (ex) {
      const err = ex.response.data;
      throw err;
    }
  };

  const apiPostWallpaperPreview = async (params = null) => {
    try {
      const url = `${host?.api}/content/preview-wallpaper`;
      const res = await axios.post(url, params);
      return res.data;
    } catch (ex) {
      const err = ex.data;
      throw err;
    }
  };

  const apiPostWallpaperDownload = async (params = null) => {
    try {
      const url = `${host?.api}/content/download-wallpaper`;
      const res = await axios.post(url, params);
      return res.data;
    } catch (ex) {
      const err = ex.data;
      throw err;
    }
  };

  return {
    getWallpaperDownload,
    apiPostWallpaperPreview,
    apiPostWallpaperDownload,
  };
};

export default wallpaperDownloadProvider;
