import { useState } from "react";
import IMAGE_CURRENT_PACKAGE from "../../../asset/images/img-current-package.svg";
import IMAGE_RECOMMEND_PACKAGE from "../../../asset/images/img-star.svg";

const useTabBarSlide = () => {
  const optionTabBarSlide = [
    {
      id: "tab1",
      value: "current-package",
      label: "แพ็กเกจปัจจุบัน",
      image: IMAGE_CURRENT_PACKAGE,
    },
    {
      id: "tab2",
      value: "recommend-package",
      label: "แนะนำสำหรับคุณ",
      image: IMAGE_RECOMMEND_PACKAGE,
    },
  ];
  const [isTabBarSlideActive, setIsTabBarSlideActive] = useState("tab1");

  const callbackTabBarSlideOnChange = e => {
    setIsTabBarSlideActive(e);
  };

  return {
    option: optionTabBarSlide,
    isTabBarSlideActive,
    callbackTabBarSlideOnChange,
    setIsTabBarSlideActive,
  };
};

export default useTabBarSlide;
