import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImgBg from "../../asset/images/Loading_Wallpaper.png";
import ImgBgSmall from "../../asset/images/Loading_Detail.png";
import { useAxios } from "../hooks/useProviderAxios";
import ImgEmptyWallpaper from "../../asset/images/img-empty-wallpaper.png";

import { TooltipFont } from "./Font";

const StyleImageBorder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: ${props => (props.isConfirmModal ? "default" : "pointer")};
  height: 100%;
  min-height: 230px;
`;

const StyleBg = styled.div`
  width: ${props => (props?.type === "wallpaper" ? "235px" : "200px")};
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  justify-content: ${props => (props?.srcImage ? "center" : "flex-end")};
  align-items: center;
  background-image: url(${props =>
    props?.type === "wallpaper" ? ImgBg : ImgBgSmall});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 18px 0px;
  gap: 19px;
  min-height: ${props => (props?.type === "wallpaper" ? "476px" : "100%")};

  ${props =>
    props?.isShowImage &&
    `
    @media (max-width: 430px) {
      aspect-ratio: ${props?.type !== "wallpaper" ? "1/1" : "auto"};
  }
  `}
`;

const ImageStyle = styled.img`
  width: 80%;
  height: 100%;
  visibility: ${props => (props?.isShowImage ? "hidden" : "visible")};
`;

const ImageBorder = props => {
  // type wallpaper and content
  const {
    image = "",
    type = "wallpaper",
    label = "",
    crossorigin = null,
    onClick = () => {},
    isConfirmModal = true,
  } = props;

  const { axios } = useAxios();
  const [isImageExist, setIsImageExist] = useState(false);
  const [isImageNotfound, setIsImageNotfound] = useState(false);

  const fetchImage = async () => {
    try {
      const res = await axios.get(image);
      if (res?.status === 200) {
        setIsImageExist(true);
      }
    } catch (err) {
      setIsImageNotfound(true);
      console.log(err);
    }
  };

  useEffect(() => {
    if (crossorigin && image) {
      fetchImage();
    }
  }, [image]);

  let srcImage = "";
  if (isImageNotfound) {
    srcImage = ImgEmptyWallpaper;
  } else if (isImageExist) {
    srcImage = image;
  }

  return (
    <StyleImageBorder onClick={onClick} isConfirmModal={isConfirmModal}>
      <StyleBg
        type={type}
        srcImage={!!crossorigin}
        isShowImage={crossorigin ? srcImage === "" : image === ""}
      >
        <ImageStyle
          src={crossorigin ? srcImage : image}
          isShowImage={crossorigin ? srcImage === "" : image === ""}
          alt="img-bg"
          crossorigin={crossorigin}
        />
        {label && <TooltipFont color="white">{label}</TooltipFont>}
      </StyleBg>
    </StyleImageBorder>
  );
};

export default ImageBorder;
