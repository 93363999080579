export default {
  host: process.env.REACT_APP_API_HOST,
  api: process.env.REACT_APP_API_ENDPOINT,
  chat: process.env.REACT_APP_API_CHAT,
  imgDownloadWallpaper: "https://noonduang-api.saranros.com/latest",
  img: "https://cms.noonduang.com",
  avatar: "https://thumb.izcene.com/mcneto/300x300/",
  cover: "https://thumb.izcene.com/mcneto/1024x576/",
  banner: "https://thumb.izcene.com/mcneto/0x0/",
  image: "https://thumb.izcene.com/mcneto/",
  navLogo: "https://thumb.izcene.com/mcneto/0x200/",
  imagePreview: "https://thumb.izcene.com/mcneto/200x0/webp/",
  video: "https://cms.noonduang.com",
  secretKey: process.env.REACT_APP_SECRET_KEY,
  serviceKey: process.env.REACT_APP_SERVICE_KEY,
  apiMedia: process.env.REACT_APP_API_MEDIA,
  tokenSecretKey: process.env.REACT_APP_TOKEN_SECRET_KEY,
  streamUrl: process.env.REACT_APP_STREAM_URL,
  liveChat: process.env.REACT_APP_LIVECHAT_API,
  socket: process.env.REACT_APP_LIVECHAT_SOCKET,
  clientKey: process.env.REACT_APP_CLIENT_KEY,
  defaultDomain: process.env.REACT_APP_DEFAULT_DOMAIN,
  unsplashAccessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
  assetIcon: "https://thumb.izcene.com/mcneto/50x50/",
  assetIconPayment: "https://thumb.izcene.com/mcneto/120x0/",
  imageCms: "https://cms.noonduang.com/",
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjksImlhdCI6MTcwNDk0ODEzOSwiZXhwIjoxNzA1MDM0NTM5LCJ0eXBlIjoibWVtYmVyIiwidG9rZW5UeXBlIjoiYWNjZXNzIn0.7w5xvaT9oBeCIciHRaPlMSe-wTtLGHVVwES9lqVwM8w",
};
