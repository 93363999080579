import styled from "styled-components";
// import BGHoroDiary from "../../asset/images/background-horo-daily.png";
import { useLocation, useNavigate } from "react-router-dom";
import BGHoroDiary from "../../asset/images/bg-article.svg";
import { ReactComponent as ImageHealth } from "../../asset/images/img-card-health.svg";
import { ReactComponent as ImageLove } from "../../asset/images/img-card-love.svg";
import { ReactComponent as ImageMoney } from "../../asset/images/img-card-money.svg";
import { ReactComponent as ImageWork } from "../../asset/images/img-card-work.svg";
import { Body2, Subtitle2 } from "./Font";

const BackgroundTest = styled.div`
  @media (max-width: 430px) {
    width: 100%;
  }
`;

const ArticalTab = styled.div`
  /* position: absolute; */
  margin-top: 20px;
  width: 500px;
  max-width: 500px;
  height: 108px;
  padding: 8px 16px;
  /* background: ${props => props.theme.secondaryLight300}; */
  /* opacity: 0.5; */
  background-image: url(${BGHoroDiary});
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-size: cover;
  align-items: center;
  border-radius: 5px;
  @media (max-width: 430px) {
    width: 100%;
    gap: 4px;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const ItemArticle = styled.div`
  cursor: pointer;
  width: 80px;
  height: 80px;
  /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); */
  background: "transparent";
  padding: 10px 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: red; */
  background-color: ${props =>
    props.isActive ? "rgba(28, 36, 83, 1)" : "transparent"};
  box-shadow: ${props =>
    props.isActive
      ? "0px 0px 12px 0px rgba(176, 131, 34, 0.40)"
      : "transparent"};
  border: ${props => (props.isActive ? "0.3px solid #EEDEBC" : "0")};
  & {
    svg {
      transition: all 0.35s ease-out;
      transform: scale(1);
      filter: brightness(1);
    }
  }
  &:hover {
    svg {
      transition: all 0.35s ease-out;
      filter: brightness(1.5);
      transform: scale(1.3);
    }
  }
`;

const ContentWrap = styled.div`
  margin-top: 20px;
  background-image: url(${BGHoroDiary});
  width: 500px;
  min-height: 320px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  padding: 40px 16px;
  white-space: break-spaces;

  @media (max-width: 430px) {
    width: 100%;
  }
`;
const CardPost = props => {
  const { options, tabActive, callbackClickTab, contentHoro } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onChangeTab = tab => {
    callbackClickTab(tab);

    // update queryString
    const path = `${pathname}?${tab}`;
    navigate(path);
  };

  return (
    <>
      <BackgroundTest>
        <ArticalTab>
          {options.map(item => (
            <ItemArticle
              isActive={item.value === tabActive}
              onClick={() => onChangeTab(item.value)}
            >
              {item.img}
              <Subtitle2 color="primaryMain">{item?.label}</Subtitle2>
            </ItemArticle>
          ))}
        </ArticalTab>
      </BackgroundTest>

      <ContentWrap>
        <Body2 color="white">{contentHoro}</Body2>
      </ContentWrap>
    </>
  );
};

CardPost.defaultProps = {
  options: [
    {
      label: "การงาน",
      value: "work",
      img: <ImageWork />,
    },
    {
      label: "การเงิน",
      value: "money",
      img: <ImageMoney />,
    },
    {
      label: "ความรัก",
      value: "love",
      img: <ImageLove />,
    },
    {
      label: "สุขภาพ",
      value: "health",
      img: <ImageHealth />,
    },
  ],
  tabActive: "work",
  callbackClickTab: tab => console.log(`${tab}<3`),
  contentHoro: "",
};

export default CardPost;
