import React from "react";
import styled from "styled-components";
import { Subtitle1 } from "./Font";

const getColor = (type, disabled, theme) => {
  switch (type) {
    case "confirm":
      return {
        backgroundColor: disabled ? "#CBCBCB" : theme?.primaryMain,
        color: disabled ? "#A3A3A3" : theme.secondaryDark600,
        border: "none",
      };
    case "cancel":
      return {
        backgroundColor: theme?.white,
        color: disabled ? "#CACACA" : theme?.textPrimaryB,
        border: disabled
          ? "solid 1px #CACACA"
          : `solid 1px ${theme?.actionIcon}`,
      };
    case "download":
      return {
        backgroundColor: theme?.secondaryMain,
        color: theme?.white,
        border: "none",
      };
    default:
      return {
        backgroundColor: theme?.primaryMain,
        color: theme?.secondaryDark600,
        border: "none",
      };
  }
};

const ButtonStyle = styled.button`
  padding: ${props => props.paddingTop || 8}px
    ${props => props.paddingRight || 8}px ${props => props.paddingBottom || 8}px
    ${props => props.paddingLeft || 8}px;
  font-size: 14px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  border-radius: ${props =>
    props?.borderRadius ? props?.borderRadius : "10px"};
  width: ${props => (props?.width ? props?.width : "100%")};
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  /* cursor: pointer; */
  ${props => getColor(props.$type, props.disabled, props?.theme)}
  &:hover {
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    filter: brightness(${props => (props?.isBrightness ? 1.1 : 1)});
  }
`;

const Button = props => {
  const {
    type,
    children,
    disabled = false,
    handleClick,
    width = "100%",
    borderRadius = "10px",
    paddingTop = 8,
    paddingBottom = 8,
    paddingLeft = 8,
    paddingRight = 8,
    isBrightness = true,
    className = "",
    typeButton = "",
  } = props;

  return (
    <ButtonStyle
      $type={type}
      disabled={disabled}
      onClick={() => handleClick()}
      width={width}
      borderRadius={borderRadius}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      isBrightness={isBrightness}
      type={typeButton}
      className={className}
    >
      <Subtitle1>{children}</Subtitle1>
    </ButtonStyle>
  );
};

Button.defaultProps = {
  borderRadius: "10px",
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 8,
  paddingRight: 8,
  isBrightness: true,
  type: "" /* button type (string) : "primary", "secoundary" */,
  children: "" /* element children (element) : <span>บันทึก<span> */,
  disabled: false /* disabled button (boolean) : true, false */,
  handleClick: () => {} /* callback function on click (function) : () => {} */,
};
export default Button;
