/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
const usePackageConfig = config => {
  const {
    wallpaperDownload = {},
    luckyNumber = {},
    lotto = {},
    horoscopeMonthly = {}, // horoscopeMonthly,
    videoNoonduang = {},
    tipsNoonduang = {}, // tipsNoonduang,
  } = config || {};
  const { quotaLimit = {}, detail = {} } = wallpaperDownload || {};
  const { month = 0 } = quotaLimit || {};
  const { aura = null } = detail || {};

  const packageKey = {
    lucky: {
      serviceCode: 455240603,
      price: 59,
    },
    victory: {
      serviceCode: 455240604,
      price: 69,
    },
    success: {
      serviceCode: 455240605,
      price: 99,
    },
    fully: {
      serviceCode: 455240606,
      price: 159,
    },
    enhance: {
      serviceCode: 455240607,
      price: 199,
    },
  };

  const detailConfig = [
    `${month} วอลเปเปอร์`, // Note case1 :: All
    `ฤกษ์มงคลติดตั้งวอลเปเปอร์`, // Note case2 :: All
    `คําอธิบายวอลเปเปอร์`, // Note case3 :: All
    `เลขมงคล 3 หลัก และเลขสวย เลขเด็ด`, // Note case4 :: luckyNumber && lotto ====> (luckyNumber.access !== "preview" && lotto.access !== "preview")
    `ดวงรายเดือน`, // Note case5 :: horoscopeMonthly ====> (horoscopeMonthly.access !== "preview")
    `สวดหนุนดวงประจําวันเกิด`, // Note case6 :: videoNoonduang ====> (videoNoonduang.access !== "preview")
    `เคล็ดลับเสริมดวง`, // Note case7 :: tipsNoonduang ====> (tipsNoonduang.access !== "preview")
    `ออร่าเสริมพลังวอลเปเปอร์`, // Note case8 :: ====> (XXX.access !== "preview")
  ];

  // eslint-disable-next-line consistent-return
  const caseConfig = (item, index) => {
    switch (index) {
      case 1:
        return item;
        break;
      case 2:
        return item;
        break;
      case 3:
        return item;
        break;
      case 4:
        return luckyNumber.access !== "preview" && lotto.access !== "preview"
          ? item
          : undefined;
        break;
      case 5:
        return horoscopeMonthly.access !== "preview" ? item : undefined;
        break;
      case 6:
        return videoNoonduang.access !== "preview" ? item : undefined;
        break;
      case 7:
        return tipsNoonduang.access !== "preview" ? item : undefined;
        break;
      case 8:
        return aura === "display" ? item : undefined;
        break;
      default:
        break;
    }
  };

  return [...detailConfig]
    .map((item, index) => {
      return caseConfig(item, index + 1);
    })
    .filter(Boolean);
};

export default usePackageConfig;
