import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../common/components/Button";
import { Subtitle1 } from "../../common/components/Font";
import ImgLoading from "../../asset/images/loading-without-border.png";

const StyleIphoneMockup = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 4.06px 4.06px 12.18px 0px rgba(0, 0, 0, 0.21);
  background-image: ${props => props?.theme?.otherGoldB};
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 1px;
  transition: transform 0.3s ease;

  ${props => props?.isDisabled && `cursor: default !important;`}

  @media (max-width: 430px) {
    height: 100%;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(240, 189, 90, 0.8) !important;
    button {
      filter: brightness(1.1);
    }
  }
`;

const ImageStyle = styled.img`
  color: transparent;
  width: 203px;
  height: 430px;
  border-radius: 10px;

  @media (max-width: 430px) {
    width: auto;
    height: 350px;
  }
`;

const StyleButton = styled.div`
  width: 80%;
  border-radius: 10px;
  position: absolute;
  bottom: 86px;

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-image: ${props => props?.theme?.otherGoldC};
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 1px;

  button {
    background-color: #18476f;
  }
`;

const IphoneMockup = props => {
  const { image = "", callbackDownload = null } = props;

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  const handleImageLoad = e => {
    setIsImageLoaded(true);

    const { alt = "" } = e.target;
    if (alt === "img-card-item") {
      setIsShowBtn(true);
    }
  };

  useEffect(() => {
    if (isImageLoaded) {
      setImgSrc(image);
    }
  }, [isImageLoaded]);

  return (
    <StyleIphoneMockup
      isDisabled={!isShowBtn}
      onClick={() => isShowBtn && callbackDownload()}
    >
      <ImageStyle
        src={imgSrc || ImgLoading}
        alt={imgSrc ? "img-card-item" : "img-loading"}
        id="card"
        onLoad={e => handleImageLoad(e)}
      />

      {isShowBtn && (
        <StyleButton>
          <Button type="download" isBrightness={false}>
            <Subtitle1>ดาวน์โหลด</Subtitle1>
          </Button>
        </StyleButton>
      )}
    </StyleIphoneMockup>
  );
};

export default IphoneMockup;
