/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { SubTooltipFont, TooltipFont } from "./Font";
import BgWallpaper from "../../asset/images/img-bg-wallpaper.png";
import BgDownloaded from "../../asset/images/bg-downloaded.png";
import IconDownloaded from "../../asset/images/icon-downloaded.svg";
import ImgEmptyWallpaper from "../../asset/images/img-empty-wallpaper.png";

const StyleCardWallpaper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  position: relative;
  width: 100%;
  max-height: 446px;
  padding: 24px;
  border-radius: 10px;
  cursor: ${props => (props?.isNoAction ? "default" : "pointer")};
  background-image: url(${props => props?.bg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  transition: transform 0.8s ease;

  &:hover {
    transform: scale(1.03);
  }

  @media screen and (max-width: 768px) {
    padding: 16px;
    max-width: 211px;
  }
`;

const StyleImage = styled.img`
  width: 100%;
  max-width: 147px;
  height: 320px;
  box-shadow: 0px 0px 18px 0px #000;
`;

const StyleTextContent = styled(TooltipFont)`
  display: flex;
  text-align: center;
  line-height: 14px;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  padding: 0px 16px;
  word-break: break-word;
`;

const StyleSeeMore = styled(SubTooltipFont)`
  cursor: pointer;
`;

const StyleDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;

  .icon-star {
    color: white;
    font-size: 4px;
  }
`;
const StyleTextDownloaded = styled(TooltipFont)`
  width: auto;
  max-width: 122px;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-align: center;
`;

const StyleImageDownloaded = styled.img`
  width: 100%;
  height: auto;
  max-width: 150px;
`;

const StyleTextEmptyWallpaper = styled(TooltipFont)`
  color: ${props => props?.theme?.primaryMain};
  text-align: center;
`;

const StyleCoverEmptyWallpaper = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const CardWallpaper = props => {
  const {
    index = null,
    callbackOpenModal = null,
    content = {},
    isDownloaded = false,
  } = props;

  const {
    publishDate = "",
    description = "",
    previewImage = "",
  } = content || {};

  const renderCardContent = () => {
    if (content === null) {
      return (
        <>
          <div>
            <StyleImage src={ImgEmptyWallpaper} alt="img-no-wallpaper" />
          </div>
          <StyleCoverEmptyWallpaper>
            <StyleTextEmptyWallpaper>
              คุณได้รับการหนุนดวงจากวอลเปเปอร์มงคลนี้แล้ว
            </StyleTextEmptyWallpaper>
          </StyleCoverEmptyWallpaper>
        </>
      );
    }
    if (isDownloaded) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "407px",
            width: "100%",
            gap: "16px",
          }}
        >
          <StyleImageDownloaded src={IconDownloaded} alt="img-downloaded" />
          <StyleTextDownloaded color="primaryMain">
            คุณได้รับการหนุนดวงจากวอลเปเปอร์มงคลนี้แล้ว
          </StyleTextDownloaded>
          {/* <StyleDivider>
            <div className="icon-star" />
            <StyleTextDownloaded color="white">
              {moment(publishDate).format("DD/MM/YYYY")}
            </StyleTextDownloaded>
            <div className="icon-star" />
          </StyleDivider> */}
        </div>
      );
    }
    return (
      <>
        <div>
          <StyleImage
            src={`https://cms.noonduang.com/${previewImage}`}
            alt="img-wallpaper"
            isDownloaded={isDownloaded}
          />
        </div>

        <StyleCoverEmptyWallpaper>
          {description && (
            <StyleTextContent color="primaryMain">
              {description?.slice(0, 200)}
            </StyleTextContent>
          )}
          <StyleSeeMore color="white">อ่านเพิ่มเติม</StyleSeeMore>
        </StyleCoverEmptyWallpaper>
      </>
    );
  };

  return (
    <StyleCardWallpaper
      onClick={() =>
        isDownloaded || content === null ? null : callbackOpenModal(index)
      }
      isNoAction={isDownloaded || content === null}
      bg={isDownloaded ? BgDownloaded : BgWallpaper}
    >
      {renderCardContent()}
    </StyleCardWallpaper>
  );
};

export default CardWallpaper;
