import React from "react";
import styled from "styled-components";
import { Body2, Subcaption, Subtitle2 } from "./Font";
import ImageStarGroup from "../../asset/images/img-star-group.svg";
import LockComponent from "./LockComponent";
import DangerousHTMLComponent from "./DangerousHTML";

const StyleCardMonthly = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 40px 16px;
  gap: 32px;

  background-image: url(${props => props?.background});
  background-repeat: no-repeat;
  background-size: cover;
  & {
    img:first-child {
      transition: all 0.5s ease-out;
      transform: scale(1);
    }
  }
  &:hover {
    img:first-child {
      transition: all 0.5s ease-out;
      filter: brightness(1.45);
      transform: scale(1.1);
    }
  }
`;

const StyleImg = styled.img`
  width: 70%;
`;

const StyleCoverText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  text-align: center;
  justify-content: center;
  gap: 15px;
  width: 100%;

  @media (max-width: 430px) {
    max-width: 100%;
  }
`;

const StyleText = styled(Body2)`
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const StyleSeeMore = styled(Subcaption)`
  cursor: pointer;
`;

const WrapTextContent = styled.div`
  padding: 16px;
  /* background-color: ${props => props.theme.primaryDark600}; */
  height: 100px;
  width: 100%;
`;

const CardMonthly = props => {
  const {
    label = "การงาน",
    background = null,
    image = null,
    handleSeeMore = null,
    content = "",
  } = props;

  return (
    <LockComponent feature="horoscopeMonthly">
      <StyleCardMonthly background={background}>
        <StyleImg src={image} alt="img" />
        <StyleCoverText>
          <Subtitle2 color="primaryMain">{label}</Subtitle2>
          <img src={ImageStarGroup} alt="img-star" />
          <WrapTextContent>
            <StyleText color="white">
              {DangerousHTMLComponent({
                htmlContent: content,
              })}
            </StyleText>
          </WrapTextContent>
        </StyleCoverText>
        <StyleSeeMore color="white" onClick={() => handleSeeMore()}>
          ดูเพิ่มเติม
        </StyleSeeMore>
      </StyleCardMonthly>
    </LockComponent>
  );
};

export default CardMonthly;
