/* eslint-disable import/no-cycle */
import { styled } from "styled-components";
import moment from "moment";
// eslint-disable-next-line import/no-cycle
import { useState } from "react";
import { ReactComponent as IconApple } from "../../asset/images/icon-apple.svg";
import { ReactComponent as IconAndroid } from "../../asset/images/icon-android.svg";
import ExpandableBox from "../../common/components/ExpandableBox";
import ModalPreviewImageDownload from "../../common/components/ModalPreviewImageDownload";
import { StyleTextWarning } from "../modalWallpaperDetail/ModalWallpaperDownload";
import BgSelectWallpaper from "../../asset/images/bg-select-wallpaper.svg";
import IconStar from "../../asset/images/icon-star-black.svg";
import Button from "../../common/components/Button";
import {
  Body1,
  Caption,
  Subcaption,
  Subtitle1,
} from "../../common/components/Font";
import ImageBorder from "../../common/components/ImageBorder";
import SeparateLine from "../../common/components/SeparateLine";
import host from "../../config/host";

const StyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 16px 0px;

  img.wallpaper-image {
    width: 255px;
    height: 330px;
    margin: 20px 0;
  }

  .wallpaper-line {
    width: 100%;
    padding: 8px 0px;
  }
`;

const StyleUl = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  padding: 0px;
  width: 100%;
  margin-top: 15px;
`;

const StyleLi = styled.li`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  &::before {
    content: ${IconStar};
    display: block;
    width: 12px;
    height: 12px;
    margin: 10px 10px 0 10px;
  }
`;

const StyleBgImgage = styled.div`
  background-image: url(${BgSelectWallpaper});
  background-size: cover;
  padding: 13px 54px;
  border-radius: 10px;
  display: flex;
  height: 100%;
  gap: 20px;

  img {
    object-fit: cover;
  }

  @media (max-width: 430px) {
    flex-wrap: wrap;
  }
`;

const StyleSmallImg = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  gap: 27px;
  width: 100%;
  height: 100%;

  @media (max-width: 430px) {
    flex-direction: row;
    gap: 20px;
  }

  div {
    @media (max-width: 430px) {
      width: 100%;
      height: 100%;
    }
  }

  img {
    object-fit: contain;
    aspect-ratio: 1/1;
  }
`;

const StyleBgContent = styled.div`
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
`;

const StyleContent = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media (max-width: 430px) {
    padding: 24px 16px !important;
  }
`;

const StyleSeparateLine = styled(SeparateLine)`
  .icon-star {
    font-size: 12px;
  }
`;

const StyleButtonDownloadWallpaper = styled.input`
  all: inherit;
  text-align: center;
  padding: 0rem 2rem;
`;

const StyleButton = styled(Button)`
  width: fit-content;
  margin: 0.5rem auto;
  padding: 0;
  display: ${props => props.display || "none"};

  span:has(#download_manual, #download_wall, #download_install) {
    width: 100%;
    height: 100%;
    min-height: inherit;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailHowtoContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
`;
const ContentHowtoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalWallpaper = props => {
  const [modalPreview, setModalPreview] = useState(null);
  const {
    packageName = "",
    imgWallpaper = "",
    imgInstallation = "",
    imgManual = "",
    description = "",
    crossorigin = null,
    isDownloadMultiple = true,
    codeWallpaper = null,
    expireDate = "",
    isConfirmModal = true,
  } = props;

  const content = [
    {
      label: "วอลเปเปอร์",
    },
    {
      label: "ฤกษ์มงคลติดตั้งวอลเปเปอร์",
    },
    {
      label: "คำอธิบายวอลเปเปอร์",
    },
  ];

  const handlePreview = data => {
    if (!isConfirmModal) {
      setModalPreview({
        isOpen: true,
        data,
      });
    }
  };

  const detectDevice = () => {
    const { userAgent } = navigator;
    if (userAgent.match(/Window/i)) {
      return "window";
    }
    if (userAgent.match(/Android/i)) {
      return "android";
    }
    if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return "ios";
    }
    return "other";
  };

  return (
    <StyContainer>
      <Subtitle1>{packageName}</Subtitle1>
      <StyleBgImgage>
        <Row>
          <ImageBorder
            image={imgWallpaper}
            label="วอลเปเปอร์"
            crossorigin={crossorigin}
            onClick={() => handlePreview(0)}
            isConfirmModal={isConfirmModal}
          />
          <StyleButton
            type="confirm"
            display={isDownloadMultiple ? "none" : "block"}
            callbackBtnRight={() => null}
          >
            <StyleButtonDownloadWallpaper
              type="button"
              nd-target={host?.imgDownloadWallpaper}
              nd-id={codeWallpaper}
              id="btn-download_wall"
              name="downloadWall"
              value="ดาวน์โหลด"
            />
          </StyleButton>
        </Row>
        <StyleSmallImg>
          <Row>
            <ImageBorder
              image={imgInstallation}
              type="content"
              label="ฤกษ์ปรับวอลเปเปอร์"
              crossorigin={crossorigin}
              onClick={() => handlePreview(1)}
              isConfirmModal={isConfirmModal}
            />

            <StyleButton
              type="confirm"
              display={isDownloadMultiple ? "none" : "block"}
              callbackBtnRight={() => null}
            >
              <StyleButtonDownloadWallpaper
                type="button"
                nd-target={host?.imgDownloadWallpaper}
                nd-id={codeWallpaper}
                id="btn-download_install"
                name="downloadInstall"
                value="ดาวน์โหลด"
              />
            </StyleButton>
          </Row>
          <Row>
            <ImageBorder
              image={imgManual}
              type="content"
              label="คำอธิบายวอลเปเปอร์"
              crossorigin={crossorigin}
              onClick={() => handlePreview(2)}
              isConfirmModal={isConfirmModal}
            />
            <StyleButton
              type="confirm"
              display={isDownloadMultiple ? "none" : "block"}
              callbackBtnRight={() => null}
            >
              <StyleButtonDownloadWallpaper
                type="button"
                nd-target={host?.imgDownloadWallpaper}
                nd-id={codeWallpaper}
                id="btn-download_manual"
                name="downloadManual"
                value="ดาวน์โหลด"
              />
            </StyleButton>
          </Row>
        </StyleSmallImg>
      </StyleBgImgage>

      <StyleContent>
        {!isConfirmModal && (
          <>
            <ExpandableBox
              isOpenDefault={detectDevice() === "ios"}
              header={
                <DetailHowtoContainer>
                  <Caption>สำหรับ iPhone, iPad</Caption>
                  <IconApple />
                </DetailHowtoContainer>
              }
              content={
                <ContentHowtoContainer>
                  <Caption>1. กดรูปภาพเพื่อเปิดภาพพรีวิว</Caption>
                  <Caption>2. กดค้างที่รูปภาพ</Caption>
                  <Caption>3. เลือก Save in Photos</Caption>
                  <Caption>4. รูปภาพจะถูกจัดเก็บอยู่ที่แอป Photos </Caption>
                  <br />
                  <Caption>สามารถกดปุ่มดาวน์โหลดทั้งหมดที่ด้านล่าง</Caption>
                  <Caption>
                    รูปภาพจะถูกจัดเก็บอยู่ที่แอป Files อัลบั้ม Downloads
                  </Caption>
                </ContentHowtoContainer>
              }
            />
            <ExpandableBox
              isOpenDefault={detectDevice() === "android"}
              header={
                <DetailHowtoContainer>
                  <Caption>สำหรับ Android</Caption>
                  <IconAndroid />
                </DetailHowtoContainer>
              }
              content={
                <ContentHowtoContainer>
                  <Caption>1. กดรูปภาพเพื่อเปิดภาพพรีวิว</Caption>
                  <Caption>2. กดค้างที่รูปภาพ</Caption>
                  <Caption>3. เลือก Download Image</Caption>
                  <Caption>4. รูปภาพจะถูกจัดเก็บอยู่ที่แอป Gallery </Caption>
                  <br />
                  <Caption>สามารถกดปุ่มดาวน์โหลดทั้งหมดที่ด้านล่าง</Caption>
                  <Caption>
                    รูปภาพจะถูกจัดเก็บอยู่ที่ Gallery อัลบั้ม Downloads
                  </Caption>
                </ContentHowtoContainer>
              }
            />
          </>
        )}
        <Subtitle1>รายละเอียดคำอธิบาย</Subtitle1>
        <StyleBgContent>
          <Body1>{description}</Body1>
        </StyleBgContent>
        <div className="wallpaper-line">
          <StyleSeparateLine width="70%" />
        </div>
        <Subtitle1>สิ่งที่ท่านจะได้รับ</Subtitle1>
        <StyleUl>
          {[...content].map(item => {
            return (
              <StyleLi>
                <img src={IconStar} alt="number-3" />
                <Body1 color="black">{item.label}</Body1>
              </StyleLi>
            );
          })}
        </StyleUl>
      </StyleContent>
      {!isConfirmModal && (
        <StyleTextWarning>
          <div className="icon-star" />
          <Subcaption>
            ดาวน์โหลดได้ถึงวันที่ {moment(expireDate).format("DD/MM/YYYY")}
          </Subcaption>
          <div className="icon-star" />
        </StyleTextWarning>
      )}

      <ModalPreviewImageDownload
        data={modalPreview?.data}
        isOpen={modalPreview?.isOpen}
        images={[
          {
            src: imgWallpaper,
            alt: "1",
            caption: "1",
          },
          {
            src: imgInstallation,
            alt: "2",
            caption: "2",
          },
          {
            src: imgManual,
            alt: "3",
            caption: "3",
          },
        ]}
        toggleModal={() =>
          setModalPreview({
            isOpen: false,
            data: null,
          })
        }
      />
    </StyContainer>
  );
};

export default ModalWallpaper;
