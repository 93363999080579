import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Button from "../../common/components/Button";
import InputText from "../../common/components/InputText";
import FormLoginProvider from "./formLoginProvider";
import { useAuth } from "../../common/hooks/useProviderAuth";
import { logout } from "./formLoginSlice";

const LoginForm = styled.form`
  max-width: 387px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 0px 16px;
  margin-top: 16px;

  @media (max-width: 430px) {
    width: 100%;
    padding: 0px 8px;
  }
`;

const StyleCoverPhoneNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${props => (props?.errMsg ? "center" : "flex-end")};
  gap: 16px;
`;

const StyleButton = styled(Button)`
  margin-top: 24px;
  height: 44px;
`;

const StyleButtonOTP = styled(Button)`
  margin-top: 14px;
  height: 44px;
  min-width: 135px;

  span {
    font-size: ${props => props?.theme?.subCaptionSize};
  }
`;

const FormLogin = () => {
  const dispatch = useDispatch();

  const { requestOtp, verifyOtp } = FormLoginProvider() || {};
  const { signin } = useAuth();
  // const VALUE_OTP = "111111";
  const navigate = useNavigate();

  const [time, setTime] = useState(0);
  const [isFinish, setIsFinish] = useState(true);

  const form = useForm({
    defaultValues: {
      phoneNumber: "",
      otp: "",
    },
  });

  const {
    clearErrors,
    handleSubmit,
    resetField,
    getValues,
    setError,
    formState: { errors, isValid },
  } = form;

  // eslint-disable-next-line no-unused-vars
  const { phoneNumber: phoneNumberLogin, otp: otpLogin } = getValues();

  const checkLoginIsExist = () => {
    const isLogin = Cookies.get("token");
    if (isLogin) navigate("/package");
  };

  useEffect(() => {
    checkLoginIsExist();
    resetField();
    clearErrors();
    dispatch(logout());
  }, []);

  /* ----------------------------------- API ---------------------------------- */
  async function fetchRequestOtp() {
    try {
      const { phoneNumber } = getValues();
      await requestOtp({
        mobileNumber: phoneNumber,
      });
      clearErrors("");
    } catch (error) {
      const { message, code } = error?.data || {};
      if (code === 400) {
        if (message === "OTP request over limit") {
          setError("otp", {
            type: "custom",
            message:
              "คุณขอ OTP ครบจำนวนที่กำหนดแล้ว สามารถขอ OTP ใหม่ได้อีกครั้ง หลังเวลา 23:59 น.",
          });
        }
      }
    }
  }

  async function fetchVerifyOtp() {
    try {
      const { phoneNumber, otp } = getValues();
      const response = await verifyOtp({
        mobileNumber: phoneNumber,
        otp,
      });

      /* keep data to access token and refresh token cookie */
      signin(
        {
          token: response.tokens.access.token,
          refresh: response.tokens.refresh.token,
        },
        () => {},
      );

      return true;
    } catch (error) {
      const { message, code } = error?.data || {};
      if (code === 400) {
        if (message === "OTP expired") {
          setError("otp", {
            type: "custom",
            message: "กรุณากรอกรหัส OTP 6 ให้ทันเวลาที่กำหนด",
          });
        }
        if (message === "OTP does not exist") {
          setError("otp", {
            type: "custom",
            message: "กรุณากรอกรหัส OTP 6 หลักให้ถูกต้อง",
          });
        }
      }
      return false;
    }
  }

  // function countdown request otp
  const countdown = (seconds = 30) => {
    let timeCountdown = seconds + 1;

    const timer = setInterval(() => {
      if (timeCountdown <= 0) {
        clearInterval(timer);
        setIsFinish(true);
      } else {
        timeCountdown -= 1;
        setTime(timeCountdown);
        setIsFinish(false);
      }
    }, 1000);
  };

  const onRedirect = () => {
    if (isValid) {
      navigate("/package");
    }
  };

  const onRequestOtp = () => {
    const phoneNumber = getValues("phoneNumber");
    if (phoneNumber?.length === 10) {
      fetchRequestOtp();
      countdown(30);
      clearErrors("phoneNumber");
    } else {
      setError("phoneNumber", {
        type: "custom",
        message:
          phoneNumber?.length === 0
            ? "กรุณากรอกเบอร์มือถือ"
            : "กรุณากรอกเบอร์มือถือ AIS ใหม่อีกครั้ง",
      });
    }
  };

  // const isCheckOtp = otp => {
  //   return otp.toString() === VALUE_OTP;
  // };

  const onFormSubmit = async () => {
    try {
      const isVerify = await fetchVerifyOtp();
      if (isVerify) onRedirect();
    } catch (error) {
      console.log(`error:`, error);
    }
  };

  return (
    <LoginForm onSubmit={handleSubmit(onFormSubmit)}>
      <StyleCoverPhoneNumber errMsg={errors?.phoneNumber?.message}>
        <InputText
          name="phoneNumber"
          label="เบอร์มือถือ"
          placeholder="กรอกเบอร์มือถือ"
          isRequire
          limit={10}
          errMsg="กรุณากรอกเบอร์มือถือ"
          form={form}
          type="tel"
        />
        <StyleButtonOTP
          type="confirm"
          typeButton="button"
          width="70%"
          handleClick={onRequestOtp}
          disabled={!isFinish}
        >
          {!isFinish
            ? `00:${String(Math.floor(time % 60)).padStart(2, 0)}`
            : "คลิกที่นี่เพื่อรับรหัส OTP"}
        </StyleButtonOTP>
      </StyleCoverPhoneNumber>

      <InputText
        name="otp"
        label="กรอกรหัส OTP 6 หลัก"
        placeholder="กรอกรหัส OTP 6 หลัก"
        isRequire
        limit={6}
        errMsg="กรุณากรอกรหัส OTP 6 หลัก"
        form={form}
        type="tel"
      />

      <StyleButton
        buttonType="submit"
        handleClick={() => handleSubmit(onFormSubmit)}
      >
        ตกลง
      </StyleButton>
    </LoginForm>
  );
};

export default FormLogin;
