import React from "react";
import styled from "styled-components";
import { Body2 } from "../common/components/Font";
import ImgNotFound from "../asset/images/notFound.svg";
import BGManyStar from "../asset/images/bg-many-star.png";
import BgDefault from "../asset/images/bg-default.jpg";

const StyleErrorPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 16px;
  background-image: url(${BGManyStar});
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
`;

const StyleNotFound = styled.img`
  width: 265px;
`;

const StyleContainer = styled.div`
  background-image: url(${BgDefault});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  height: 100%;
`;

const ErrorPage = () => {
  return (
    <StyleContainer>
      <StyleErrorPage>
        <StyleNotFound src={ImgNotFound} />
        <Body2 color="white">ไม่พบหน้านี้</Body2>
      </StyleErrorPage>
    </StyleContainer>
  );
};

export default ErrorPage;
