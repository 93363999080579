/* eslint-disable consistent-return */
const useCopyTextToClipboard = async text => {
  try {
    if ("clipboard" in navigator) {
      const copy = await navigator.clipboard.writeText(text);
      return copy;
    }
    return document.execCommand("copy", true, text);
  } catch (error) {
    console.log(`error:`, error);
  }
};

export default useCopyTextToClipboard;
