import styled from "styled-components";
import FormLogin from "../features/formLogin";
import { ReactComponent as IconLogin } from "../asset/images/logo-login.svg";
import ButtonBack from "../common/components/ButtonBack";
import TitleText from "../common/components/TitleText";
import Layout from "../common/layout/Layout";

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 36px 0px;
  background-color: ${props => props.theme.primaryDark600};

  @media (min-width: 430px) {
    width: 419px;
    border-radius: 10px;
    margin: auto;
  }
`;

const BtnBack = styled.div`
  padding: 8px 16px;
`;

const StyleContent = styled.div`
  margin-bottom: 40px;
`;

const StyleIcon = styled(IconLogin)`
  margin-top: 30px;
`;

const Login = () => {
  return (
    <Layout>
      <StyleContent>
        <BtnBack>
          <ButtonBack color="white" label="< ย้อนกลับ" />
        </BtnBack>
        <LoginContainer>
          <TitleText label="เข้าสู่ระบบ" theme="white" size="large" />
          <StyleIcon />
          <FormLogin />
        </LoginContainer>
      </StyleContent>
    </Layout>
  );
};

export default Login;
